import React, { useState, useCallback, useEffect, } from "react";
import log from 'loglevel';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useTranslation } from "react-i18next";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DownloadIcon from '@material-ui/icons/GetApp';
import Container from "@material-ui/core/Container";
import MyAxios, {get_document, check_response, } from "./MyAxios";
import { dmy_date_formatter, DataTable } from './components/DataTable';
import { EsenzioniInsertDialog } from "./EsenzioniInsertDialog";
import { getOptionList, Loading, } from "./components/MostComponents";
import { addIdToRows, } from "./Utils";

export const Esenzioni = (props) => {
    const { t } = useTranslation()
    const [forceReload, setForceReload] = useState(0);
    const [forceReloadDialog, setForceReloadDialog] = useState(0)
    const [loading, setLoading] = useState(true)
    const [options_esenzioni, setOptions_esenzioni] = useState([])
    const [row, setRow] = useState(null)
    const [rows, setRows] = useState([])
    const [openInsertDialog, setOpenInsertDialog] = useState(false)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
        setContent(text);
        setAlert1(true);
    }, [setContent,setAlert1])
    const { setConfirm1, setCContent, setOnConfirm } = useGlobalHook('confirmStore');
    function appConfirm(text,okHandler) {
      setCContent(text);
      setOnConfirm(() => x => {
        okHandler();
      });
      setConfirm1(true);
    }

    useEffect(() => {
        setLoading(true)
        const jdata = {
            action: 'LIST',
            patient_id: props.paziente.value,
        }
        const formData = new FormData()
        formData.append('jpayload', JSON.stringify(jdata))
        const url = '/patient_exemption'
        log.debug(url, [...formData.entries()])
        log.debug(url, "jpayload", jdata)
        MyAxios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
          setLoading(false)
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            appAlert(res.error)
            return
          }
          setRows(addIdToRows(res.patient_exemptions))
          const oe = getOptionList(res.exemption_list,'code','code+description')
          //log.debug(oe)
          setOptions_esenzioni(oe)
        })
        .catch(function (error) {
            setLoading(false)
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,props.paziente.value,forceReload,])

    function editEsenzione(row) {
        setRow(row)
        setForceReloadDialog(prevCount => prevCount + 1)
        setOpenInsertDialog(true)
    }

    function deleteEsenzione(key,code) {
        const text = t("Cancellare l'esenzione")+" "+code+" ?"
        appConfirm(text,() => {
            const jdata = {
                action: 'DELETE',
                patient_id: props.paziente.value,
                id: key,
            }
            const formData = new FormData()
            formData.append('jpayload', JSON.stringify(jdata))
            const url = '/patient_exemption'
            log.debug(url, [...formData.entries()])
            log.debug(url, "jpayload", jdata)
            MyAxios.post(url, formData, {
                headers: { "Content-Type": "multipart/form-data;" }
            }).then((response) => {
              const data = check_response(response);
              if(!data.success) {
                log.error(data.error)
                appAlert(data.error)
                return
              }
              setForceReload(prevCount => prevCount + 1)
            })
            .catch(function (error) {
                log.error(error);
                appAlert(error.message?error.message:JSON.stringify(error));
            })
        })
    }
    function reloadOnInsert() {
        setForceReload(prevCount => prevCount + 1)
    }

    function nuovo() {
        setRow(null)
        setForceReloadDialog(prevCount => prevCount + 1)
        setOpenInsertDialog(true)
    }

    const download = (id) => {
      get_document(id,appAlert)
    }

    const columns = [
          { field: 's3_id', headerName: t("Documento"), width: 150, renderCell: (params: GridCellParams) => {
            if (params.row.s3_id === null)
              return null
            return (
              <Tooltip title={t("Download")}>
                <IconButton
                  onClick={() => download(params.row.s3_id)}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
           )},
          },
          { field: 'exemption', headerName: t('Codice'), width: 150, },
          { field: 'description', headerName: t('Descrizione'), minWidth: 200, flex: 1, },
          { field: 'open_date', headerName: t('Apertura'), width: 150, valueFormatter: dmy_date_formatter },
          { field: 'expiration_date', headerName: t('Scadenza'), width: 150, valueFormatter: dmy_date_formatter },
          { field: 'table_id', headerName: ' ', width: 120, renderCell: (params: GridCellParams) => {
            return (
              <React.Fragment>
              <Tooltip title={t("Modifica")}>
                <IconButton
                  onClick={() => editEsenzione(params.row)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("Rimuovi")}>
                <IconButton
                  onClick={() => deleteEsenzione(params.row.table_id,params.row.exemption)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              </React.Fragment>
           )},
          },
        ]

    if(loading)
        return <Loading />
    return (
        <div className="wt3">
        <Container component="main" className="displayFlex flexflowColumn height100">
            <Toolbar className="rowreverse">
                <Button variant="contained" onClick={nuovo} className="submitButton" > {t("Nuova esenzione")} </Button>
            </Toolbar>
            { rows.length ?
                <div className="whiteback flex1">
                    <DataTable columns={columns} rows={rows} density={"compact"} disableSelectionOnClick={true} />
                </div>
            : 
                <div className="boxForm tcenter">
                    {t("Nessuna esenzione")}
                </div>
            }
        </Container>
        <EsenzioniInsertDialog opendialog={openInsertDialog} setOpendialog={setOpenInsertDialog} paziente={props.paziente} options_esenzioni={options_esenzioni} reload={reloadOnInsert} row={row} forceReloadDialog={forceReloadDialog} />
        </div>
    )
}
