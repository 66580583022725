import React, { useState, } from 'react';
import { Link } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import {
  Menu,
  MenuItem,
  MenuHeader,
  MenuDivider,
  SubMenu
} from '@szhsin/react-menu';
import { useTranslation } from 'react-i18next';
import useStyles from "./components/useStyles";
import HelpDialog from "./components/HelpDialog";
import Version from "./components/Version";
import LanguageIcon from '@material-ui/icons/Language';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { Patient } from './Patient';
import '@szhsin/react-menu/dist/index.css';

export function Header(props) {
  const { userInfo } = useGlobalHook('userStore');
  const { t, i18n } = useTranslation();
  const [langDialog, setLangDialog] = useState(false);

  function linguaWin() {
    setLangDialog(true);
  }

  const query_log = () => {
    props.changeMode('query_log')
  };

  const logout = () => {
    props.logout()
  };

  const changepasswd = () => {
    props.changeMode('changepasswd')
  };

  const ricercheMostdoc = () => {
    props.changeMode('ricerche')
  };

  const amministrazioneMostdoc = () => {
    props.changeMode('amministrazione')
  };

  const nuovoPaziente = () => {
    props.changeMode('nuovoPaziente')
  };

  const diagnosi = () => {
    props.changeMode('diagnosi')
  };

  const dbinthem = () => {
    props.changeMode('dbinthem')
  };

  const dbinthemPatient = () => {
    props.changeMode('dbinthemPatient')
  };

  const dpn = () => {
    window.open("/mostdoc/WWW/MOSTDOC/html?DPN+Mframe.html","dpn")
  };

  const squid = () => {
    window.open("/mostdoc/WWW/MOSTDOC/html?SQUID+Mframe.extjs","squid")
  };

  const vaccinazioni = () => {
    window.open("https://network-italiano-asplenia.it/","asplenia")
  };

  const document = () => {
    props.changeMode('document')
  };

  const covid = () => {
    props.changeMode('covid')
  };

  const gene = () => {
    props.changeMode('gene')
  };

  const hospitalization = () => {
    props.changeMode('hospitalization')
  };

  const instrumental_exam = () => {
    props.changeMode('instrumental_exam')
  };

  const tools = () => {
    props.changeMode('tools')
  };

  const mutazioni = () => {
    props.changeMode('mutazioni')
  };

  const esenzioni = () => {
    props.changeMode('esenzioni')
  };

  const riepilogo = () => {
    props.changeMode('riepilogo')
  };

  const nuovaVisita = () => {
    props.nuovaVisita()
  };

  const appuntamentiVisita = () => {
    props.changeMode('appuntamentiVisita')
  };

  const agendeWindow = () => {
    // ? problemi di cache su cambio versione ? forziamo no cache (dovrebbe gia' essere cosi'...
    const url = "/"+props.wtapp+"/agendaT?"+new Date().toISOString()
    // nuovo tab (per finestra indipendente: 3o parametro: "menubar=0,width=800,height=600,top=200,left=900")
    window.open(url,"wt3agende")
  };

  const WT2 = () => {
    props.changeMode('WT2')
  };

  const classes = useStyles();

  let emptyMode = false
  if(props.empty) {
    emptyMode = true
  }
  let patientName = t("non selezionato")
  let patientDisabled = true
  if(props.paziente) {
    patientName = props.paziente.row.surname + " " + props.paziente.row.name
    patientDisabled = false
  }

  //log.debug("userInfo",userInfo);
  return (
    <div className="Header">
      <AppBar position="fixed" color="inherit" className="Header">
        <Toolbar>
        { props.backMode ?  (
          <Tooltip title={t("Indietro")}>
            <Link to={props.backMode}>
                <IconButton
                    className={classes.menuButton}
                    edge="start"
                    color="inherit"
                >
                    <ArrowBackIcon />
                </IconButton>
            </Link>
          </Tooltip>
        ) : emptyMode || userInfo.passwordExpired ?  (
          null
        ) : (
            <Menu menuButton={
                      <Tooltip title={t("menu")}>
                        <IconButton
                          className={classes.menuButton}
                          id="hamburger"
                          edge="start"
                          aria-label="menu"
                          aria-controls="user-menu"
                          aria-haspopup="true"
                          color="inherit"
                        >
                          <MenuIcon />
                        </IconButton>
                      </Tooltip>
                    } >
  <MenuItem id="pagina_principale" onClick={WT2}>{t("Pagina principale")}</MenuItem>
  <MenuItem id="agende" onClick={agendeWindow}>{t("Agende (nuova finestra)")}</MenuItem>
  <SubMenu id="nuovo_submenu" label={t("Nuovo")}>
    <MenuItem id="nuovo_paziente" onClick={nuovoPaziente}>{t("Nuovo paziente")}</MenuItem>
    { userInfo.profile === "ADMIN_PROFILE" ?
      <MenuItem id="nuova_diagnosi" onClick={diagnosi}>{t("Nuova diagnosi")}</MenuItem>
    : null }
  </SubMenu>
  <MenuItem id="ricerche_avanzate" onClick={ricercheMostdoc}>{t("Ricerche avanzate")}</MenuItem>
  <MenuItem onClick={tools}>{t("Tools")}</MenuItem>
  { userInfo.profile === "ADMIN_PROFILE" ?
    <SubMenu label={t("Amministrazione")}>
        <MenuItem id="amministrazione" onClick={amministrazioneMostdoc}>{t("Gestione tabelle")}</MenuItem>
        <MenuItem id="query_log" onClick={query_log}>{t("Log")}</MenuItem>
    </SubMenu>
  : null }
  <SubMenu label={t("Applicazioni")}>
      { userInfo.profile === "ADMIN_PROFILE" ?
        <MenuItem onClick={dbinthem}>DB-INTHEM</MenuItem>
      : null }
      { props.wtapp === "wtto2" ?
        [
            <MenuItem key="squid" onClick={squid}>{t("Applicazione SQUID")}</MenuItem>,
            <MenuItem key="dpn" onClick={dpn}>{t("Applicazione DPN")}</MenuItem>
        ]
      : null }
      { 1 /* props.portaleVaccinazioni */ ?
        <MenuItem onClick={vaccinazioni}>{t("Portale vaccinazioni")}</MenuItem>
      : null }
  </SubMenu>
  <MenuDivider />
  <MenuHeader styles={{ backgroundColor: '#00C', color: 'white' }}>{t("Paziente")+" "+patientName}</MenuHeader>
    <MenuItem id="tipizzazione_molecolare" styles={{ paddingLeft: '50px' }} disabled={patientDisabled} onClick={mutazioni}>{t("Tipizzazione molecolare")}</MenuItem>
    <MenuItem id="gestione_documentale" styles={{ paddingLeft: '50px' }} disabled={patientDisabled} onClick={document}>{t("Gestione Documentale")}</MenuItem>
    <MenuItem id="esami_strumentali" styles={{ paddingLeft: '50px' }} disabled={patientDisabled} onClick={instrumental_exam}>{t("Esami Strumentali")}</MenuItem>
    <MenuItem id="nuovo_appuntamento_visita" styles={{ paddingLeft: '50px' }} disabled={patientDisabled} onClick={nuovaVisita}>{t("Nuovo appuntamento visita")}</MenuItem>
    <MenuItem id="appuntamenti_visita" styles={{ paddingLeft: '50px' }} disabled={patientDisabled} onClick={appuntamentiVisita}>{t("Appuntamenti visita")}</MenuItem>
    <MenuItem id="esenzioni" styles={{ paddingLeft: '50px' }} disabled={patientDisabled} onClick={esenzioni}>{t("Esenzioni")}</MenuItem>
    <MenuItem id="riepilogo" styles={{ paddingLeft: '50px' }} disabled={patientDisabled} onClick={riepilogo}>{t("Anamnesi Storica")}</MenuItem>
    <MenuItem id="covid" styles={{ paddingLeft: '50px' }} disabled={patientDisabled} onClick={covid}>Covid</MenuItem>
    <MenuItem id="terapia_genica" styles={{ paddingLeft: '50px' }} disabled={patientDisabled} onClick={gene}>{t("Terapia genica")}</MenuItem>
    <MenuItem id="ospedalizzazioni" styles={{ paddingLeft: '50px' }} disabled={patientDisabled} onClick={hospitalization}>{t("Ospedalizzazioni")}</MenuItem>
    <MenuItem id="dbInthem" styles={{ paddingLeft: '50px' }} disabled={patientDisabled} onClick={dbinthemPatient}>{t("Consenso DB-INTHEM")}</MenuItem>
    {/* xxx DEBUG ONLY
    <MenuItem styles={{ paddingLeft: '50px' }} disabled={patientDisabled} onClick={() => props.debugTS()}>{"xxx DEBUG trattamenti speciali xxx"}</MenuItem>
    xxx DEBUG ONLY */}
                </Menu>
        )}
        { !(emptyMode || userInfo.passwordExpired) ?  (
              <Patient options_patient={props.options_patient} paziente={props.paziente} setPaziente={props.setPaziente} chiudiPaziente={props.chiudiPaziente} apriPaziente={props.apriPaziente} stampaPaziente={props.stampaPaziente} />
        ) : null }
          <div className={classes.grow} />
          <Version />
          <HelpDialog emptyMode={emptyMode} openHelpInDrawer={props.openHelpInDrawer} />
          { emptyMode ? 
              <Tooltip title={t("Lingua")}>
                <IconButton
                    onClick={linguaWin}
                    color="inherit"
                >
                    <LanguageIcon />
                </IconButton>
              </Tooltip>
          :   <div className={classes.sectionUser}>
                <Menu menuButton={
                    <Tooltip title={userInfo.name+" "+userInfo.surname}>
                      <IconButton
                        edge="end"
                        aria-label={t("account of current user")}
                        aria-controls="user-menu"
                        aria-haspopup="true"
                        color="inherit"
                      >
                        <AccountCircleIcon />
                      </IconButton>
                    </Tooltip>
                    } >
  <MenuHeader>{userInfo.name + " " + userInfo.surname}</MenuHeader>
  <MenuItem onClick={changepasswd}>{t("Cambio password")}</MenuItem>
  <MenuItem onClick={logout}>{t("Logout")}</MenuItem>
                </Menu>
              </div>
          }
        </Toolbar>
      </AppBar>
      <Toolbar />
<Dialog
  open={langDialog}
  onClose={() => setLangDialog(false)}
  aria-describedby="lang-alert-dialog-description"
>
  <DialogContent>
    <DialogContentText id="lang-alert-dialog-description">
      {t("Scegli la lingua")}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    {/* configurare supportedLngs e getLocale() in i18n.js */}
    <Button onClick={() => {
        setLangDialog(false);
        i18n.changeLanguage('it');
    }} color="primary" autoFocus>
      <img src="https://flagcdn.com/h24/it.png" height="24" width="48" alt="Italiano" title="Italiano" />
    </Button>
    <Button onClick={() => {
        setLangDialog(false);
        i18n.changeLanguage('en');
    }} color="primary" autoFocus>
      <img src="https://flagcdn.com/h24/gb.png" height="24" width="48" alt="English" title="English" />
    </Button>
    <Button onClick={() => {
        setLangDialog(false);
        i18n.changeLanguage('fr');
    }} color="primary" autoFocus>
      <img src="https://flagcdn.com/h24/fr.png" height="24" width="48" alt="Français" title="Français" />
    </Button>
    <Button onClick={() => {
        setLangDialog(false);
        i18n.changeLanguage('pt');
    }} color="primary" autoFocus>
      <img src="https://flagcdn.com/h24/br.png" height="24" width="48" alt="Português (Brasil)" title="Português (Brasil)" />
    </Button>
  </DialogActions>
</Dialog>
    </div>
  );
}
