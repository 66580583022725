import React, { useState, } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import Popper from "@material-ui/core/Popper";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import { MostButton, } from "./components/MostComponents";
import { now, dmy, } from "./Utils";

export const Patient = (props) => {
    const [listPatientKey, setListPatientKey] = useState("1")
    const { login1 } = useGlobalHook('loginStore');
    const { t, } = useTranslation();

    const cambioPaziente = (e,el) => {
        props.apriPaziente(el)
        // per resettare autocomplete 
        setListPatientKey(now(4))
    }

    const PatientDetail = (props) => {
        const dnascita = dmy(props.paziente.birth_date)
        let diagnosi2 = ''
        if (props.paziente.diagnosis_2_description)
            diagnosi2 = <span> - {props.paziente.diagnosis_2_description}</span>
        let diagnosi3 = ''
        if (props.paziente.diagnosis_3_description)
            diagnosi3 = <span> - {props.paziente.diagnosis_3_description}</span>
        let fiscal_code
        if (props.paziente.fiscal_code)
            fiscal_code = <span>{props.paziente.fiscal_code}</span>
        else
            fiscal_code = <span className="patientMissingInfo">{t("mancante")}</span>
        let cartella
        if (props.paziente.folder_id)
            cartella = <span>{props.paziente.folder_id}</span>
        else
            cartella = <span className="patientMissingInfo">{t("mancante")}</span>
        let gruppos
        if (props.paziente.blood_group)
            gruppos = <span>{props.paziente.blood_group}</span>
        else
            gruppos = <span className="patientMissingInfo">{t("mancante")}</span>
        let followup = ''
        if (props.paziente.followup_ferriprox)
            followup = <span className="listPatientFerriprox">{t("Followup settimanale del Deferiprone")}</span>
        let endo = ''
        if (props.paziente.endo_patient)
            endo = <span className="listPatientEndo">{t("Paziente endocrinologico")}</span>
        const statusclass = statusClassName(props.paziente.status)
        const statusdesc = statusDesc(props.paziente.status)
        const status = <span className={statusclass}>{statusdesc}</span>
        return <div className="patientDetailDiv"><div><span className="patientName">{props.paziente.surname}</span> <span className="patientName">{props.paziente.name}</span><span className="details">Alias: <b>{props.paziente.alias}</b> - Id: {props.paziente.identifier} - Status: {status}</span></div><div className="patientDetailDiv2">{t("Sesso")}: <b>{props.paziente.gender}</b> - {t("Nato il")}: <b>{dnascita}</b> - {t("Gruppo sanguigno")}: <b>{gruppos}</b><br />{t("Codice fiscale")}: <b>{fiscal_code}</b> - {t("Cartella")}: {cartella} - {t("Diagnosi")}: <b>{props.paziente.diagnosis_description}{diagnosi2}{diagnosi3}</b> - {followup} {endo} </div></div>
    }

  const getOptionLabel = (option) => option && option.label ? option.label : ""
  const getOptionSelected=(option, value) => {
        if (!value || value.value === "")
          return true
        if (value.value === option.value)
          return true
      }

  const statusDesc=(status) => {
        return t(status)
      }

  const statusClassName=(status) => {
        if(status === "deceduto")
            return "status_deceduto"
        if(status === "perso_al_follow_up")
            return "status_perso_al_follow_up"
        if(status === "trasferito")
            return "status_trasferito"
        if(status === "trapiantato")
            return "status_trapiantato"
        return ""
      }


  // google: mui autocomplete popper style
  // https://smartdevpreneur.com/customizing-the-material-ui-autocomplete-component/
  // https://codesandbox.io/s/material-ui-autosuggest-epuj4?file=/demo.tsx:281-632
  const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      zIndex: 2000,
      "& .MuiAutocomplete-listbox": {
        //border: "2px solid grey",
        //minHeight: 400,
        //color: "green",
        fontSize: 12,
        //"& li:nth-child(even)": { backgroundColor: "#CCC" },
        //"& li:nth-child(odd)": { backgroundColor: "#FFF" }
      }
    },
    inputLabel: {
        fontSize: 12,
        fontStyle: "italic",
    }
  })
);

  const classes = useStyles();
  const CustomPopper = function (props) {
    return <Popper {...props} className={classes.root} placement="bottom" />;
  };
  //const label = t("Inserire il cognome del paziente..")
  // incredibilmente questo campo viene compilato da chrome con lo username se viene mostrato il dialog di login, bisogna disabilitarlo... boh
  let pazienteDisabled = false
  if(login1)
    pazienteDisabled = true

    return (
      <div className="pazienteDiv">
        <Autocomplete
          id="paziente"
          name="paziente"
          disabled={pazienteDisabled}
          key={listPatientKey}
          options={props.options_patient}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          style={{ width: 300 }} 
          className="pazienteAutocomplete"
          defaultValue={null}
          onChange={cambioPaziente}
          renderInput={(params) => (
            <TextField {...params} margin="dense" variant="outlined" label={t("Inserire il cognome del paziente")} InputLabelProps={{className: classes.inputLabel}} />
          )}
          renderOption={(option) => {
            // lista pazienti: cosa viene visualizzato
            // wt2: Alias, Data nascita, Diagnosi, Codice fiscale, Cartella
            // tempi di risposta penosi: togliamo cose inutili
            // 5/4/22 : Voi: richiesta di visualizzazione di cartella e diagnosi
            const dnascita = dmy(option.row.birth_date)
            const nascita = <div><i>{t("Data di nascita")}:</i> <b>{`${dnascita}`}</b></div>
            let codice_fiscale = ''
//            if(option.row.fiscal_code)
//                codice_fiscale = <div><i>Codice fiscale:</i> <b>{`${option.row.fiscal_code}`}</b></div>
            const diagnosi = <div><i>{t("Diagnosi")}:</i> <b>{`${option.row.diagnosis_description}`}</b></div>
            let cartella = ''
            if(option.row.folder_id)
                cartella = <div><i>{t("Cartella")}:</i> <b>{`${option.row.folder_id}`}</b></div>
            let followup = ''
            if(option.row.followup_ferriprox)
                followup = <div className="listPatientFerriprox">{t("Followup settimanale del Deferiprone")}</div>
            let endo = ''
            if(option.row.endo_patient)
                endo = <div className="listPatientEndo">{t("Paziente endocrinologico")}</div>
            let status = ''
//            const statusclass = statusClassName(option.row.status)
//            if(statusclass !== '') {
//                const statusdesc = statusDesc(option.row.status)
//                status = <div className={statusclass}>{statusdesc}</div>
//            }
//            return <div><div className="listPatientName">{`${option.row.surname} ${option.row.name}`}</div><div className="listPatientInfo"><i>Id:</i> {`${option.row.identifier}`}<br /><i>Alias:</i> <b>{`${option.row.alias}`}</b>{nascita}<i>Diagnosi:</i> <b>{`${option.row.diagnosis_description}`}</b>{codice_fiscale}{cartella}{followup}{endo}{status}</div></div>
            return <div><div className="listPatientName">{`${option.row.surname} ${option.row.name}`}</div><div className="listPatientInfo">{nascita}{diagnosi}{codice_fiscale}{cartella}{followup}{endo}{status}</div></div>
          }}
          PopperComponent={CustomPopper} 
        />
        {props.paziente != null ?
            <React.Fragment>
                <div className="closeDiv">
                    <MostButton
                        label={<div>
                            <div> {t("Chiudi")} </div>
                            <div> {t("Cartella")} </div>
                        </div> } 
                        className="chiudiCartella font10"
                        onClick={props.chiudiPaziente} /> 
                </div>
                {/* xxx work in progress
                <div className="closeDiv">
                    <MostButton
                        label="Stampa"
                        className="chiudiCartella font10"
                        onClick={props.stampaPaziente} />
                </div>
                */}
                <PatientDetail paziente={props.paziente.row} />
            </React.Fragment>
        : null }
      </div>
    );
};
