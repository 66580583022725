import React, { }  from 'react';
import { useTranslation } from "react-i18next";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CopyrightIcon from '@material-ui/icons/Copyright';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import { useGlobalHook } from '@devhammed/use-global-hook'

export default function Version(props) {
  const { userInfo } = useGlobalHook('userStore');
  const [open, setOpen] = React.useState(false);
  const { t, } = useTranslation()

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  let needReload
  const running_fe_version = window._env_.FE_VERSION
  const server_fe_version = userInfo.fe_version
  const be_version = userInfo.be_version
  if (!server_fe_version || running_fe_version === "devel") {
    // non autenticati, non abbiamo info da BE
    needReload = 0
  } else {
    needReload = (server_fe_version !== running_fe_version)
  }

  return (
  <React.Fragment>
   <Tooltip title={needReload ? t("ATTENZIONE")+": "+t("disponibile aggiornamento") : t("Versione SW")}>
    <IconButton
        id="version_button"
        onClick={handleClickOpen}
        color="inherit"
    >
        { needReload ?
            <ErrorIcon className="error" />
        :
            <CopyrightIcon />
        }
    </IconButton>
   </Tooltip>
   <Dialog
        id="versionDialog"
        open={open}
        scroll="paper"
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t("Versione SW")}
        </DialogTitle>
        <DialogContent>
          FE : {running_fe_version} <br />
          { needReload ?
            <div>
            <span className="error"> {t("ATTENZIONE disponibile versione")} "{server_fe_version}" </span> <br />
            {t("Se hai salvato Aggiorna")}
            </div>
          : null }
          <br />
          {be_version ? "BE : "+be_version : ""}
        </DialogContent>
        <DialogActions>
          {/* nota: "window.location.reload(true)" per rimuovere la cache del browser non è standard e (forse) funziona solo in firefox; bisogna configurare nginx perché disabiliti cache per index.html */}
          { needReload ?
            <Button id="reload" color="primary" variant="contained" onClick={() => window.location.reload(true)} >
                {t("Aggiorna")}
            </Button>
          : null }
          <Button id="help_close" onClick={handleClose} variant="outlined" color="secondary">
            {t("Chiudi")}
          </Button>
        </DialogActions>
  </Dialog>

  </React.Fragment>
  );
}
