import React, { useCallback, useEffect, useState } from "react"
import log from 'loglevel';
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getLang, getLocale } from "../i18n";
import { useForm } from "react-hook-form";
//import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
//import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios, { check_response } from "../MyAxios";
import { getCurrentTimezone } from "../Utils";
import { MostSubmitButton, MostTextField, } from "../components/MostComponents";
import useStyles from "../components/useStyles";
import logo from '../WebTHAL-logo.png';

export function SignIn(props) {
const history = useHistory();
const [channel] = React.useState(new BroadcastChannel("checkWt3Active"))
const { t, i18n } = useTranslation();
const { register, handleSubmit } = useForm();
const classes = useStyles();
const [disabledButs, setDisabledButs] = useState(false)
const [alreadyLogged, setAlreadyLogged] = useState(false)
const { setLlang, setLlocale } = useGlobalHook('loginStore');
const { setUserInfo } = useGlobalHook('userStore');
const { setAlert1, setContent } = useGlobalHook('alertStore');
const appAlert = useCallback((text) => {
  setContent(text);
  setAlert1(true);
}, [setContent,setAlert1])

useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if(isSafari)
        appAlert(t("Attenzione")+": "+t("l'utilizzo del browser Safari è sconsigliato, alcune pagine potrebbero non essere visualizzate correttamente"))
}, [appAlert,t,])

useEffect(() => {
    // siamo gia' autenticati?
    const url = 'get_userinfo';
    MyAxios.get(url)
      .then(response => {
        const res = check_response(response,false,true);
        if (res.success && res.user && props.wtapp === res.user.wtapp && !alreadyLogged) {
            history.push('uhome')
        }
      })
}, [history,props.wtapp,alreadyLogged])

useEffect(() => {
    channel.onmessage = ev => {
        log.debug("got message from another window",ev)
        if(!alreadyLogged) {
            if(ev.data === "openingWindow") {
                try {
                    log.debug("postMessage openWindow")
                    channel.postMessage("openWindow")
                } catch(e) {}
            } else
                setAlreadyLogged(true)
        }
    }
    return function cleanup() {
        try {
            channel.close()
        } catch(e) {}
    }
}, [ channel, alreadyLogged, ])

useEffect(() => {
    log.debug("channel.postMessage")
    try {
        channel.postMessage("openingWindow")
    } catch(e) {}
}, [ channel, ])


const onSubmit = vals => {
    // cosi' logghiamo password
    //log.debug("onSubmit: " + JSON.stringify(vals));
    //alert("onSubmit: " + JSON.stringify(vals));

    const lang = getLang(i18n.language)
    const locale = getLocale(i18n.language)
    const timezone = getCurrentTimezone()
    let data= {
        username: vals.username,
        password: vals.password,
        wtapp: props.wtapp,
        lang: lang,
        locale: locale,
        timezone: timezone,
    }
    setDisabledButs(true)
    MyAxios.post("login", data)
    .then((response) => {
        const res = check_response(response)
        if (res.success) {
            setLlang(lang)
            setLlocale(locale)
            props.resetIdleTimeout()
            setUserInfo(res.user)
            history.push('uhome');
        } else {
            log.debug("Error: login: "+res.error);
            appAlert(t("Accesso fallito")+": "+res.error);
            setDisabledButs(false)
        }
    })
    .catch(function (error) {
        // handle error
        log.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        setDisabledButs(false)
    })
}

function alreadLoggedGoOn() {
    setAlreadyLogged(false)
}


  return (
   <div className="content-container">
    <Container component="main" maxWidth="xs">
   <div className="boxForm margintop64">
      <div className={classes.paper+" nomargintop"}>
        {/*
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <img src={logo} className="xxxxApp-logo" alt="logo" />
        */}
        <img src={logo} className="small-logo" alt="logo WebTHAL" />
        <Typography component="h1" variant="h5">
          WebTHAL 3
        </Typography>
        <Typography component="h1" variant="h5">
          {props.wtapp}
        </Typography>
        {alreadyLogged ?
         <div className="blackColor">
            <h3>{t("Attenzione")}</h3>
            <p>{t("Already logged in 1")}</p>
            <p>{t("Already logged in 2")}</p>
            <p>{t("Already logged in 3")}</p>
            <div className="taright tabBar">
                    <Button variant="outlined" color="secondary" onClick={alreadLoggedGoOn} >
                        {t("Continua sconsigliato")}
                    </Button>
            </div>
         </div>
        :
         <form onSubmit={handleSubmit(onSubmit)} className={classes.form} >
            <MostTextField name="username" required={true} label={t("Username")} autoComplete="current-username" type="text" autoFocus={true} register={register} />
            <MostTextField name="password" required={true} label="Password" autoComplete="current-password"  type="password" register={register} />
            {/*
            <MostCheckbox register={register} name="remember" default={false} label={t("SignIn.Remember me")} />
            */}
          <MostSubmitButton disabled={disabledButs} className="makeStyles-submit-4" label={t('SignIn.Sign In')}/>
          <Grid container>
            <Grid item xs>
              <Link to="forgot_password" variant="body2">
                {t('SignIn.Forgot password?')}
              </Link>
            </Grid>
          </Grid>
         </form>
        }
      </div>
   </div>
    </Container>
   </div>
  );
}
