import React, { useState, useEffect, } from "react";
import log from 'loglevel';
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { dmy_hms, ymd } from "./Utils";
import MyAxios, {check_response} from "./MyAxios";
import { getOptionListElement, MostTimePicker, MostTextField, MostAutocomplete, MostDatePicker, } from "./components/MostComponents";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

/*
props.agenda == "appuntamenti" | "trasfusioni"

props.paziente ? visita : nota
itipo == "VISITA" | "NOTA"
NOTA : props.dialogMode == "edit" | null
NOTA "edit" : props.dati
VISITA edit : props.row
*/
export const CalendarEventDialog = (props) => {
    const { t, } = useTranslation();
    const [idate, setIdate] = useState(null)
    const [itipo, setItipo] = useState(null)
    const [testo, setTesto] = useState("")
    const [stanza, setStanza] = useState(null)
    const [dottore, setDottore] = useState(null)
    const [from, setFrom] = useState(null)
    const [to, setTo] = useState(null)
    const [errorText, setErrorText] = useState(null)

    useEffect(() => {
        setErrorText(null)
        if (props.dati) {
            // modifica di una nota
            setIdate(props.dati.start)
            if(props.dati.allDay || dmy_hms(props.dati.start) === "00:00:00") {
                setFrom(null)
                setTo(null)
            } else {
                setFrom(props.dati.start)
                setTo(props.dati.end)
            }
            setTesto(props.dati.dettagli)
            setStanza(getOptionListElement(props.options_stanze,props.dati.room))
            setDottore(getOptionListElement(props.options_dottori,props.dati.doctor))
        } else if (props.row) {
            // modifica di una visita
            setIdate(new Date(props.row.dataevento))
            if(props.row.time_from)
                setFrom(new Date(props.row.dataevento+" "+props.row.time_from))
            else
                setFrom(null)
            if(props.row.time_to)
                setTo(new Date(props.row.dataevento+" "+props.row.time_to))
            else
                setTo(null)
            setTesto(props.row.dettagli)
            setStanza(getOptionListElement(props.options_stanze,props.row.room))
            setDottore(getOptionListElement(props.options_dottori,props.row.doctor))
        } else {
            setIdate(props.currentdate)
            setFrom(null)
            setTo(null)
            setTesto("")
            setStanza(null)
            setDottore(null)
        }
        if (props.paziente) {
            // AppuntamentiVisita : solo visite
            setItipo("VISITA")
        } else {
            // Agende : solo note
            setItipo("NOTA")
        }
    }, [props.row,props.dati,props.paziente,props.currentdate,props.options_dottori,props.options_stanze])
    
    const closeDialogFunc = () => {
        setIdate(props.currentdate)
        setFrom(null)
        setTo(null)
        setTesto("")
        setErrorText(null)
        setStanza(null)
        setDottore(null)
        props.setOpendialog(false)
    }
    const ihandleDateChange = (date) => {
        setIdate(date)
    }
    const ionChangeStanza = (e,el) => {
        setStanza(el)
    }
    const ionChangeDottore = (e,el) => {
        setDottore(el)
    }
    const ionChangeTesto = (e) => {
        setTesto(e.target.value)
    }
    const onChangeFrom = (date) => {
        setFrom(date)
        if(isNaN(date))
            return
        if(date) {
            const nt = moment(date).add(15, 'minutes').toDate()
            setTo(nt)
        } else
            setTo(null)
    }
    function rimuoviNota() {
        const jdata = {
            action: 'DELETE',
            code: props.dati.mostdoc__key,
        }
        MyAxios.post("/calendar_note", jdata)
        .then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            setErrorText(res.error)
            return
          }
          props.setOpendialog(false)
          if(props.reload)
            props.reload()
        })
        .catch(function (error) {
            log.error(error);
            setErrorText(error.message?error.message:JSON.stringify(error));
        })
    }
    const handleSubmit = () => {
        log.debug("idate ",idate," itipo ",itipo," testo ",testo,"from",from,"to",to)
        setErrorText("")
        if(!idate) {
            setErrorText(t("Il campo data è obbligatorio"))
            return
        }
        if(isNaN(idate)) {
            setErrorText(t("Data non valida"))
            return
        }
        if(isNaN(from) || isNaN(to)) {
            setErrorText(t("Ora non valida"))
            return
        }
        /* // data < min || data > max
        if(dPerror && dPerror !== "") {
            setErrorText("Data non valida: "+dPerror)
            return
        }
        */
        const today = new Date(ymd(new Date()))
        if(idate < today) {
            setErrorText(t("Disabilitato l'inserimento di eventi nel passato"))
            return
        }
        if((from && !to) || (!from && to)) {
            setErrorText(t("I campi delle ore devono essere vuoti o entrambe valorizzati"))
            return
        }
        const idates = ymd(idate)
        let froms = null
        let tos = null
        if(from) {
            froms = dmy_hms(from).substring(0,5)
            tos = dmy_hms(to).substring(0,5)
        }
        let jdata
        let url
        if(itipo === "NOTA") {
            if (!testo || testo.trim() === "") {
                setErrorText(t("Nessun testo inserito"))
                return
            }
            url = "/calendar_note"
            jdata = {
                agenda: props.agenda === "appuntamenti" ? 'A' : 'T',
                note_date: idates,
                time_from: froms,
                time_to: tos,
                doctor:  dottore ? dottore.value : null,
                room:  stanza ? stanza.value : null,
                text:  testo,
            }
            if (props.dialogMode === "edit") {
                jdata.action = 'MODIFY'
                jdata.code = props.dati.mostdoc__key
            } else {
                jdata.action = 'ADD'
            }
        } else {
            url = "/visit_appointment"
            jdata = {
                patient_id: props.paziente.value,
                appointment_date: idates,
                time_from: froms,
                time_to: tos,
                doctor:  dottore ? dottore.value : null,
                room:  stanza ? stanza.value : null,
                notes: testo,
            }
            if (props.row) {
                jdata.action = 'MODIFY'
                jdata.mostdoc__key = props.row.mostdoc__key
            } else {
                jdata.action = 'ADD'
            }
        }
        // ?? fa casino chiude e riapre dialog ??
        //setDisabledButs(true)
        MyAxios.post(url, jdata)
        .then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            setErrorText(res.error)
            return
          }
          // azzeramento campi e chiusura dialog
          closeDialogFunc()
          if(props.reload)
            props.reload()
        })
        .catch(function (error) {
            log.error(error);
            setErrorText(error.message?error.message:JSON.stringify(error));
        })
    }

    let title
    if (props.dialogMode === "edit")
        title = t("Nota")
    else if (props.paziente) {
        if (props.row) 
            title = t("Modifica appuntamento")
        else
            title = t("Inserimento nuovo appuntamento")
    } else
        title = t("Inserimento nuova nota")

    return (
        <Dialog open={props.opendialog} fullWidth maxWidth="sm" className="wt3">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <div>
                { props.dialogMode === "edit" ?
                    <Toolbar className="rowreverse">
                        <Button id="rimuovi"variant="contained" onClick={rimuoviNota} className="submitButton" > {t("Rimuovi la nota")} </Button>
                    </Toolbar>
                : null }
                    <div className="displayFlex">
                        <div className="w200">
                            {/* esempio di MostDatePicker con gestione degli errori (portare dentro MostDatePicker?)
                                onError={(p) => setDPerror(p)}
                            */}
                            <MostDatePicker name="data" openTo="date" disableFuture={false} value={idate} onChange={ihandleDateChange} label={t("Data")} />
                        </div>
                        { props.agenda === "appuntamenti" ?
                            <React.Fragment>
                                <div className="w200">
                                    <MostTimePicker name="dalle" value={from} onChange={onChangeFrom} label={t("Dalle ore")} />
                                </div>
                                <div className="w200">
                                    <MostTimePicker name="alle" value={to} onChange={date => setTo(date)} label={t("Alle ore")} />
                                </div>
                            </React.Fragment>
                        : null }
                    </div>
                    { props.agenda === "appuntamenti" ?
                        <div>
                            <MostAutocomplete name="ambulatorio" value={stanza} options={props.options_stanze} label={t("Ambulatorio")} onChange={ionChangeStanza} />
                            <MostAutocomplete name="medico" value={dottore} options={props.options_dottori} label={t("Medico")} onChange={ionChangeDottore} />
                        </div>
                    : null }
                    { itipo === "VISITA" ?
                        <MostTextField label={t("Paziente")} value={props.paziente.row.surname+" "+props.paziente.row.name} disabled />
                    : null }
                    <MostTextField name="note_testo" value={testo} multiline={true} rows={5} label={itipo === "VISITA" ? t("note") : t("testo")} onChange={ionChangeTesto} inputProps={{ maxLength: 1998 }} />
                </div>
                <DialogContentText className="red">
                    {errorText}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="wt3">
                <Button id="annulla" onClick={closeDialogFunc} variant="outlined" color="secondary" className="font12" >
                    {t("Annulla")}
                </Button>
                <Button id="modifica" onClick={handleSubmit} variant="contained" color="secondary" className="font12" >
                    {props.dialogMode === "edit" || props.row ? t("Modifica") : t("Aggiungi")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
