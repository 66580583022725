import "ka-table/style.css";
import log from 'loglevel';
import React, { useEffect, useCallback, useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/GetApp';
//import UploadIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { kaReducer, Table } from 'ka-table';
import { DataType, SortDirection, SortingMode } from 'ka-table/enums';
import { loadData, updateData } from 'ka-table/actionCreators';
import Container from "@material-ui/core/Container";
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios, { get_document, check_response } from "./MyAxios";
import { Loading, getOptionList } from "./components/MostComponents";
import { dmy_hms, } from './Utils';
import { descriptionLangColumn } from './UtilsInthem';
//import { AddDocumentDialog } from "./AddDocumentDialog";
import { InstrumentalExamsInsertDialog } from "./InstrumentalExamsInsertDialog";

export const InstrumentalExams = (props) => {
    //log.debug("Documents")
    const { t, i18n } = useTranslation("documents", "translation");
    const [options_tipo_esame, setOptions_tipo_esame] = useState(null);
    //const [openAddDocumentDialog, setOpenAddDocumentDialog] = useState(false)
    const [openInsertDialog, setOpenInsertDialog] = useState(false)
    const [loading, setLoading] = useState(true)
    const [forceReload, setForceReload] = useState(0)
    const [forceReloadDialog, setForceReloadDialog] = useState(0)
    //const [forceReloadAddDocumentDialog, setForceReloadAddDocumentDialog] = useState(0)
    const [rows, setRows] = useState([])
    const [row, setRow] = useState(null)
    //const [key4addDocument, setKey4addDocument] = useState(null)
    //const [desc4addDocument, setDesc4addDocument] = useState(null)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])
    const { setConfirm1, setCContent, setOnConfirm } = useGlobalHook('confirmStore');
    function appConfirm(text,okHandler) {
      setCContent(text);
      setOnConfirm(() => x => {
        okHandler();
      });
      setConfirm1(true);
    }

    const getRow = (mkey) => {
        for (let i in rows) {
            if(rows[i].mostdoc__key === mkey)
                return rows[i]
        }
        return null
    }

    const CellS3Id = ({
      column,
      dispatch,
      rowKeyValue,
      value,
    }) => {
      //log.debug("column",column,"rowKeyValue",rowKeyValue,"value",value)
      const row = getRow(rowKeyValue)
      //log.debug(row)
      if(!value) {
        /*
        return (
            <Tooltip title={t("translation:Inserisci")}>
                <IconButton
                    onClick={() => addDocument(row)}
                >
                    <UploadIcon />
                </IconButton>
            </Tooltip>
        )
        */
        return null
      }
      if(!row)
        return null
      return (
          <Tooltip title={t("Download")}>
            <IconButton
              onClick={() => download(row.s3_id)}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
      )
    } 

    const CellEsami = ({
      column,
      dispatch,
      rowKeyValue,
      value,
    }) => {
      if(!value)
        return null
      // in value arriva una stringa non significativa
      const row = getRow(rowKeyValue)
      if(!row)
        return null
      let ret = ''
      for (let i in row.exam_results) {
        const e = row.exam_results[i]
        if(ret)
            ret += ', '
        ret += t(e.exam)+': '
        if(e.numeric_value)
            ret += e.text_value
        else
            ret += t(e.text_value)
      }
      return ret
    } 

    const CellMkey = ({
      column,
      dispatch,
      rowKeyValue,
      value,
    }) => {
      if(!value)
        return null
      const row = getRow(rowKeyValue)
      if(!row)
        return null
      const tipo = row.tipo_esame_descr
      let data = null
      if(row.data_ingresso)
        data = dmy_hms(row.data_ingresso,2)
      return (
          <React.Fragment>
          <Tooltip title={t("translation:Modifica")}>
            <IconButton
              onClick={() => edit(row)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("translation:Rimuovi")}>
            <IconButton
              onClick={() => deleteRow(value,tipo,data)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          </React.Fragment>
      )
    } 

    const tablePropsInit = {
      columns: [
        { key: 'tipo_esame_descr', title: t('translation:Esame'), dataType: DataType.String },
        { key: 'data_ingresso', title: t('data_ingresso'), dataType: DataType.Date, width: 80, },
        { key: 'data_esame', title: t('data_esame'), dataType: DataType.Date, sortDirection: SortDirection.Descend, width: 100, },
        { key: 's3_id', title: t('translation:Documento'), dataType: DataType.String, width: 80, },
        { key: 'esito', title: t('esito'), dataType: DataType.String, width: 500, },
        { key: 'note', title: t('note'), dataType: DataType.String, width: 200, },
        { key: 'exam_results', title: t('translation:Esami'), dataType: DataType.String, width: 200, },
        { key: 'data_contr', title: t('data_contr'), dataType: DataType.String, width: 100, },
        { key: 'mostdoc__key', title: " ", dataType: DataType.String, width: 130, },
      ],
      singleAction: loadData(),
      groups: [{ columnKey: 'tipo_esame_descr' }],
      rowKeyField: 'mostdoc__key',
      sortingMode: SortingMode.Single,
      format: ({ column, value }) => {
        if (column.key === 'tipo_esame_descr'){
            return t("translation:Esame")+": "+value
        }
        if (column.dataType === DataType.Date){
            return value && dmy_hms(value,2)
        }
      },
    };
    const [tableProps, changeTableProps] = useState(tablePropsInit);
    const dispatch = (action) => {
        changeTableProps((prevState) => kaReducer(prevState, action));
    };

    useEffect(() => {
        setLoading(true)
        const jdata = {
            action: 'LIST',
            patient_id: props.paziente.value,
        }
        const formData = new FormData()
        formData.append('jpayload', JSON.stringify(jdata))
        const url = "/instrumental_exams"
        log.debug(url, [...formData.entries()])
        log.debug(url, "jpayload", jdata)
        MyAxios.post(url, formData)
        .then((response) => {
          setLoading(false)
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          const ie = getOptionList(data.instrumental_exams,'code',descriptionLangColumn(i18n.language))
          setOptions_tipo_esame(ie)
          const rows = data.patient_instrumental_exams
          for(let i in rows) {
            const r = rows[i]
            if(r.data_ingresso)
                r.data_ingresso = new Date(r.data_ingresso)
            if(r.data_esame)
                r.data_esame = new Date(r.data_esame)
          }
          setRows(rows)
          dispatch(updateData(rows))
        })
        .catch(function (error) {
            setLoading(false)
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [props.paziente.value,appAlert,forceReload,i18n.language])

    const deleteRow = (mkey,tipo,data) => {
        const text = t("translation:Cancellare l esame")+" "+tipo+" "+t("translation:del")+" "+data+" ?"
        appConfirm(text,() => {
            const jdata = {
                action: 'DELETE',
                mostdoc__key: mkey,
            }
            const formData = new FormData()
            formData.append('jpayload', JSON.stringify(jdata))
            const url = "/instrumental_exams"
            log.debug(url, [...formData.entries()])
            log.debug(url, "jpayload", jdata)
            MyAxios.post(url, formData)
            .then((response) => {
              const data = check_response(response);
              if(!data.success) {
                log.error(data.error)
                appAlert(data.error)
                return
              }
              setForceReload(prevCount => prevCount + 1)
            })
            .catch(function (error) {
                log.error(error);
                appAlert(error.message?error.message:JSON.stringify(error));
            })
        })
    }

    /*
    const addDocument = (row) => {
        setKey4addDocument(row.mostdoc__key)
        let desc = t('translation:Esame')+" "+row.tipo_esame_descr+" "
        if(row.data_ingresso)
            desc += t("translation:del")+" "+dmy_hms(row.data_ingresso,2)
        else if(row.data_esame)
            desc += t("translation:del")+" "+dmy_hms(row.data_esame,2)
        setDesc4addDocument(desc)
        setForceReloadAddDocumentDialog(prevCount => prevCount + 1)
        setOpenAddDocumentDialog(true)
    }
    */

    const download = (id) => {
        get_document(id,appAlert)
    }

    function reloadOnInsert() {
        setForceReload(prevCount => prevCount + 1)
    }

    function nuovo() {
        setRow(null)
        setForceReloadDialog(prevCount => prevCount + 1)
        setOpenInsertDialog(true)
    }

    function edit(row) {
        setRow(row)
        setForceReloadDialog(prevCount => prevCount + 1)
        setOpenInsertDialog(true)
    }

    if(loading)
        return <Loading />

    return (
        <div className="wt3">
        <Container component="main">
            <div>
                <Toolbar className="rowreverse">
                    <Button variant="contained" onClick={nuovo} className="submitButton" > {t("translation:Nuovo Esame_Consulenza")} </Button>
                </Toolbar>
            </div>
        </Container>
        <div className="whiteback">
            <Table
              {...tableProps}
              dispatch={dispatch}
              childComponents={{
                cellText: {
                  content: (props) => {
                    if(!props.column)
                        return null
                    switch (props.column.key){
                      case 's3_id': return <CellS3Id {...props}/>;
                      case 'mostdoc__key': return <CellMkey {...props}/>;
                      case 'exam_results': return <CellEsami {...props}/>;
                      default:
                    }
                    return null
                  }
                }
              }}
            />
        </div>
        <InstrumentalExamsInsertDialog opendialog={openInsertDialog} setOpendialog={setOpenInsertDialog} paziente={props.paziente} options_tipo_esame={options_tipo_esame} reload={reloadOnInsert} row={row} forceReloadDialog={forceReloadDialog} />
        {/*
        <AddDocumentDialog opendialog={openAddDocumentDialog} setOpendialog={setOpenAddDocumentDialog} paziente={props.paziente} reload={reloadOnInsert} desc4addDocument={desc4addDocument} key4addDocument={key4addDocument} forceReloadDialog={forceReloadAddDocumentDialog} />
        */}
        </div>
    )
}
