import React, { useEffect, useState } from "react";
//import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';
import { Loading, MostTextField } from "./MostComponents";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 8,
    marginBottom: 4,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const Native = ({ 
    name,
    options,
    label,
    value,
    disabled,
    onChange,
    variant="outlined",
    fullWidth=true,
    size="small"
}) => {
  const [dvalue, setDvalue] = useState("")
  const [opt, setOpt] = useState(null)
  const classes = useStyles();
  //log.debug("name",name,"options",options,"value",value,"opt",opt)

  useEffect(() => {
    let opt = options
    if (!opt || opt.length === 0)
        opt = [{value:"",label:""}]
    else if (opt[0].value !== "") {
        opt.splice(0,0,{value:"",label:""})
    }
    setOpt(opt)
    if(disabled) {
        let v = value
        if(!v)
            v = ""
        let dvalue = ""
        for (let i in opt) {
            if(opt[i].value === v) {
                dvalue = opt[i].label
                break
            }
        }
        setDvalue(dvalue)
    }
  }, [disabled,options,value,name])

  let v = value
  if(!v)
     v = ""

  if(disabled) {
    return (
        <MostTextField
            disabled
            value={dvalue}
            label={label}
            variant={variant}
            fullWidth={fullWidth}
        />
    )
  }

  if(!opt)
    return <Loading />

  const id = 'select_'+name
  return (
      <FormControl fullWidth={fullWidth} size={size} variant={variant} className={classes.formControl}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Select
          native
          value={v}
          onChange={onChange}
          label={label}
          inputProps={{
            name: name,
            id: {id}
          }}
        >
          {opt.map((option,index)=>{
                return (
                    <option key={id+"k"+option.value} value={""+option.value} label={option.label} />
                )
            })}
        </Select>
      </FormControl>
  )
}

