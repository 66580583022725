import React, { useEffect, useCallback, useState } from "react";
import log from 'loglevel';
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios, { get_document, check_response } from "./MyAxios";
import { DocumentsInsertDialog } from "./DocumentsInsertDialog";
import { Loading, getOptionList, } from "./components/MostComponents";
import { dmy_date_formatter, DataTable } from './components/DataTable';
import { descriptionLangColumn } from './UtilsInthem';

export const Documents = (props) => {
    //log.debug("Documents")
    const [options_document_type, setOptions_document_type] = useState([])
    const { t, i18n } = useTranslation("documents", "translation");
    const [rows, setRows] = useState([])
    const [row, setRow] = useState(null)
    const [loading, setLoading] = useState(true)
    const [forceReload, setForceReload] = useState(0)
    const [openInsertDialog, setOpenInsertDialog] = useState(false)
    const [forceReloadDialog, setForceReloadDialog] = useState(0)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])
    const { setConfirm1, setCContent, setOnConfirm } = useGlobalHook('confirmStore');
    function appConfirm(text,okHandler) {
      setCContent(text);
      setOnConfirm(() => x => {
        okHandler();
      });
      setConfirm1(true);
    }

    const columns = [
          { field: 's3_id', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
            return (
              <Tooltip title={t("Download")}>
                <IconButton
                  onClick={() => download(params.row.s3_id)}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
           )},
          },
          { headerName: t("translation:Data"), field: "document_date", width: 120, valueFormatter: dmy_date_formatter},
          { headerName: t("translation:Tipo"), field: "document_type_description", width: 200},
          { headerName: t("note"), field: "note", minWidth: 200, flex: 1, renderCell: (params: GridCellParams) => {
            function ale() {
                const s = params.value.replace(/\n/g , function(s) { return "<br>"; } )

                appAlert("<b>"+t("translation:Nota")+"</b><br>"+s)
            }
            return (
              <Tooltip title={t("Mostra tutto il testo")}>
                <span className="clickable" onClick={ale}>{params.value}</span>
              </Tooltip>
          )}},
          { field: 'id', headerName: ' ', width: 120, renderCell: (params: GridCellParams) => {
            if(!params.row.id)
                return null
            return (
              <React.Fragment>
              <Tooltip title={t("translation:Modifica")}>
                <IconButton
                  onClick={() => edit(params.row)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("translation:Rimuovi")}>
                <IconButton
                  onClick={() => deleteRow(params.row.id,params.row.document_type)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              </React.Fragment>
           )},
          },
    ]

    useEffect(() => {
        setLoading(true)
        const jdata = {
            action: 'LIST_EVERYTHING',
            patient_id: props.paziente.value,
        }
        const formData = new FormData()
        formData.append('jpayload', JSON.stringify(jdata))
        const url = "/documents"
        log.debug(url, [...formData.entries()])
        log.debug(url, "jpayload", jdata)
        MyAxios.post(url, formData)
        .then((response) => {
          setLoading(false)
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          //alert(JSON.stringify(data))
          const documents = data.documents
          for (let i in documents) {
            documents[i].count = i
          }
          setRows(documents)
          const odt = getOptionList(data.document_type,'id',descriptionLangColumn(i18n.language))
          setOptions_document_type(odt)
        })
        .catch(function (error) {
            setLoading(false)
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [props.paziente.value,appAlert,forceReload,i18n.language])

    const deleteRow = (id,fn) => {
        const text = t("Cancellare il documento di tipo")+" "+fn+" ?"
        appConfirm(text,() => {
            const jdata = {
                action: 'DELETE',
                id: id,
            }
            const formData = new FormData()
            formData.append('jpayload', JSON.stringify(jdata))
            const url = "/documents"
            log.debug(url, [...formData.entries()])
            log.debug(url, "jpayload", jdata)
            MyAxios.post(url, formData)
            .then((response) => {
              const data = check_response(response);
              if(!data.success) {
                log.error(data.error)
                appAlert(data.error)
                return
              }
              setForceReload(prevCount => prevCount + 1)
            })
            .catch(function (error) {
                log.error(error);
                appAlert(error.message?error.message:JSON.stringify(error));
            })
        })
    }

    function reloadOnInsert() {
        setForceReload(prevCount => prevCount + 1)
    }

    function nuovo() {
        setRow(null)
        setForceReloadDialog(prevCount => prevCount + 1)
        setOpenInsertDialog(true)
    }

    function edit(row) {
        setRow(row)
        setForceReloadDialog(prevCount => prevCount + 1)
        setOpenInsertDialog(true)
    }

    const download = (id) => {
        get_document(id,appAlert)
    }

    function getRowId(row) {
        return row.count
    }

    if(loading)
        return <Loading />

    return (
      <div className="wt3">
        <Container component="main" className="displayFlex flexflowColumn height100">
            <Toolbar className="rowreverse">
                <Button variant="contained" onClick={nuovo} className="submitButton" > {t("translation:Nuovo documento")} </Button>
            </Toolbar>
            <div className="whiteback flex1">
                <DataTable columns={columns} rows={rows} density={"compact"} getRowId={getRowId} />
            </div>
        </Container>
        <DocumentsInsertDialog opendialog={openInsertDialog} setOpendialog={setOpenInsertDialog} paziente={props.paziente} reload={reloadOnInsert} row={row} forceReloadDialog={forceReloadDialog} options_document_type={options_document_type} />
      </div>
    )
}
