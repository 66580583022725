import React, { useMemo, useEffect, useState, useCallback } from "react";
import log from 'loglevel';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useTranslation } from 'react-i18next';
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import { getOptionListElement, Loading, MostTextField, MostSubmitButton, MostAutocomplete, } from "./components/MostComponents";
import MyAxios, { check_response } from "./MyAxios";
import { useForm } from "react-hook-form";

export const DbinthemPatient = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [forceReload, setForceReload] = useState(0);
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [loginmode, setLoginmode] = useState("");
    const [disabledButs, setDisabledButs] = useState(false)
    const [row, setRow] = useState([])
    const { control, register, handleSubmit, errors, } = useForm();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])
    const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
    function appAlert2(text,closeHandler) {
      setContent2(text)
      setOnClose2(() => x => {
        closeHandler()
      });
      setAlert2(true)
    }

    const options_login_mode = useMemo(() => [
        {value: "Email", label: t("Indirizzo e-mail")},
        {value: "Mobile", label: t("Numero di cellulare")},
    ], [t])

    useEffect(() => {
        function notNull(v) {
            if(!v)
                return ""
            return v
        }
        const jdata = {
            action: 'LIST',
            identifier: props.paziente.value,
        }
        MyAxios.post("/inthem_patient", jdata)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          setRow(data.row)
          if(data.row.INTHEM_CODE) {
            setEmail(notNull(data.row.CERTIFIED_EMAIL))
            setMobile(notNull(data.row.CERTIFIED_MOBILE))
            setLoginmode(getOptionListElement(options_login_mode,data.row.LOGIN_MODE))
          }
          setLoading(false)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,props.paziente,forceReload,options_login_mode])

    function openDbinthem () {
        const url = props.config.inthem_url+"/login"
        window.open(url,"db-inthem")
    }

    const doForce = () => {
        setDisabledButs(true)
        const jdata = {
            identifier: props.paziente.value,
            action: 'MODIFY',
            c_db: true,
            force: true,
        }
        MyAxios.post("/inthem_patient", jdata)
        .then((response) => {
            setDisabledButs(false)
            const res = check_response(response)
            if (res.success) {
                appAlert2(t("Modifiche registrate correttamente"),() => {
                    setLoading(true)
                    setForceReload(prevCount => prevCount + 1)
                })
            } else {
                log.error("Error: "+res.error);
                appAlert(res.error);
            }
        })
        .catch(function (error) {
            // handle error
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
        })
    }
    function syncDbinthem () {
        setLoading(true)
        const jdata = {
            action: 'INTHEM_SYNC',
            identifier: props.paziente.value,
        }
        const url = "/inthem_patient"
        MyAxios.post(url, jdata).then((response) => {
            const res = check_response(response)
            setLoading(false)
            if(res.success === false) {
                log.error("Error: "+res.error)
                appAlert(res.error)
                return
            }
            appAlert2(JSON.stringify(res),() => {
                setLoading(true)
                setForceReload(prevCount => prevCount + 1)
            })
        })
        .catch(function (error) {
            // handle error
            setLoading(false)
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }

    const onSubmit = (vals) => {
        const mobileVal = vals.mobile.trim()
        const login_mode = vals.login_mode.value
        const email = vals.email.trim().toLowerCase()
        if(login_mode === "Email" && email === "") {
            appAlert(t("Campo email obbligatorio"))
            return
        }
        if(login_mode === "Mobile" && mobileVal === "") {
            appAlert(t("Campo cellulare obbligatorio"))
            return
        }
        setDisabledButs(true)
        const jdata = {
            action: 'MODIFY',
            c_db: true,
            identifier: props.paziente.value,
            login_mode: login_mode,
            certified_mobile: mobileVal,
            certified_email: email,
        }
        MyAxios.post("/inthem_patient", jdata)
        .then((response) => {
            setDisabledButs(false)
            const res = check_response(response)
            if (res.success) {
                appAlert2(t("Modifiche registrate correttamente"),() => {
                    setLoading(true)
                    setForceReload(prevCount => prevCount + 1)
                })
            } else {
                let error = res.error
                if(error.includes("already present in Inthem")) {
                    error += '<br><br>'+t("Premi sincronizza")
                }
                log.error("Error: "+error)
                appAlert(error)
            }
        })
        .catch(function (error) {
            // handle error
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
        })
    }
    const onChangeEmail = (e) => {
        setEmail(e.target.value)
    }
    const onChangeMobile = (e) => {
        setMobile(e.target.value)
    }
    function boolval(v) {
        if(v === true)
            return t("Sì")
        if(v === false)
            return t("No")
        return t("Non ha ancora espresso il consenso")
    }

    if(loading)
        return <Loading />

    return (
        <div className="wt3">
        <Container component="main" maxWidth="sm">
          <Toolbar className="rowreverse">
            <Button variant="outlined" id="sync_db_inthem" onClick={syncDbinthem} className="grayButton" > {t("Sincronizza DB-INTHEM")} </Button>
            <Button variant="outlined" id="access_db_inthem" onClick={openDbinthem} className="grayButton marginr10" > {t("Accedi a DB-INTHEM")} </Button>
          </Toolbar>
            <div>
                <div className="boxForm grayTable">
                    { ! row.INTHEM_CODE ?
                        <span>
                            {t("Il paziente")} {props.paziente.row.surname + " " + props.paziente.row.name} {" "} <br />
                            {t("ha una diagnosi non prevista in DB-INTHEM")}
                        </span>
                    : row.ALREADY_IN_INTHEM ?
                        (row.FORCE === true ?
                            <span>
                                {t("consenso ma richiesta non inoltrata")}
                            </span>
                        :
                            <React.Fragment> 
                                {t("Questo è lo stato dei consensi del paziente") + " " + props.paziente.row.surname + " " + props.paziente.row.name + " " + t("in DB-INTHEM")}:
                            <br /> <br />
                            <table>
                            <tbody>
                            <tr>
                                <th>{t("Consenso DB-INTHEM")}</th><td>{boolval(row.C_DB)}</td>
                            </tr>
                            <tr>
                                <th>{t("Consenso a fornire dati personali")}</th><td>{boolval(row.C_DB_P)}</td>
                            </tr>
                            <tr>
                                <th>{t("Consenso a fornire dati medici")}</th><td>{boolval(row.C_DB_M)}</td>
                            </tr>
                            </tbody>
                            </table>
                            { row.C_DB === false ?
                                <React.Fragment>
                                    <br /> <br />
                                    <span> {t("consenso a nuovo inserimento")}
                                    </span>
                                     <MostSubmitButton disabled={disabledButs} label={t("Conferma")} className="grayButton" onClick={doForce} />
                                </React.Fragment>
                            : null }
                            </React.Fragment>
                        )
                    : 
                        <React.Fragment>
                        { row.C_DB === true ?
                            <span>
                                {t("Il paziente")}  {props.paziente.row.surname + " " + props.paziente.row.name} {" "}
                                {t("è in attesa di essere registrato in DB-INTHEM")} <br />
                                {t("Puoi ancora modificare i dati che saranno inviati a DB-INTHEM")}:
                            </span>
                        :
                            <span>
                                {t("Il paziente")}  {props.paziente.row.surname + " " + props.paziente.row.name} {" "}
                                {t("non è ancora presente in DB-INTHEM")} <br />
                                {t("se consenso inserisci dati")}:
                            </span>
                        }
                        <br /> <br />

                        <form onSubmit={handleSubmit(onSubmit)} disabled noValidate>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12}>
                                {/* nota: autocomplete controllato non funziona con control della form */}
                                <MostAutocomplete control={control} name="login_mode" options={options_login_mode} label={t("Campo di autenticazione")+" *"} required={true} rules={{ required: true }}  errors={errors} defaultValue={loginmode} />
                            </Grid>
                            <Grid item xs={12}>
                                <MostTextField name="email" register={register} label={t("E-mail")} value={email} onChange={onChangeEmail} />
                            </Grid>
                                <MostTextField name="mobile" register={register} label={t("Cellulare")} value={mobile} onChange={onChangeMobile} />
                            <Grid item xs={12}>
                                 <MostSubmitButton id="submit" disabled={disabledButs} label={t("Conferma")} color="secondary" />
                            </Grid>
                        </Grid>
                        </form>
                        </React.Fragment>
                    }
                </div>
            </div>
        </Container>
        </div>
    )
}
