import React, { useState, useEffect, } from "react";
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import MyAxios, {check_response} from "./MyAxios";
import { MostTextField, MostAutocomplete, } from "./components/MostComponents";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const DiagnosiInsertDialog = (props) => {
    const { t, } = useTranslation();
    const [diagnosi, setDiagnosi] = useState("")
    const [icd9, setIcd9] = useState(null)
    const [errorText, setErrorText] = useState(null)

    useEffect(() => {
        setErrorText(null)
        setDiagnosi("")
        setIcd9(null)
    }, [])
    
    const closeDialogFunc = () => {
        setErrorText(null)
        setDiagnosi("")
        setIcd9(null)
        props.setOpendialog(false)
    }
    const ionChangeIcd9 = (e,el) => {
        setIcd9(el)
    }
    const ionChangeDiagnosi = (e) => {
        setDiagnosi(e.target.value)
    }
    const handleSubmit = () => {
        log.debug("diagnosi",diagnosi)
        let jdata
        if (!diagnosi || diagnosi.trim() === "") {
            setErrorText("Diagnosi non inserita")
            return
        }
        if (!icd9) {
            setErrorText("Codice ICD9 non inserito")
            return
        }
        const dia = diagnosi.toUpperCase()
        jdata = {
            action: 'ADD',
            description_en: dia,
            description_it: dia,
            description_fr: dia,
            description_pt: dia,
            custom: true,
            DIAGNOSIS_GROUP: 'ALTRO',
            DISEASE_KEY: icd9.value,
        }
        // ?? fa casino chiude e riapre dialog ??
        //setDisabledButs(true)
        MyAxios.post("/supported_diagnosis", jdata)
        .then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            setErrorText(res.error)
            return
          }
          props.setOpendialog(false)
          props.reload()
        })
        .catch(function (error) {
            log.error(error);
            setErrorText(error.message?error.message:JSON.stringify(error));
        })
    }

    return (
        <Dialog open={props.opendialog} fullWidth maxWidth="sm" className="wt3">
            <DialogTitle>{t("Aggiunta di una nuova diagnosi")}</DialogTitle>
            <DialogContent>
                <div>
                    <MostTextField name="diagnosi_clinica" value={diagnosi} label={t("Diagnosi clinica")} onChange={ionChangeDiagnosi} inputProps={{ maxLength: 1998 }} />
                    <MostAutocomplete name="icd9" value={icd9} options={props.options_disease} label={t("Inserire codice ICD9")} onChange={ionChangeIcd9} />
                </div>
                <DialogContentText className="red">
                    {errorText}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="wt3">
                <Button id="annulla" onClick={closeDialogFunc} variant="outlined" color="secondary" className="font12" >
                    {t("Annulla")}
                </Button>
                <Button id="inserisci" onClick={handleSubmit} variant="contained" color="secondary" className="font12" >
                    {t("Aggiungi")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
