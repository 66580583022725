import React, { useState, useCallback, useEffect, } from "react";
import log from 'loglevel';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useTranslation } from "react-i18next";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Container from "@material-ui/core/Container";
import MyAxios, {check_response} from "./MyAxios";
import { DiagnosiInsertDialog } from "./DiagnosiInsertDialog";
import { DataTable } from './components/DataTable';
import { getOptionList, Loading, } from "./components/MostComponents";
import { addIdToRows, } from "./Utils";
//import { getLang, } from "./i18n";
import { descriptionLangColumn, } from './UtilsInthem'

export const Diagnosi = (props) => {
    const { t, i18n } = useTranslation()
    const descColumn = descriptionLangColumn(i18n.language)
    const [forceReload, setForceReload] = useState(0);
    const [loading, setLoading] = useState(true)
    const [options_disease, setOptions_disease] = useState([])
    const [rows, setRows] = useState([])
    const [openInsertDialog, setOpenInsertDialog] = useState(false)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
        setContent(text);
        setAlert1(true);
    }, [setContent,setAlert1])
    const { setConfirm1, setCContent, setOnConfirm } = useGlobalHook('confirmStore');
    function appConfirm(text,okHandler) {
      setCContent(text);
      setOnConfirm(() => x => {
        okHandler();
      });
      setConfirm1(true);
    }

    useEffect(() => {
        setLoading(true)
        function compare( a, b ) {
            // desc
            if ( a[descColumn] < b[descColumn] )
                return -1;
            if ( a[descColumn] > b[descColumn] )
                return 1;
            return 0;
        }
        const jdata = {
            action: 'LIST',
            //lang: getLang(i18n.language),
        }
        MyAxios.post("/supported_diagnosis", jdata)
        .then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            appAlert(res.error)
            return
          }
          const rows = res.supported_diagnosis
          rows.sort( compare )
          for (let i in rows) {
            if(rows[i].custom)
                rows[i].custom = t("No")
            else
                rows[i].custom = t("Sì")
          }
          setRows(addIdToRows(rows))
          const od = getOptionList(res.disease_list,'code','code+'+descColumn)
          setOptions_disease(od)
          setLoading(false)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,forceReload,descColumn,i18n.language,t])

    function deleteDiagnosi(row) {
        const text = "Cancellare la diagnosi "+row[descColumn]+" ?"
        appConfirm(text,() => {
            const jdata = {
                action: 'DELETE',
                code: row.code
            }
            MyAxios.post("/supported_diagnosis", jdata)
            .then((response) => {
              const data = check_response(response);
              if(!data.success) {
                log.error(data.error)
                appAlert(data.error)
                return
              }
              setForceReload(prevCount => prevCount + 1)
            })
            .catch(function (error) {
                log.error(error);
                appAlert(error.message?error.message:JSON.stringify(error));
            })
        })
    }
    function reloadOnInsert() {
        setForceReload(prevCount => prevCount + 1)
    }

    function nuovo() {
        setOpenInsertDialog(true)
    }

    const columns = [
        { field: 'custom', headerName: t('Standard'), width: 150, },
        { field: descColumn, headerName: t('Diagnosi clinica'), width: 400, },
        { field: 'disease_key', headerName: t('Codice ICD9'), width: 200, },
        { field: 'disease_description', headerName: t('Codice ICD9'), width: 300, },
        { field: 'code', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
          return (
            <Tooltip title={t("Rimuovi")}>
              <IconButton
                onClick={() => deleteDiagnosi(params.row)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
         )},
        },
    ]

    if(loading)
        return <Loading />
    return (
        <div className="wt3">
        <Container component="main" className="displayFlex flexflowColumn height100">
            <Toolbar className="rowreverse">
                <Button id="crea_nuova_diagnosi" variant="contained" onClick={nuovo} className="submitButton" > {t("Nuova diagnosi")} </Button>
            </Toolbar>
            { rows.length ?
                <div className="whiteback flex1">
                    <DataTable columns={columns} rows={rows} density={"compact"} disableSelectionOnClick={true} />
                </div>
            : 
                <div className="boxForm tcenter">
                    {t("Nessuna diagnosi")}
                </div>
            }
        </Container>
        <DiagnosiInsertDialog opendialog={openInsertDialog} setOpendialog={setOpenInsertDialog} options_disease={options_disease} reload={reloadOnInsert} />
        </div>
    )
}
