import React, { useState, Suspense, } from 'react';
import log from 'loglevel';
import { useIdleTimer } from 'react-idle-timer'
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Switch, Route, } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { itIT } from '@material-ui/data-grid';
import CssBaseline from "@material-ui/core/CssBaseline";
import { GlobalHooksProvider, createGlobalHook } from '@devhammed/use-global-hook'
import GuardedRoute from './GuardedRoute';
import { logInit } from './Utils';
import { Home } from './Home';
import { Login, Logout } from './Login';
import { ForgottenPasswd } from './auth/ForgottenPasswd';
import { UHome } from './UHome';
import { AgendeWin } from './AgendeWin';
import { Loading, Timeout, NoMatch, logoutReplacingWindowLocation } from './components/MostComponents';
import { getLang } from "./i18n";
import AlertDialog from './AlertDialog';
import { sendMessageToWt2, } from "./Wt2";
import * as locales from '@material-ui/core/locale';
// import { ProvideAuth } from "./use-auth";
//import logo from './Smartag.png';
import './App.css';

logInit()

class DebugRouter extends Router {
  constructor(props){
    super(props);
    log.debug('initial history is: ', JSON.stringify(this.history, null,2))
    this.history.listen((location, action)=>{
      log.debug(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      )
      log.debug(`The last navigation action was ${action}`, JSON.stringify(this.history, null,2));
    });
  }
}

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    {/* <img src={logo} className="App-logo" alt="logo" /> */}
    <Loading />
  </div>
);

const alertStore = createGlobalHook('alertStore', () => {
  const [alert1, setAlert1] = useState(false);
  const [content, setContent] = React.useState("")
  return { alert1, setAlert1, content, setContent }
})
const alert2Store = createGlobalHook('alert2Store', () => {
  const [alert2, setAlert2] = useState(false);
  const [content2, setContent2] = React.useState("")
  const [onClose2, setOnClose2] = React.useState(null)
  return { alert2, setAlert2, content2, setContent2, onClose2, setOnClose2 }
})
const confirmStore = createGlobalHook('confirmStore', () => {
  const [confirm1, setConfirm1] = useState(false);
  const [cContent, setCContent] = React.useState("")
  const [onConfirm, setOnConfirm] = React.useState(null)
  return { confirm1, setConfirm1, cContent, setCContent, onConfirm, setOnConfirm }
})
const userStore = createGlobalHook('userStore', () => {
  const [userInfo, setUserInfo] = useState({});
  return { userInfo, setUserInfo }
})
const loginStore = createGlobalHook('loginStore', () => {
  const [login1, setLogin1] = useState(false);
  const [lUsername, setLUsername] = useState("");
  const [llang, setLlang] = useState("");
  const [llocale, setLlocale] = useState("");
  return { login1, setLogin1, lUsername, setLUsername, llang, setLlang, llocale, setLlocale }
})

export default function App() {

  const { i18n } = useTranslation();
  let locale = null // enUS
  if (getLang(i18n.language) === "it")
    locale = "itIT"

  // in java timeout di 2 ore (7200 secondi, sia server side che client side)
  // in python timeout di 8 ore (480 minuti)
  // in react timeout di 1 ora : non abbiamo problemi di chiusura della sessione java
  // Nota: se andiamo in timeout chiudiamo applicazione anche se ci sono dati non salvati
  const timeout = 60 * 60 * 1000
  const [idleTimeout, setIdleTimeout] = useState(false);
  const handleOnIdle = () => {
    setIdleTimeout(true)
  }
  const resetIdleTimeout = () => {
    log.debug("resetIdleTimeout")
    setIdleTimeout(false)
    reset()
  }

  // forziamo logout senza se e senza ma
  function onTimeout() {
    sendMessageToWt2("timeout",() => {
        logoutReplacingWindowLocation()
    })
  }

  const {
    reset
  } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle
  })

  if(window.ethereum)
    window.ethereum.autoRefreshOnNetworkChange = false;

  window.__most = {}
  window.__most.palette = {
      primary: {
        main: '#61DAFB',        // colore molto chiaro
        contrastText: '#000000',
        // non usato da react
        darkColor: '#2299BB'    // colore medio tra primary e secondary
      },
      // secondary : usare button variant="outlined" 
      // per fare button con colore di sfondo, usare variant="contained", main: colore sfondo, contrastText: colore scritta
      secondary: {
        main: '#666',        // DEVE essere colore scuro (altrimenti outline non si vede)
        contrastText: '#ffffff',
      },
      /*
      info: {        // non funziona in alcuni casi, vuole solo primary o secondary -> portato a secondary
        main: '#e0e0e0',
        contrastText: '#000000',
      },
      */
      /*
      background: {
        default: '#999999',
      },
      */
  }

  const theme = createMuiTheme({
        palette: window.__most.palette,
      },
      // https://material-ui.com/guides/localization/
      locale ? locales[locale] : null,
      // https://material-ui.com/components/data-grid/localization/
      getLang(i18n.language) === "it" ? itIT : null
  );

  return (
  <ThemeProvider theme={theme}>
   <CssBaseline />
   <GlobalHooksProvider hooks={[ alertStore, alert2Store, confirmStore, userStore, loginStore ]}>
    <Suspense fallback={<Loader />}>
    <Timeout idleTimeout={idleTimeout} onTimeout={onTimeout} />
      <div className="App">
        <DebugRouter>
          <Switch>
            <Route path="/logout" key="logout">  <Logout />  </Route>
            <Route path="/:wtapp/logout" key="logout">  <Logout />  </Route>
            <Route path="/:wtapp/login" key="login"> <Login resetIdleTimeout={resetIdleTimeout} />  </Route>
            <Route path="/:wtapp/forgot_password" key="forgot_password">  <ForgottenPasswd />  </Route>
            <GuardedRoute path='/:wtapp/uhome' key="uhome" component={UHome} resetIdleTimeout={resetIdleTimeout} />
            <GuardedRoute path='/:wtapp/agendaT' key="agendaT" component={AgendeWin} />
            <GuardedRoute path='/:wtapp/agendaA' key="agendaA" component={AgendeWin} />
            <Route exact path="/" key="home">  <Home />  </Route> 
            <Route> <NoMatch /> </Route>
            {/*
            <Route path="/opusdocs" render={() => (window.location = "/OpusDocs")} />
            <Route path="/opusdocs0" render={() => (window.open("/OpusDocs", "_blank"))} />
            */}
          </Switch>
        </DebugRouter>
      </div>
    </Suspense>
    <AlertDialog name="alert"/>
    <AlertDialog name="alert2"/>
    <AlertDialog name="confirm"/>
    <AlertDialog name="login" resetIdleTimeout={resetIdleTimeout} />
   </GlobalHooksProvider>
  </ThemeProvider>
  );
}
