import React, { useEffect, useState, useCallback, } from "react";
import log from 'loglevel';
import { useParams, } from "react-router-dom";
import { useGlobalHook } from '@devhammed/use-global-hook'
import Tooltip from '@material-ui/core/Tooltip';
import HelpDialog from "./components/HelpDialog";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PrintIcon from '@material-ui/icons/Print';
import ReplayIcon from '@material-ui/icons/Replay';
import AddIcon from '@material-ui/icons/AddCircle';
import { Calendar, } from "react-big-calendar";
import { useTranslation } from 'react-i18next';
import { dmy_hms, dmy, ymd } from "./Utils";
import { CalendarEventDialog } from "./CalendarEventDialog";
import MyAxios, { check_response, check_and_download,} from "./MyAxios";
import { MostdocDialog } from "./MostdocDialog";
import { getOptionListLabel, getOptionList, MostAutocomplete, MostDatePicker, Loading, } from "./components/MostComponents";
import { getLang } from "./i18n";
import moment from "moment";
import globalize from 'globalize'
import localizer from 'react-big-calendar/lib/localizers/globalize'
import "react-big-calendar/lib/css/react-big-calendar.css";
require('globalize/lib/cultures/globalize.culture.it')
require('globalize/lib/cultures/globalize.culture.fr')
require('globalize/lib/cultures/globalize.culture.pt')
require('globalize/lib/cultures/globalize.culture.en-GB')

const globalizeLocalizer = localizer(globalize)

export const Agende = (props) => {
  let startingAgenda = "appuntamenti"
  if (props.mode === "agendaT")
    startingAgenda = "trasfusioni"
  const { wtapp } = useParams()
  const { i18n, t } = useTranslation()
  const lang = getLang(i18n.language)
  const [mostdocOpen, setMostdocOpen] = React.useState(false)
  const [mostdocSubmit, setMostdocSubmit] = React.useState(false)
  const [forceReload, setForceReload] = useState(0);
  const [dati, setDati] = useState(null)
  const [dialogMode, setDialogMode] = useState(null)
  const [options_stanze, setOptions_stanze] = useState([])
  const [options_dottori, setOptions_dottori] = useState([])
  const [gotDoctor, setGotDoctor] = useState(0)
  const [openInsertDialog, setOpenInsertDialog] = useState(false)
  const [currentdate, setCurrentdate] = useState(new Date())
  // cambio data in picker
  const handleDateChange = (date) => {
    // finche' data non e' corretta non facciamo nulla!
    if(date && !isNaN(date))
        setCurrentdate(date)
  }
  const [mode, setMode] = useState(startingAgenda)
  const [resetting, setResetting] = useState(false)
  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState([])
  const defaultView = "work_week"
  const [view, setView] = useState(defaultView)
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
  }, [setContent,setAlert1])
  const options_tipoeventi = [
        {value: "CONTROLLO_TRASFUSIONE", label: t("Controllo")},
        {value: "CONTROLLO_SETTIMANALE", label: t("Controllo emocromo")},
        {value: "CROCIATE", label: t("Prove crociate")},
        {value: "VISITA", label: t("Visita")},
  ]
  const [tipoeventi, setTipoeventi] = useState(null)
  const onChangeTipoeventi = (e,el) => {
    setTipoeventi(el)
  }
  const [stanza, setStanza] = useState(null)
  const onChangeStanze = (e,el) => {
    setStanza(el)
  }
  const [dottore, setDottore] = useState(null)
  const onChangeDottori = (e,el) => {
    setDottore(el)
  }

  const BCmessages = {
      date: t('Data'),
      time: t('Ora'),
      event: t('Evento'),
      allDay: t('Tutto il giorno'),
      week: t('Settimana'),
      work_week: t('Settimana'), // lavorativa
      day: t('Giorno'),
      month: t('Mese'),
      previous: '<<',
      next: '>>',
      yesterday: t('Ieri'),
      tomorrow: t('Domani'),
      today: t('Oggi'),
      agenda: t('Agenda'),

      noEventsInRange: t('Non ci sono eventi in questo periodo'),

      showMore: total => `+${total} eventi`,  // i18n ? (non utilizzato)
    }

    useEffect(() => {
        document.title = t("Agende")+" WebTHAL 3"
        const jdata = {
            action: 'LIST',
            also_room: true,
        }
        MyAxios.post("/doctor", jdata)
        .then((response) => {
            const data = check_response(response);
            if(!data.success) {
                log.error(data.error)
                appAlert(data.error)
                return
            }
            const d = []
            for(let i in data.doctors) {
                if(data.doctors[i].active)
                    d.push(data.doctors[i])
            }
            const od = getOptionList(d,'username','surname+name',0,1)
            //log.debug(od)
            setOptions_dottori(od)
            const r = []
            for(let i in data.rooms) {
                if(data.rooms[i].active)
                    r.push(data.rooms[i])
            }
            const or = getOptionList(r,'code','name')
            setOptions_stanze(or)
            setGotDoctor(1)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
        .then(function () {
            setLoading(false)
        })
    }, [appAlert,t])

  const getRoomName = useCallback((code) => {
    return getOptionListLabel(options_stanze,code)
  }, [options_stanze])

  const getDoctorName = useCallback((code) => {
    return getOptionListLabel(options_dottori,code)
  }, [options_dottori])

  const loadTrasfusioni = useCallback((da,a) => {
        function NNS( s ) {
            if(s === null)
                return ""
            return s
        }
        function compare( a, b ) {
            if ( a.agenda_date < b.agenda_date )
                return -1;
            if ( a.agenda_date > b.agenda_date )
                return 1;
            if ( a.tday < b.tday )
                return -1;
            if ( a.tday > b.tday )
                return 1;
            if ( NNS(a.turn) < NNS(b.turn) )
                return -1;
            if ( NNS(a.turn) > NNS(b.turn) )
                return 1;
            // note in testa: tipo NOTA TRASFUSIONE, ok ordine
            if ( a.tipo < b.tipo )
                return -1;
            if ( a.tipo > b.tipo )
                return 1;
            if ( a.surname < b.surname )
                return -1;
            if ( a.surname > b.surname )
                return 1;
            if ( a.name < b.surname )
                return -1;
            if ( a.name > b.surname )
                return 1;
            return 0;
        }
        setLoading(true)
        const formData = new FormData()
        formData.append('fromDate', ymd(da))
        formData.append('toDate', ymd(a))
        const url = "/eventiTrasfusionali"
        log.debug(url, [...formData.entries()])
        MyAxios.post(url, formData)
        .then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            appAlert(res.error)
            return
          }
          const rows = res.rows
          rows.sort( compare )
          let prevday = null
          let daycount = 0
          const evs = []
          for(let i in rows) {
            if (rows[i].tipo !== "NOTA") {
                if (rows[i].agenda_date !== prevday) {
                    daycount = 1
                    prevday = rows[i].agenda_date
                } else
                    daycount++
            }
            const d = new Date(rows[i].agenda_date)
            /* xxx intestazioni colonne ? bisogna poi gestire il tipo HEADER ..
            if (daycount === 1)
                evs.push({
                    daycount: 0,
                    start: d,
                    end: d,
                    tipo: "HEADER",
                    allDay: true,
                })
            */
            // DEFINIZIONE EVENTO TRASFUSIONI
            const ev = {
                daycount: daycount,
                start: d,
                end: d,
                tipo: rows[i].tipo,
                identifier: rows[i].identifier,
                tday: rows[i].tday, // T1 T2 T3
                turn: rows[i].turn, // G1 G2
                folder_id: rows[i].folder_id,
                surname: rows[i].surname ? rows[i].surname.toLowerCase() : null,
                birth_date: rows[i].birth_date,
                esami: rows[i].esami,     // TRASFUSIONE
                dettagli: rows[i].esami,  // NOTA
                mostdoc__key: rows[i].mostdoc__key_nota,
                mostdoc__key_blood_request: rows[i].mostdoc__key_blood_request,
                name: rows[i].name ? rows[i].name.toLowerCase() : null,
                numUnita: rows[i].numUnita,
                qtaRichiesta: rows[i].qtaRichiesta,
                preMed: rows[i].preMed,
                preTransfusionTherapy: rows[i].preTransfusionTherapy,
                tipoUnita: rows[i].tipoUnita,
                tipoUnitaDescription: rows[i].tipoUnitaDescription,
                allDay: true,
              }
            evs.push(ev)
          }
          setEvents(prevEvents => prevEvents.concat(evs))
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
        .then(function () {
            setLoading(false)
            setResetting(false)
        })
  }, [appAlert])

  const getTipoAppuntamentoDesc = useCallback((tipo,what,room,doctor) => {
    if (tipo === "NOTA") {
        if(what==="value")
            return "A"
        return t("NOTA")
    }
    if (tipo === "CROCIATE") { // wtto2
        if(what==="value")
            return "B"
        return t("Prove crociate")
    }
    if (tipo === "CONTROLLO_TRASFUSIONE") {   // wtge2
        if(what==="value")
            return "C"
        return t("Controllo")
    }
    if (tipo === "VISITA") {
        if(what==="value")
            return "D"+(room?getRoomName(room):"  ")+(doctor?getDoctorName(doctor):"  ")
        return t("Visita")
    }
    if (tipo === "CONTROLLO_SETTIMANALE") {
        if(what==="value")
            return "E"
        return t("Controllo emocromo")
    }
    if(what==="value")
        return "F"
    return t("Appuntamento")
  }, [t,getRoomName,getDoctorName])

  const loadAppuntamenti = useCallback((da,a) => {
        function compare( a, b ) {
            if ( a.dataevento < b.dataevento )
                return -1;
            if ( a.dataevento > b.dataevento )
                return 1;
            if ( !a.time_from && b.time_from )
                return -1;
            if ( a.time_from && !b.time_from )
                return 1;
            if ( a.time_from && b.time_from && a.time_from < b.time_from )
                return -1;
            if ( a.time_from && b.time_from && a.time_from > b.time_from )
                return 1;
            if ( getTipoAppuntamentoDesc(a.tipo,"value",a.room,a.doctor) < getTipoAppuntamentoDesc(b.tipo,"value",b.room,b.doctor) )
                return -1;
            if ( getTipoAppuntamentoDesc(a.tipo,"value",a.room,a.doctor) > getTipoAppuntamentoDesc(b.tipo,"value",b.room,b.doctor) )
                return 1;
            if ( a.surname < b.surname )
                return -1;
            if ( a.surname > b.surname )
                return 1;
            if ( a.name < b.surname )
                return -1;
            if ( a.name > b.surname )
                return 1;
            return 0;
        }
        setLoading(true)
        const formData = new FormData()
        formData.append('fromDate', ymd(da))
        formData.append('toDate', ymd(a))
        const url = "/eventiAppuntamento"
        log.debug(url, [...formData.entries()])
        MyAxios.post(url, formData)
        .then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            appAlert(res.error)
            return
          }
          const rows = res.rows
          rows.sort( compare )
          const evs = []
          let prevday = null
          let daycount = 0
          let firstVisita
          let prevodd
          for(let i in rows) {
            // FILTRI
            if (tipoeventi && tipoeventi.value !== rows[i].tipo)
                continue
            if (dottore && dottore.value !== rows[i].doctor)
                continue
            if (stanza && stanza.value !== rows[i].room)
                continue
            if (rows[i].tipo !== "NOTA") {
                if (rows[i].dataevento !== prevday) {
                    daycount = 1
                    prevday = rows[i].dataevento
                    firstVisita = true
                } else
                    daycount++
            }
            let odd=undefined
            if (rows[i].tipo === "VISITA") {
                if(firstVisita) {
                    odd=true
                    prevodd=true
                    firstVisita=false
                } else {
                    if(rows[i-1].room === rows[i].room) {
                        odd = prevodd
                    } else {
                        odd = !prevodd
                        prevodd = odd
                    }
                }
            }
            const d = new Date(rows[i].dataevento)
            // DEFINIZIONE EVENTO APPUNTAMENTI
            const ev = {
                daycount: daycount,
                odd: odd,
                start: d,
                end: d,
                tipo: rows[i].tipo,
                mostdoc__key: rows[i].mostdoc__key,
                room: rows[i].room,
                doctor: rows[i].doctor,
                patient_id: rows[i].patient_id,
                dettagli: rows[i].dettagli,
                birth_date: rows[i].birth_date,
                diagnosis: rows[i].diagnosis,
                diagnosis_description: rows[i].diagnosis_description,
                folder_id: rows[i].folder_id,
                name: rows[i].name ? rows[i].name.toLowerCase() : null,
                surname: rows[i].surname ? rows[i].surname.toLowerCase() : null,
                allDay: true,
              }
            if (rows[i].time_from) {
                ev.allDay = false
                ev.start = new Date(rows[i].dataevento+" "+rows[i].time_from)
                ev.end = new Date(rows[i].dataevento+" "+rows[i].time_to)
            } 
            evs.push(ev)
          }
          setEvents(prevEvents => prevEvents.concat(evs))
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
        .then(function () {
            setLoading(false)
            setResetting(false)
        })
  }, [appAlert,getTipoAppuntamentoDesc,tipoeventi,dottore,stanza])

  useEffect(() => {
        // allo startup dobbiamo aspettare che arrivi l'elenco di stanze e dottori
        // altrimenti questa routine che dovrebbe essere eseguita una volta sola
        // viene eseguita 3 volte
        if(!gotDoctor)
            return
        setEvents([])
        // rimuoviamo calendario e ripartiamo da zero
        setResetting(true)
        let da = currentdate
        let a = currentdate
        if(view !== "day") {
            // work_week
            da = moment(currentdate).startOf('isoWeek').toDate()
            a = moment(currentdate).endOf('isoWeek').toDate()
        }
        if(mode === "appuntamenti") {
            loadAppuntamenti(da,a)
        } else {
            loadTrasfusioni(da,a)
        }
  }, [loadTrasfusioni,loadAppuntamenti,mode,view,currentdate,tipoeventi,forceReload,gotDoctor])

  const emocromo = () => {
    setMostdocOpen(true)
    setMostdocSubmit(true)
  }

  const handleSelectEvent = (event) => {
    log.debug("handleSelectEvent",event)
    // se e' una nota andiamo in modifica
    if(event.tipo === "NOTA") {
        setDialogMode("edit")
        setDati(event)
        setOpenInsertDialog(true)
        return
    }
    let html = ""
    html += "<b>"+t("Data")+":</b> "+dmy_hms(event.start,2)
    if(!event.allDay) {
        html += dmy_hms(event.start).substring(0,5)+" - "
        html += dmy_hms(event.end).substring(0,5)
    }
    html += "<br />"
    if(event.tipo)
        html += "<b>"+t("Tipo")+":</b> "+event.tipo+"<br />"
    if(event.room)
        html += "<b>"+t("Ambulatorio")+":</b> "+getRoomName(event.room)+"<br />"
    if(event.doctor)
        html += "<b>"+t("Medico")+":</b> "+getDoctorName(event.doctor)+"<br />"
    if(event.patient_id || event.identifier) {
        html += "<b>"+t("Paziente")+":</b> <span class='capitalize'>"+event.surname+" "+event.name+"</span> (n."+dmy(event.birth_date)+") "+(event.folder_id ? "c."+event.folder_id : "")+"</span> <br />"
        if(event.diagnosis_description)
            html += "<b>"+t("Diagnosi")+":</b> "+event.diagnosis_description+"<br />"
    }
    if(event.tday)
        html += "<b>"+t("Giornata")+":</b> "+event.tday+"<br />"
    if(event.turn)
        html += "<b>"+t("Turno")+":</b> "+event.turn+"<br />"
    if(event.qtaRichiesta)
        html += "<b>"+t("Quantità richiesta")+":</b> "+event.qtaRichiesta+"<br />"
    if(event.numUnita)
        html += "<b>"+t("Numero di unità")+":</b> "+event.numUnita+"<br />"
    if(event.tipoUnitaDescription)
        html += "<b>"+t("Tipo di preparazione")+":</b> "+event.tipoUnitaDescription+"<br />"
    if(event.preTransfusionTherapy)
        html += "<b>"+t("Pre-medicazione")+":</b> "+event.preTransfusionTherapy+"<br />"
    if(event.esami)
        html += "<b>"+t("Esami")+":</b><br />"+event.esami+"<br />"
    if(event.dettagli)
        html += "<br />"+event.dettagli+"<br />"
    appAlert(html)
  }

  const handleSelectSlot = ({ start, end }) => {
    if(mode !== "appuntamenti")
        return
    setDialogMode(null)
    setDati({start:start, end:end, allDay:false})
    setOpenInsertDialog(true)
  }

  const print = () => {
    const jdata = {
        agenda_date: ymd(currentdate),
    }
    if(mode === "appuntamenti") {
        jdata.agenda_type = 'APPOINTMENT'
        if(stanza)
            jdata.room = stanza.value
        if(dottore)
            jdata.doctor = dottore.value
        if(tipoeventi)
            jdata.tipoeventi = tipoeventi.value
    }
    else
        jdata.agenda_type = 'TRANSFUSIONAL'
    MyAxios.post("/agenda_print", jdata)
    .then((response) => {
        log.debug(response)
        const fileName = 'agenda_'+mode+"_"+ymd(currentdate)+'.pdf'
        const res = check_and_download(response,'pdf','pdf',fileName,1)
        if(res.success === false) {
            log.error("Error: "+res.error)
            appAlert(res.error)
            return
        }
    })
    .catch(function (error) {
        log.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    })
  }

  const openInsertDialogFunc = () => {
    setDati(null)
    setDialogMode(null)
    setOpenInsertDialog(true)
  }

  // cambio data in calendar
  // https://github.com/jquense/react-big-calendar/issues/342
  const handleNavigate = ( date, view ) => {
    setCurrentdate(date)
  }

  function reloadOnInsert() {
    setForceReload(prevCount => prevCount + 1)
  }

  function Event({ event }) {
    const tipodesc = getTipoAppuntamentoDesc(event.tipo)
    if (event.tipo === "NOTA") {
        // if(view === "day")
        return (
                <div className="myevent notaevent">
                    <span className={event.tipo}>{tipodesc}:</span> {" "}
                    { event.room ? <span className="notaRoom">[{getRoomName(event.room)}] </span> : null } {" "}
                    { event.doctor ? <span className="notaDoctor">[{getDoctorName(event.doctor)}]</span> : null } {" "}
                    {event.dettagli}
                </div>
        )
    }
    const PatientInfo = ( props ) => {
        let name
        if (view === "day")
            name = <b><span className="capitalize">{props.event.surname+" "+props.event.name}</span></b>
        else
            name = <span className="capitalize">{props.event.surname+" "+props.event.name}</span>
        if (props.short)
            return name
        return (
            <span>{name} ({t("nato_abbr")}{dmy(props.event.birth_date)}) {props.event.folder_id ? t("cartella_abbr")+props.event.folder_id : ""}</span>
        )
    }
    if(mode === "appuntamenti") {
        if(view === "day") {
          return (
            <div className="myevent day">
              <div className="w25 taright">{event.daycount}</div>
              <div className={"w15p "+event.tipo}>{tipodesc}</div>
              <div className="w20p"><PatientInfo event={event} /></div>
              <div className="w20p">{event.diagnosis_description}</div>
              <div className="flex1">
                { event.room ? <span className="notaRoom">[{getRoomName(event.room)}] </span> : null } {" "}
                { event.doctor ? <span className="notaDoctor">[{getDoctorName(event.doctor)}]</span> : null } {" "}
                {event.dettagli}
              </div>
            </div>
          )
        }
        // week
        return (
            <div className="myevent week">
              <table>
              <tbody>
               <tr>
                <td className="w25 vertalignTop"> {event.daycount}</td>
                <td> 
                    <span className={event.tipo}>{tipodesc}</span> {" "}
                    { event.room ? <span className="notaRoom">[{getRoomName(event.room)}] </span> : null } {" "}
                    { event.doctor ? <span className="notaDoctor">[{getDoctorName(event.doctor)}]</span> : null } {" "}
                    <br />
                    <PatientInfo event={event} />
                </td>
               </tr>
              </tbody>
              </table>
            </div>
        )
    } else {
        // trasfusionale
        if(view === "day") {
          return (
            <div className="myevent day">
              <div className="w25 taright">{event.daycount}</div>
              <div className={"w30 DAY_"+event.tday}>{event.tday}</div>
              <div className={"w30 TURN_"+event.turn}>{event.turn}</div>
              <div className="paziente w20p"><PatientInfo event={event} /></div>
              <div className="w50 taright">{event.qtaRichiesta}</div>
              <div className="w30 taright">{event.numUnita}</div>
              <div className="w150_20p">{event.tipoUnitaDescription}</div>
              <div className="w150_20p">{event.preTransfusionTherapy}</div>
              <div className="flex1 minw100">{event.esami}</div>
            </div>
          )
        }
        return (
            <div className="myevent week">
              <table>
              <tbody>
               <tr>
                <td className="w25 vertalignTop"> {event.daycount}</td>
                <td className={"w30 vertalignTop DAY_"+event.tday}> {event.tday}</td>
                <td className={"w30 vertalignTop TURN_"+event.turn}> {event.turn}</td>
                <td>
                    <PatientInfo event={event} short={true} />
                </td>
               </tr>
              </tbody>
              </table>
            </div>
        )
    }
  }

    // xxx giorni festivi
    // xxx border sballa allineamento slots
    const customDayPropGetter = date => {
      return {}
      /*
      if (date.getDate() === 25)
        return {
          className: 'special-day',
        }
      else return {}
      */
    }

    // xxx giorni festivi
    const customSlotPropGetter = date => {
      return {}
      /*
      if (date.getDate() === 25)
        return {
          className: 'special-day',
        }
      else 
        return {}
      */
    }

    function onView(view){
        log.debug("onView",view)
        setView(view)
    }

    const customEventPropGetter = e => {
        var backgroundColor='white'
        if(e.odd !== undefined) { 
            if (e.odd)
                backgroundColor='#efefef'
            else
                backgroundColor='#efffef'
        }
        var style = {
            backgroundColor: backgroundColor,
            //borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '1px',
            borderStyle: 'solid',
            borderColor: 'gray',
            display: 'block'
        }
        return {
            style: style
        }
    }

  const state = {
    culture: lang,
    dayLayoutAlgorithm: 'no-overlap',
    // mese non funziona piu' ?
    //views: ['day', 'work_week', 'month'],
    views: ['day', 'work_week', ],
    defaultView: view,
    min: new Date(0, 0, 0, 7, 0, 0),
    step: 15,        // duration of the slot
    timeslots: 4,
  };
  if(mode === "appuntamenti") {
    // 7 - 20
    state.max = new Date(0, 0, 0, 20, 0, 0)
  } else {
    // 7 - 7 : non visualizza slot orari
    state.max = new Date(0, 0, 0, 7, 0, 0)
  }

  const changeMode = (event, value) => {
    setMode(value);
  }

  if(! window.top.decrypted && window.opener) {
    window.top.decrypted = window.opener.decrypted
  }

  // se si forza lo scrolling a livello di boxForm (xapp-container xcontent-container)
  // funziona tutto SOLO SE la parte di intestazione è piccola e non va
  // oltre la dimensione dello schermo; lo scrolling è gestito solo nella
  // parte sottostante (orari). Ma con molti appuntamenti a livello di
  // intestazione, parte sotto scompare
  return (
      <div className="wt3 height100">
        <div className="width95 center minw500">
          <div className="agendeHeader boxForm">
          <Grid container spacing={1}>
              <Grid item xs={12} lg={6}>
                <div className="agendeHeaderDiv flexStart">
                <ToggleButtonGroup
                  value={mode}
                  exclusive
                  size="small"
                  onChange={changeMode}
                >
                    <ToggleButton value="appuntamenti">
                        {t("Agenda appuntamenti")}
                    </ToggleButton>
                    <ToggleButton value="trasfusioni">
                        {t("Agenda trasfusionale")}
                    </ToggleButton>
                </ToggleButtonGroup>
                <div className="w200 alignCenter height100">
                    <MostDatePicker label={t("Data")} openTo="date" disableFuture={false} value={currentdate} onChange={handleDateChange} margin="none" />
                </div>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className="agendeHeaderDiv flexEnd alignCenter height100">
                    <Button variant="outlined" startIcon={<PrintIcon />} onClick={print} className="font12 w100" >
                        {t("Stampa")}
                    </Button>
                    <Tooltip title={t("Stampa Controllo settimanale emocromo")}>
                        <Button variant="outlined" startIcon={<PrintIcon />} onClick={emocromo} className="font12 w100" >
                            {t("Emocromo")}
                        </Button>
                    </Tooltip>
                    <Button variant="outlined" startIcon={<AddIcon />} onClick={openInsertDialogFunc} className="font12 nowrap w100" >
                        {t("Nuova nota")}
                    </Button>
                    <Button variant="outlined" startIcon={<ReplayIcon />} onClick={reloadOnInsert} className="font12 w100" >
                        {t("Aggiorna")}
                    </Button>
                    <HelpDialog />
                </div>
              </Grid>
                { mode === "appuntamenti" ?
              <Grid item xs={12}>
                <div className="agendeHeaderDiv">
                    <div className="flex1" />
                    <div>
                        {t("Filtro")}:
                    </div>
                    <div className="w200"> 
                        <MostAutocomplete options={options_tipoeventi} label={t("Tipo di evento")} value={tipoeventi} onChange={onChangeTipoeventi} />
                    </div>
                    <div className="w200"> 
                        <MostAutocomplete options={options_stanze} label={t("Ambulatorio")} value={stanza} onChange={onChangeStanze} />
                    </div>
                    <div className="w200"> 
                        <MostAutocomplete options={options_dottori} label={t("Medico")} value={dottore} onChange={onChangeDottori} />
                    </div>
                </div>
              </Grid>
                : null }
          </Grid>
          </div>
          <div className="boxForm xcontent-container">
            { loading||resetting ? <Loading /> : null }
            { resetting ? null
            : <div className={loading ? "hidden" : null}>
                <Calendar
                  date={currentdate}
                  events={events}
                  localizer={globalizeLocalizer}
                  messages={BCmessages}
                  culture={state.culture}
                  dayLayoutAlgorithm={state.dayLayoutAlgorithm}
                  defaultView={state.defaultView}
                  min={state.min}
                  max={state.max}
                  views={state.views}
                  step={state.step}
                  timeslots={state.timeslots}
                  onView={onView}
                  selectable
                  onSelectEvent={handleSelectEvent}
                  onSelectSlot={handleSelectSlot}
                  onNavigate={handleNavigate}
                  dayPropGetter={customDayPropGetter}
                  slotPropGetter={customSlotPropGetter}
                  eventPropGetter={customEventPropGetter}
                  components={{
                      event: Event,
                  }}
                />
              </div>
            }
          </div>
        </div>
        <CalendarEventDialog currentdate={currentdate} opendialog={openInsertDialog} setOpendialog={setOpenInsertDialog} dati={dati} paziente={props.paziente} options_stanze={options_stanze} options_dottori={options_dottori} reload={reloadOnInsert} dialogMode={dialogMode} agenda={mode} />
        <MostdocDialog mostdocSubmit={mostdocSubmit} mostdocOpen={mostdocOpen} setMostdocOpen={setMostdocOpen} wtapp={wtapp} info4decrypt={window.top.decrypted} />
      </div>
  );
}

