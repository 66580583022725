import React, { useState, useCallback, useEffect, } from "react";
import log from 'loglevel';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import MyAxios, {check_response} from "./MyAxios";
import { Loading, MostAutocomplete, MostSubmitButton, MostTextField, getOptionList, getOptionListElement} from "./components/MostComponents";

export const Covid = (props) => {
    const { t, i18n } = useTranslation()
    const { control, handleSubmit, register } = useForm();
    const [optionStatus, ] = useState([
        { label:t("contratto_infezione"), value:"true" },
        { label:t("non_contratto_infezione"), value:"false" },
    ])
    const [covid_status, setCovid_status] = useState(null)
    const [optionSymptom, setOptionSymptom] = useState([])
    const [optionDetection, setOptionDetection] = useState([])
    const [optionSource, setOptionSource] = useState([])
    const [optionHospitalization, setOptionHospitalization] = useState([])
    const [optionOutcome, setOptionOutcome] = useState([])
    const [forceReload, setForceReload] = useState(0);
    const [got2, setGot2] = useState(false)
    const [data, setData] = useState([])
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
        setContent(text);
        setAlert1(true);
    }, [setContent,setAlert1])
    const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
    function appAlert2(text,closeHandler) {
        setContent2(text)
        setOnClose2(() => x => {
        closeHandler()
        });
        setAlert2(true)
    }

    function toInt(s) {
        if(!s || s.trim() === '')
            return null
        return parseInt(s)
    }

    useEffect(() => {
        const covid_data = {};
        covid_data['action'] = "LIST";
        covid_data['patient_id'] = props.paziente.value;
        MyAxios.post("/covid", covid_data)
        .then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            appAlert(res.error)
            return
          }
          //log.debug(res.covid_row)
          if (res.covid_row && res.covid_row.id) {
            const optionStatusValue = res.covid_row.covid_status ? "true" : "false"
            setCovid_status(getOptionListElement(optionStatus,optionStatusValue))
            setData(res.covid_row)
          } 
          const trans = "trans_" + i18n.language
          setOptionSymptom(getOptionList(res.covid_symptom,'id',trans))
          setOptionDetection(getOptionList(res.covid_detection,'id',trans))
          setOptionSource(getOptionList(res.covid_source,'id',trans))
          setOptionHospitalization(getOptionList(res.covid_hospitalization,'id',trans))
          setOptionOutcome(getOptionList(res.covid_outcome,'id',trans))
          setGot2(true)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,props.paziente.value,i18n.language,forceReload,optionStatus,])

    const onSubmitUpload = (vals) => {
        log.debug(vals)
        if (covid_status === null) {
            appAlert(t("stato")+": "+t("campo obbligatorio"))
            return
        }
        const covid_data = {}
        covid_data['action'] = data.id ? "MODIFY" : "ADD"
        covid_data['patient_id'] = props.paziente.value
        if (data.id)
            covid_data['id'] = data.id
        covid_data['covid_status'] = covid_status.value === "true" ? true : false
        if (covid_data['covid_status']) {
            covid_data['covid_symptom'] = vals.symptom ? vals.symptom.value : null
            covid_data['covid_detection'] = vals.detection ? vals.detection.value : null
            covid_data['covid_source'] = vals.source ? vals.source.value : null
            covid_data['covid_hospitalization'] = vals.hospitalization ? vals.hospitalization.value : null
            covid_data['covid_outcome'] = vals.outcome ? vals.outcome.value : null
            covid_data['covid_hosp_days'] = toInt(vals.hosp_days)
            covid_data['covid_disease_days'] = toInt(vals.disease_days)
            covid_data['covid_therapy'] = vals.therapy
        } else {
            covid_data['covid_symptom'] = null
            covid_data['covid_detection'] = null
            covid_data['covid_source'] = null
            covid_data['covid_hospitalization'] = null
            covid_data['covid_outcome'] = null
            covid_data['covid_hosp_days'] = null
            covid_data['covid_disease_days'] = null
            covid_data['covid_therapy'] = null
        }
        MyAxios.post("/covid", covid_data)
        .then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            appAlert(res.error)
            return
          }
          appAlert2(t("Modifiche registrate correttamente"),() => {
            setForceReload(prevCount => prevCount + 1)
          })
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }

    const handleStatusChange = (e,el) => {
        setCovid_status(el)
    }

    if(!got2)
        return <Loading />

    let nonmalato = true
    if (covid_status && covid_status.value === "true") 
        nonmalato = false

    return (
        <div className="wt3">
        <Container component="main">
            <div className="margintop30">
                <div className="boxForm margintop30 insertBox">
                <form onSubmit={handleSubmit(onSubmitUpload)} noValidate>
                <Grid container spacing={1} alignItems="center" className="blackColor">
                     <Grid item xs={12}>
                         <MostAutocomplete name="status" options={optionStatus} label={t("stato")+" *"} value={covid_status} onChange={handleStatusChange} />
                     </Grid>
                     <Grid item xs={12}>
                         <MostAutocomplete disabled={nonmalato} control={control} name="symptom" options={optionSymptom} label={t("symptom")} defaultValue={getOptionListElement(optionSymptom, data.covid_symptom)} />
                     </Grid>
                     <Grid item xs={12}>
                         <MostAutocomplete disabled={nonmalato} control={control} name="detection" options={optionDetection} label={t("detection")} defaultValue={getOptionListElement(optionDetection, data.covid_detection)} />
                     </Grid>
                     <Grid item xs={12}>
                         <MostAutocomplete disabled={nonmalato} control={control} name="source" options={optionSource} label={t("source")} defaultValue={getOptionListElement(optionSource, data.covid_source)} />
                     </Grid>
                     <Grid item xs={12}>
                         <MostAutocomplete disabled={nonmalato} control={control} name="hospitalization" options={optionHospitalization} label={t("hospitalization")} defaultValue={getOptionListElement(optionHospitalization, data.covid_hospitalization)} />
                     </Grid>
                     <Grid item xs={12}>
                        <MostTextField name="hosp_days" disabled={nonmalato} inputProps={{type:'number'}} defaultValue={data.covid_hosp_days} label={t("hosp_days")} register={register} />
                     </Grid>
                     <Grid item xs={12}>
                        <MostTextField name="disease_days" disabled={nonmalato} inputProps={{type:'number'}} defaultValue={data.covid_disease_days} label={t("disease_days")} register={register} />
                     </Grid>
                     <Grid item xs={12}>
                        <MostTextField name="therapy" disabled={nonmalato} defaultValue={data.covid_therapy} label={t("therapy")} register={register} multiline={true} rows={5} inputProps={{ maxLength: 1998 }} />
                     </Grid>
                     <Grid item xs={12}>
                         <MostAutocomplete control={control} name="outcome" disabled={nonmalato} options={optionOutcome} label={t("outcome")} defaultValue={getOptionListElement(optionOutcome,data.covid_outcome)} />
                     </Grid>
                    <Grid item xs={12}>
                        <MostSubmitButton id="modifica" label={t("Modifica")} color="secondary" className="font12" />
                    </Grid>
                </Grid>
                </form>
                </div>
            </div>
        </Container>
        </div>
    )
}

