import React, { useState, useEffect, } from "react";
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import MyAxios, {check_response} from "./MyAxios";
import { ymd } from "./Utils";
import { S3Field, getOptionListElement, MostDatePicker, MostAutocomplete, } from "./components/MostComponents";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const EsenzioniInsertDialog = (props) => {
    const { t, } = useTranslation();
    const [apertura, setApertura] = useState(null)
    const [scadenza, setScadenza] = useState(null)
    const [esenzione, setEsenzione] = useState(null)
    const [errorText, setErrorText] = useState(null)
    const [uploadInfo, setUploadInfo] = useState(null)
    const [uploadTarget, setUploadTarget] = useState(null)
    const [docRemoved, setDocRemoved] = useState(false)
    const [disabledButs, setDisabledButs] = useState(false)

    // nota: con uso di forceReloadDialog viene richiamata ad ogni apertura
    useEffect(() => {
        if (props.row) {
            // modifica
            if(props.row.open_date)
                setApertura(new Date(props.row.open_date))
            else
                setApertura(null)
            if(props.row.expiration_date)
                setScadenza(new Date(props.row.expiration_date))
            else
                setScadenza(null)
            setEsenzione(getOptionListElement(props.options_esenzioni,props.row.exemption))
            setDocRemoved(false)
        } else {
            setApertura(null)
            setScadenza(null)
            setEsenzione(null)
        }
        setErrorText(null)
        setUploadTarget(null)
        setUploadInfo(null)
    }, [props.row,props.options_esenzioni,props.forceReloadDialog])

    const aperturaChange = (date) => {
        setApertura(date)
    }
    const scadenzaChange = (date) => {
        setScadenza(date)
    }
    const closeDialogFunc = () => {
        props.setOpendialog(false)
    }
    const ionChangeEsenzione = (e,el) => {
        setEsenzione(el)
    }
    const handleSubmit = () => {
        if(isNaN(apertura) || isNaN(scadenza)) {
            setErrorText(t("Data non valida"))
            return
        }
        if (!esenzione) {
            setErrorText(t("Codice esenzione non inserito"))
            return
        }
        setDisabledButs(true)
        const jdata = {
            open_date: ymd(apertura),
            expiration_date: ymd(scadenza),
            patient_id: props.paziente.value,
            code: esenzione.value,
        }
        if (props.row) {
            jdata.action = 'MODIFY'
        } else {
            jdata.action = 'ADD'
        }
        const formData = new FormData()
        if(props.row && props.row.s3_id && docRemoved && !uploadInfo)
            jdata.delete_file = true
        if(uploadInfo)
            formData.append('document_file', uploadInfo[0])
        formData.append('jpayload', JSON.stringify(jdata))
        const url = '/patient_exemption'
        log.debug(url, [...formData.entries()])
        log.debug(url, "jpayload", jdata)
        MyAxios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            setErrorText(res.error)
            return
          }
          closeDialogFunc()
          // diamo il tempo di eseguire il then finale
          setTimeout(()=>{
            props.reload()
          },200)
        })
        .catch(function (error) {
            log.error(error);
            setErrorText(error.message?error.message:JSON.stringify(error));
        })
        .then(function () {
            if(uploadTarget)
                uploadTarget.value = null
            setUploadInfo(null)
            setDisabledButs(false)
        })
    }

    return (
        <Dialog open={props.opendialog} fullWidth maxWidth="sm" className="wt3">
            <DialogTitle>{props.row ? t("Modifica esenzione") : t("Nuova esenzione")}</DialogTitle>
            <DialogContent>
                <div>
                    <MostAutocomplete value={esenzione} options={props.options_esenzioni} label={t("Inserire codice esenzione")} onChange={ionChangeEsenzione} />
                    <MostDatePicker openTo="date" disableFuture={true} value={apertura} onChange={aperturaChange} label={t("Apertura")} />
                    <MostDatePicker openTo="year" disableFuture={false} value={scadenza} onChange={scadenzaChange} label={t("Scadenza")} />
                    <S3Field label={t("Documento allegato")} s3_id={props.row ? props.row.s3_id : null} showError={setErrorText} docRemoved={docRemoved} setDocRemoved={setDocRemoved} setUploadInfo={setUploadInfo} setUploadTarget={setUploadTarget} />
                </div>
                <DialogContentText className="red">
                    {errorText}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="wt3">
                <Button onClick={closeDialogFunc} disabled={disabledButs} variant="outlined" color="secondary" className="font12" >
                    {t("Annulla")}
                </Button>
                <Button onClick={handleSubmit} disabled={disabledButs} variant="contained" color="secondary" className="font12" >
                    {props.row ? t("Modifica") : t("Aggiungi")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
