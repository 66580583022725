// https://bezkoder.com/react-hooks-file-upload/
import React from "react";

export function FileUpload (props) {

  const selectFile = (event) => {
    // info su file caricati -> vettore uploadInfo
    if(props.setUploadInfo)
        props.setUploadInfo(event.target.files)
    // struttura interna utilizzabile per reset
    // per resettare file: event.target.value = null
    // quindi -> uploadTarget.value = null
    if(props.setUploadTarget)
        props.setUploadTarget(event.target)
  }

  return (
    <div>
      <label className="btn btn-default">
        <input type="file" onChange={selectFile} />
      </label>
    </div>
  )
}

