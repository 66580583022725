// https://rangle.io/blog/simplifying-controlled-inputs-with-hooks/
import React, { useState, useEffect } from "react";
import log from 'loglevel';
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { MostAutocomplete, MostSubmitButton } from "./components/MostComponents";

/**
 * riga di pull down per inserire una mutazione
 */
export function NewMutation(props) {
  // mai visto ma...
  //   Line 86:6:  React Hook useEffect has a missing dependency: 'props'. Either include it or remove the dependency array. However, 'props' will change when *any* prop changes, so the preferred fix is to destructure the 'props' object outside of the useEffect call and refer to those specific props inside useEffect  react-hooks/exhaustive-deps
  const gene = props.gene
  const mutationNames = props.mutationNames
  const setAllele1 = props.setAllele1
  const setAllele2 = props.setAllele2
  const { t } = useTranslation();

  const nomenclaturaOptions = [
        { value: "nome_comune", label: t("nomi comuni") },
        { value: "nome_hgvs", label: t("nomi HGVS") },
      ];
  const nomenclaturaDefault = nomenclaturaOptions[1];
  const [nomenclatura, setNomenclatura] = useState(nomenclaturaDefault);

  function updateNomenclatura(ev,newNomenclatura) {
    if(! newNomenclatura)
        return
    //log.debug("updateNomenclatura ", newNomenclatura);
    setNomenclatura(newNomenclatura);
  }

  function onChangeAllele1(ev,el) {
    log.debug("onChangeAllele1",el)
    props.setAllele1(el)
  }

  function onChangeAllele2(ev,el) {
    props.setAllele2(el)
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    props.submitNewMutation();
  };

  const handleGeneChange = (ev,newVal) => {
    if(! newVal)
        return
    props.setGene(newVal);
    // cambio gene forza ricostruzione liste alleli e reset valori alleli (effetto)
    //props.setAllele1("");
    //props.setAllele2("");
  };

  const [geneOptions, setGeneOptions] = useState([]);
  useEffect(() => {
    // preparazione elementi Select gene
    //log.debug("NewMutation useEffect: costruzione geneOptions");
    if(props.geneList) {
        let v = props.geneList.map((g) => {
            return { value: g, label: g };
        });
        setGeneOptions(v);
    }
  }, [props.geneList]);

  const [alleleOptions, setAlleleOptions] = useState([]);
  useEffect(() => {
    //log.debug("NewMutation useEffect: costruzione alleleOptions");
      let allele4Select = function () {
        var allelis = mutationNames[gene.value];
        if (allelis === undefined) {
          return [];
        }
        //log.debug("allele4Select");
        var ret = [];
        var v = "";
        var l = "";
        ret = allelis.filter((a_row) => a_row[nomenclatura.value] !== "N/A").map((a_row) => {
          //log.debug("a_row: ",a_row);
          v = a_row["nome_comune"];
          l = a_row[nomenclatura.value];
          if(l==="Normale" || l==="Non determinato")
            l=t(l)
          return { value: v, label: l };
        });
        // log.debug("RET:",ret);
        return ret;
      };
      let v = allele4Select();
      setAlleleOptions(v);
      // nuove opzioni, resettiamo valore
      setAllele1("");
      setAllele2("");
  }, [nomenclatura.value,gene,mutationNames,setAllele1,setAllele2,t]);

  let alleleIsDisabled = true;
  if(alleleOptions.length)
    alleleIsDisabled = false;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={4}>
          <MostAutocomplete
              label={t("In inserimento utilizza")}
              value={nomenclatura}
              onChange={updateNomenclatura}
              options={nomenclaturaOptions}
            />
        </Grid>
        <Grid item xs={8}>
        </Grid>
        <Grid item xs={4}>
            <MostAutocomplete
                label={t("Nuovo gene")}
                onChange={handleGeneChange}
                options={geneOptions}
                value={gene}
              />
        </Grid>
        <Grid item xs={4}>
            <MostAutocomplete
                label={t("Allele")+"1"}
                onChange={onChangeAllele1}
                options={alleleOptions}
                disabled={alleleIsDisabled}
                value={props.allele1}
              />
        </Grid>
        <Grid item xs={4}>
            <MostAutocomplete
                label={t("Allele")+"2"}
                onChange={onChangeAllele2}
                options={alleleOptions}
                disabled={alleleIsDisabled}
                value={props.allele2}
              />
        </Grid>
        <Grid item xs={4}>
            <MostSubmitButton label={t("Aggiungi gene")} color="secondary" className="font12" />
        </Grid>
    </Grid>
    </form>
  );
}
