import React, { useState, } from "react";
import log from 'loglevel';
import moment from "moment";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { MostDatePicker, MostAutocomplete, MostButton, MostSubmitButton, MostTextField, } from "./components/MostComponents";
import { dmy_hms, round, } from "./Utils"

export const options_sesso = [
    {label:"F",value:"F"},
    {label:"M",value:"M"},
]

export const readOnlyProps = {
    style: {
        backgroundColor: '#f0f0f0',
    }
}

export const resultProps = {
    style: {
        color: '#077',
        backgroundColor: '#f0f0f0',
        fontWeight: 'bold',
    }
}

export const submitClassname = (mode) => {
    return "font12 "+(mode!=="insert"?"hidden":"block")
}
export const resetClassname = (mode) => {
    return "font12 "+(mode==="insert"?"hidden":"block")
}

export const resultLabelProps = {
    style: {
        color: '#077',
        fontWeight: 'bold',
    }
}


const MEEXCalcolo = (props) => {
    const { t } = useTranslation();
    const { control, register, handleSubmit, errors, } = useForm();
    const [mode, setMode] = useState("insert")
    const [currentdate, setCurrentdate] = useState(new Date())
    const [hbs_post_perc, setHbs_post_perc] = useState(" ")
    const [hb_post, setHb_post] = useState(" ")
    const [media_gg, setMedia_gg] = useState(" ")
    const [min_gg, setMin_gg] = useState(" ")
    const [max_gg, setMax_gg] = useState(" ")
    const [warning, setWarning] = useState(" ")
    const [next_visit, setNext_visit] = useState(" ")
    const handleDateChange = (date) => {
            setCurrentdate(date)
    }
    const nuovoCalcolo = () => {
        setHbs_post_perc(" ")
        setHb_post(" ")
        setMedia_gg(" ")
        setMin_gg(" ")
        setMax_gg(" ")
        setNext_visit(" ")
        setWarning(" ")
        setMode("insert")
    }
    const onFormSubmit = (vals) => {
        if(!currentdate || isNaN(currentdate)) {
            alert(t("Data non valida"))
            return
        }
        log.debug(vals)
        const F5 = vals.f5_sesso.value
        const F6 = parseFloat(vals.f6_eta)
        const F7 = parseFloat(vals.f7_peso)
        const F8 = parseFloat(vals.f8_altezza)
        const F9 = parseFloat(vals.f9_hb_pre)
        const F10 = parseFloat(vals.f10_hbs_perc)
        const F12 = parseFloat(vals.f12_ht_ce)
        const F14 = parseFloat(vals.f14_hbs_mean)
        const F15 = parseFloat(vals.f15_hbs_low)
        const F16 = parseFloat(vals.f16_hbs_max)
        const F17 = parseFloat(vals.f17_hbs_regime)
        const F19 = parseFloat(vals.f19_phlebo)
        const F20 = parseFloat(vals.f20_hydra)
        const F21 = parseFloat(vals.f21_ce_transf)
        // =F7/F8^2
        const B1 =F7/Math.pow(F8,2)
        //const B2 =F12/3
        // =SE(B1>25;0,7/(B1/22)^0,5;SE(F6>12;SE(F5="M";0,7;0,65);0,75))
        const B3 =(B1>25?0.7/Math.pow((B1/22),0.5):(F6>12?(F5==="M"?0.7:0.65):0.75))
        const B4 =B3*F7

        const F25 =(F9*F10*(B4-F19/100)/(F9*(B4-F19/100)+F21/100*F12/3))-0.48
        setHbs_post_perc(round(F25,1))
        const F26 =(F9*(B4-F19/100)+F21/100*F12/3)/(B4-F19/100+F21/100+F20/100)+1
        setHb_post(round(F26,1))
        // =SE(ARROTONDA((F17-F25)/F14;0)<0;"Valori di HbS-post ancora sopra soglia, programmare il prossimo scambio nell'immediato per abbassare i valori di HbS%";ARROTONDA((F17-F25)/F14;0))
        let F28
        if(round((F17-F25)/F14,0)<0) {
            setWarning(t("warning HbS-post"))
            F28 = " "
        } else
            F28 = round((F17-F25)/F14,0)
        setMedia_gg(F28)
        // =SE(ARROTONDA((F17-F25)/F15;0)<0;"Valori di HbS-post ancora sopra soglia, programmare il prossimo scambio nell'immediato per abbassare i valori di HbS%";ARROTONDA((F17-F25)/F15;0))
        let F29
        if (round((F17-F25)/F15,0)<0) {
            setWarning(t("warning HbS-post"))
            F29 = " "
        } else
            F29 = round((F17-F25)/F15,0)
        setMin_gg(F29)
        // =SE(ARROTONDA((F17-F25)/F16;0)<0;"Valori di HbS-post ancora sopra soglia, programmare il prossimo scambio nell'immediato per abbassare i valori di HbS%";ARROTONDA((F17-F25)/F16;0))
        let F30
        if (round((F17-F25)/F16,0)<0) {
            setWarning(t("warning HbS-post"))
            F30 = " "
        } else
            F30 = round((F17-F25)/F16,0)
        setMax_gg(F30)
        // =SE(ARROTONDA((F17-F25)/F14;0)<0;"Data non disponibile";F4+F28)
        let F31
        if (round((F17-F25)/F14,0)<0)
            F31 = t("Data non disponibile")
        else {
            F31 = dmy_hms(moment(currentdate).add(parseInt(F28),'days').toDate(),2)
        }
        setNext_visit(F31)
        setMode("view")
    }
    return (
        <Container component="main" maxWidth="md">
        <div className="boxForm margintop30">
          <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
          <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={12} md={6}>
                <div className="w200 alignCenter height100">
                    <MostDatePicker label={t("Data")} disabled={mode!=="insert"} openTo="date" disableFuture={false} value={currentdate} onChange={handleDateChange} margin="none" />
                </div>
                <MostAutocomplete control={control} name="f5_sesso" disabled={mode!=="insert"} options={options_sesso} label={t("Sesso")+" *"} required={true} rules={{ required: true }}  errors={errors} />
                <MostTextField name="f6_eta" disabled={mode!=="insert"} defaultValue="" required={true} label={t("Età (anni)")} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                <MostTextField name="f7_peso" disabled={mode!=="insert"} defaultValue="" required={true} label={t("Peso")+" (kg)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                <MostTextField name="f8_altezza" disabled={mode!=="insert"} defaultValue="" required={true} label={t("Altezza")+" (m)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                <MostTextField name="f9_hb_pre" disabled={mode!=="insert"} defaultValue="" required={true} label={"Hb pre (g/dl)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                <MostTextField name="f10_hbs_perc" disabled={mode!=="insert"} defaultValue="" required={true} label={"HbS% pre (%)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel className="">{t("Incremento giornaliero di HbS%")}</FormLabel>
                <Box border={1} borderRadius={4} className="box" >
                    <div className="blackColor padding10">
                        <MostTextField name="f14_hbs_mean" disabled={mode!=="insert"} defaultValue="0.39" required={true} label={t("mean")} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                        <MostTextField name="f15_hbs_low" disabled={mode!=="insert"} defaultValue="0.31" required={true} label={t("low")} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                        <MostTextField name="f16_hbs_max" disabled={mode!=="insert"} defaultValue="0.47" required={true} label={t("max")} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                    </div>
                </Box>
                <MostTextField name="f17_hbs_regime" disabled={mode!=="insert"} defaultValue="" required={true} label={t("HbS% di regime")+" (%)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                <br />
                <FormLabel className="Tools_formLabel">{t("MEEX VOLUMES")}</FormLabel>
                <Box border={1} borderRadius={4} className="box" >
                    <div className="blackColor padding10">
                        <MostTextField name="f19_phlebo" disabled={mode!=="insert"} defaultValue="" required={true} label={t("Total Phlebotomy")+" (ml)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                        <MostTextField name="f20_hydra" disabled={mode!=="insert"} defaultValue="" required={true} label={t("Total Hydration")+" (ml)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                        <MostTextField name="f21_ce_transf" disabled={mode!=="insert"} defaultValue="" required={true} label={t("Total CE transfused")+" (ml)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                    </div>
                </Box>
                <MostTextField name="f12_ht_ce" disabled={mode!=="insert"} defaultValue="60" required={true} label={t("Ht of CE")+" (%)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
              </Grid>
              <Grid item md={3} />
              <Grid item xs={12} md={6}>
                <MostSubmitButton label={t("Calcola")} color="secondary" className={submitClassname(mode)+" margin0"} />
                <MostButton label={t("Nuovo calcolo")} color="secondary" className={resetClassname(mode)+" margin0"} onClick={nuovoCalcolo} />
              </Grid>
              <Grid item md={3} />
              <Grid item xs={12} md={6}>
                <MostTextField inputProps={resultProps} InputLabelProps={resultLabelProps} label={"HbS% post MEEX (±2.4) (%)"} value={hbs_post_perc} />
                <MostTextField inputProps={resultProps} InputLabelProps={resultLabelProps} label={"Hb post MEEX (±0.7) (g/dl)"} value={hb_post} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel className="">{t("Prossimo appuntamento (gg)")}</FormLabel>
                <Box border={1} borderRadius={4} className="box" >
                    <div className="blackColor padding10">
                        <MostTextField inputProps={resultProps} InputLabelProps={resultLabelProps} label={"media (gg)"} value={media_gg} />
                        <MostTextField inputProps={resultProps} InputLabelProps={resultLabelProps} label={"min (gg)"} value={min_gg} />
                        <MostTextField inputProps={resultProps} InputLabelProps={resultLabelProps} label={"max (gg)"} value={max_gg} />
                        <span style={{ color: 'red', fontWeight: 'bold',}}>{warning}</span>
                    </div>
                </Box>
                <MostTextField inputProps={resultProps} InputLabelProps={resultLabelProps} label={t("Data prossimo appuntamento")} value={next_visit} />
              </Grid>
          </Grid>
          </form>
        </div>
        </Container>
    )
}

const MEEXPrevisione = (props) => {
    const { t } = useTranslation();
    const { control, register, handleSubmit, errors, } = useForm();
    const [mode, setMode] = useState("insert")
    const [total_phlebotomy, setTotal_phlebotomy] = useState(" ")
    const [total_hydration, setTotal_hydration] = useState(" ")
    const [ce, setCe] = useState(" ")
    const [weight_ce, setWeight_ce] = useState(" ")
    const [hbs_post_perc, setHbs_post_perc] = useState(" ")
    const [hb_post, setHb_post] = useState(" ")
    const [total_phlebotomy2, setTotal_phlebotomy2] = useState(" ")
    const [total_hydration2, setTotal_hydration2] = useState(" ")
    const [ce2, setCe2] = useState(" ")
    const [weight_ce2, setWeight_ce2] = useState(" ")
    const [hbs_post_perc2, setHbs_post_perc2] = useState(" ")
    const [F11, setF11] = useState(" ")
    const [I10, setI10] = useState(" ")
    const nuovoCalcolo = () => {
        setMode("insert")
        setTotal_phlebotomy(" ")
        setTotal_hydration(" ")
        setCe(" ")
        setWeight_ce(" ")
        setHbs_post_perc(" ")
        setHb_post(" ")
        setTotal_phlebotomy2(" ")
        setTotal_hydration2(" ")
        setCe2(" ")
        setWeight_ce2(" ")
        setHbs_post_perc2(" ")
        setF11(" ")
        setI10(" ")
    }
    const onFormSubmit = (vals) => {
        log.debug(vals)
        const D4 = vals.d4_sesso.value
        const D5 = parseFloat(vals.d5_eta)
        const D6 = parseFloat(vals.d6_peso)
        const D7 = parseFloat(vals.d7_altezza)
        const D9 = parseFloat(vals.d9_hb_pre)
        const D10 = parseFloat(vals.d10_hbs_perc)
        const D11 = parseFloat(vals.d11_ht_ce)
        const D13 = parseFloat(vals.d13_target_hb)
        const D14 = parseFloat(vals.d14_hb_limit)

        // =SE(E(D7>1,3;D6>40);"Adult";"Pediatric")
        const C18  =((D7>1.3 && D6>40)?"Adult":"Pediatric")
        // =D6/D7^2
        const C19 =D6/Math.pow(D7,2)
        const C20 =D11/3
        // =SE(C19>25;0,7/(C19/22)^0,5;SE(D5>12;SE(D4="M";0,7;0,65);0,75))
        const C21 =(C19>25?0.7/Math.pow((C19/22),0.5):(D5>12?(D4==="M"?0.7:0.65):0.75))
        const C22 =C21*D6
        //const C23 = 280
        // =SE(D6<10;5*D6;SE(D6<20;(D6/6+4)*D6;10,5*D6))
        const E19 =(D6<10?5*D6:(D6<20?(D6/6+4)*D6:10.5*D6))
        // =SE(SE(D9<8,5;6*D6;SE(D9<10;(0,07*D6+0,16*D10)*D6;(0,12*D6+0,18*D10)*D6))>1400;1400;SE(D9<8,5;6*D6;SE(D9<10;(0,07*D6+0,16*D10)*D6;(0,12*D6+0,18*D10)*D6)))
        let E20 =((D9<8.5?6*D6:(D9<10?(0.07*D6+0.16*D10)*D6:(0.12*D6+0.18*D10)*D6))>1400?1400:(D9<8.5?6*D6:(D9<10?(0.07*D6+0.16*D10)*D6:(0.12*D6+0.18*D10)*D6)))

        const E21 =125+7*D6-60*(D9-11)
        // =100*ARROTONDA((E19*2)/100;0)/2
        const G19 =100*round((E19*2)/100,0)/2
        // =SE(E20<500;100*ARROTONDA((E20)*2/100;0)/2;100*ARROTONDA((E20)*1/100;0)/1)
        const G20 =(E20<500?100*round((E20)*2/100,0)/2:100*round((E20)*1/100,0)/1)
        //=100*ARROTONDA(E21/100;1)
        const G21 =100*round(E21/100,1)

        const H19 =G19/100
        const H20 =G20/100
        const H21 =G21/100
        // =ARROTONDA((D9*(C22-H20)+H21*C20)/(C22-H20+H21+H19)+1;1)
        const J20 =round((D9*(C22-H20)+H21*C20)/(C22-H20+H21+H19)+1,1)

        // =ARROTONDA((G21+(D13-J20)*D6*SE(C18="Adult";3;5))/100;1)*100
        const G22 =round((G21+(D13-J20)*D6*(C18==="Adult"?3:5))/100,1)*100
        const H22 =G22/100

        //const I21 =G21/(C23/1.06)
        // =ARROTONDA(G22/(C23/1,06);0)
        //const I22 =round(G22/(C23/1.06),0)
        // =(D9*D10*(C22-H20))/(D9*(C22-H20)+H21*C20)-0,48
        const J22 =(D9*D10*(C22-H20))/(D9*(C22-H20)+H21*C20)-0.48
        //const K20 =D13
        const K22 =(D9*D10*(C22-H20))/(D9*(C22-H20)+H22*C20)-0.48
        const G5 =G20
        setTotal_phlebotomy(G5)
        const G6 =G19
        setTotal_hydration(G6)
        const G7 =G21
        setCe(G7)
        const G8 =G7*1.06
        setWeight_ce(round(G8,0))
        const G9 =J22
        setHbs_post_perc(round(G9,1))
        const G10 =J20
        // =SE(G10>=D14; "High value of Hb: viscosity risk";"")
        const F11 =(G10>=D14? t("High value of Hb")+": "+t("viscosity risk"):" ")
        setF11(F11)
        setHb_post(G10)
        if (G22<0) {
            setTotal_phlebotomy2(" ")
            setTotal_hydration2(" ")
            setCe2(" ")
            setWeight_ce2(" ")
            setHbs_post_perc2(" ")
            setI10(" ")
        } else {
            const J5 =G20
            setTotal_phlebotomy2(J5)
            const J6 =G19
            setTotal_hydration2(J6)
            const J7 =G22
            setCe2(round(J7,0))
            const J8 =J7*1.06
            setWeight_ce2(round(J8,0))
            const J9 =K22
            setHbs_post_perc2(round(J9,1))
            // =SE(D13>=D14; "Target value Hb: viscosity risk";"")
            const I10 =(D13>=D14? t("Target value Hb")+": "+t("viscosity risk"):" ")
            setI10(I10)
        }
        setMode("view")
    }
    return (
        <Container component="main" maxWidth="md">
        <div className="boxForm margintop30">
          <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
          <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={12} md={6}>
                <FormLabel className="Tools_formLabel">{t("Parameters of the patient")}</FormLabel>
                <Box border={1} borderRadius={4} className="box" >
                    <div className="blackColor padding10">

                        <MostAutocomplete control={control} name="d4_sesso" disabled={mode!=="insert"} options={options_sesso} label={t("Sesso")+" *"} required={true} rules={{ required: true }}  errors={errors} />
                        <MostTextField name="d5_eta" disabled={mode!=="insert"} defaultValue="" required={true} label={t("Età (anni)")} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                        <MostTextField name="d6_peso" disabled={mode!=="insert"} defaultValue="" required={true} label={t("Peso")+" (kg)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                        <MostTextField name="d7_altezza" disabled={mode!=="insert"} defaultValue="" required={true} label={t("Altezza")+" (m)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                    </div>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel className="Tools_formLabel">{t("Pre-exchange parameters")}</FormLabel>
                <Box border={1} borderRadius={4} className="box" >
                    <div className="blackColor padding10">
                        <MostTextField name="d9_hb_pre" disabled={mode!=="insert"} defaultValue="" required={true} label={"Hb pre (g/dl)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                        <MostTextField name="d10_hbs_perc" disabled={mode!=="insert"} defaultValue="" required={true} label={"HbS% pre (%)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                        <MostTextField name="d11_ht_ce" disabled={mode!=="insert"} defaultValue="60" required={true} label={t("Ht of CE")+" (%)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                    </div>
                </Box>
                <FormLabel className="Tools_formLabel"> &nbsp; </FormLabel>
                <Box border={1} borderRadius={4} className="box" >
                    <div className="blackColor padding10">
                        <MostTextField name="d13_target_hb" disabled={mode!=="insert"} defaultValue="11.0" required={true} label={t("Target value Hb")+" (g/dl)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                        <MostTextField name="d14_hb_limit" disabled={mode!=="insert"} defaultValue="12.0" required={true} label={t("Hb-limit (viscosity)")+" (g/dl)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                    </div>
                </Box>
              </Grid>
              <Grid item md={3} />
              <Grid item xs={12} md={6}>
                <MostSubmitButton label={t("Calcola")} color="secondary" className={submitClassname(mode)+" margin0"} />
                <MostButton label={t("Nuovo calcolo")} color="secondary" className={resetClassname(mode)+" margin0"} onClick={nuovoCalcolo} />
              </Grid>
              <Grid item md={3} />
              <Grid item xs={12} md={6}>
                <FormLabel className="Tools_formLabel">{t("MEEX Model n1")} </FormLabel>
                <Box border={1} borderRadius={4} className="box" >
                    <div className="blackColor padding10">
                        <MostTextField inputProps={readOnlyProps} label={t("Total Phlebotomy")+" (ml)"} value={total_phlebotomy} />
                        <MostTextField inputProps={readOnlyProps} label={t("Total Hydration")+" (ml)"} value={total_hydration} />
                        <MostTextField inputProps={readOnlyProps} label={"CE (ml)"} value={ce} />
                        <MostTextField inputProps={readOnlyProps} label={t("Equivalent weight of CE")+" (g)"} value={weight_ce} />
                        <MostTextField inputProps={resultProps} InputLabelProps={resultLabelProps} label={"HbS% post (±2.4) (%)"} value={hbs_post_perc} />
                        <MostTextField inputProps={resultProps} InputLabelProps={resultLabelProps} label={"Hb post (±0.7) (g/dl)"} value={hb_post} />
                        <span style={{ color: 'red', fontWeight: 'bold',}}>{F11}</span>
                    </div>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel className="Tools_formLabel">{t("MEEX Model n2 corrected for Hb")} </FormLabel>
                <Box border={1} borderRadius={4} className="box" >
                    <div className="blackColor padding10">
                        <MostTextField inputProps={readOnlyProps} label={t("Total Phlebotomy")+" (ml)"} value={total_phlebotomy2} />
                        <MostTextField inputProps={readOnlyProps} label={t("Total Hydration")+" (ml)"} value={total_hydration2} />
                        <MostTextField inputProps={readOnlyProps} label={"CE (ml)"} value={ce2} />
                        <MostTextField inputProps={readOnlyProps} label={t("Equivalent weight of CE")+" (g)"} value={weight_ce2} />
                        <MostTextField inputProps={resultProps} InputLabelProps={resultLabelProps} label={"HbS% post (±2.4) (%)"} value={hbs_post_perc2} />
                        <span style={{ color: 'red', fontWeight: 'bold',}}>{I10}</span>
                    </div>
                </Box>
              </Grid>
          </Grid>
          </form>
        </div>
        </Container>
    )
}

export const MMEEXCalcolo = React.memo(MEEXCalcolo)
export const MMEEXPrevisione = React.memo(MEEXPrevisione)
