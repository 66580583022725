import React, { useState, useCallback } from "react";
import log from 'loglevel';
import { useGlobalHook } from '@devhammed/use-global-hook'
import Container from "@material-ui/core/Container";
import PropagateLoader from "react-spinners/PropagateLoader";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import { css } from "@emotion/core";
import { dmy_date_formatter, DataTable, } from './components/DataTable';
import { useTranslation } from 'react-i18next';
import { now, dataGridMakeIdUnique, } from "./Utils";
import { options_formatofile_download } from "./UtilsInthem";
import { MostSubmitButton, MostAutocomplete, } from "./components/MostComponents";
import MyAxios, { check_and_download, check_response } from "./MyAxios";

export const Dbinthem = (props) => {
    const { t } = useTranslation();
    const [options_formatofile_download_t] = useState(options_formatofile_download(t))
    const [showdatagrid, setShowdatagrid] = useState(false)
    const [rows, setRows] = useState([])
    const [mode] = useState("consensi")
    const [loading, setLoading] = useState(false)
    const [formato, setFormato] = useState(null)
    const onChangeFormato = (e,el) => {
        setFormato(el)
    }
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    const spinnerCss = css`
        display: block;
        margin: 0 auto;
    `;

    const columnsConsensi = [
        { field: 'IDENTIFIER', headerName: t('Identificatore'), width: 200, },
        { field: 'SURNAME', headerName: t('Cognome'), width: 200, },
        { field: 'NAME', headerName: t('Nome'), width: 200, },
        { field: 'BIRTH_DATE', headerName: t('Nascita'), width: 150, valueFormatter: dmy_date_formatter },
        { field: 'C_DB', headerName: t('Consenso'), width: 150, },
        { field: 'C_DB_P', headerName: t('dati_personali'), width: 150, },
        { field: 'C_DB_M', headerName: t('dati_medici'), width: 150, },
    ]

    function download () {
        setShowdatagrid(false)
        if(!formato) {
            appAlert(t("Scegli il formato"))
            return
        }
        let format = formato.value
        if (formato.value === "video")
            format = 'JSON'
        const formData = new FormData()
        formData.append('format', format)
        const url = "/inthem_all_patients"
        log.debug(url, [...formData.entries()])
        MyAxios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data;" },
            responseType: format !== 'JSON' ? 'arraybuffer' : '',
        }).then((response) => {
            const fileName = 'dbinthem-pazienti.'+now(3)+'.'+format.toLowerCase()
            const res = check_and_download(response,format,formato.value,fileName,1)
            if(res.success === false) {
                log.error("Error: "+res.error)
                appAlert(res.error)
                return
            }
            if (formato.value === "video") {
                setRows([])
                setShowdatagrid(true)
                setRows(dataGridMakeIdUnique(res.rows,1))
            }
        })
        .catch(function (error) {
            // handle error
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }

    function syncDbinthem () {
        setShowdatagrid(false)
        setLoading(true)
        const formData = new FormData()
        formData.append('action', 'SYNC')
        const url = "/inthem_all_patients"
        log.debug(url, [...formData.entries()])
        MyAxios.post(url, formData, {}).then((response) => {
            const res = check_response(response)
            setLoading(false)
            if(res.success === false) {
                log.error("Error: "+res.error)
                appAlert(res.error)
                return
            }
            appAlert(JSON.stringify(res))
        })
        .catch(function (error) {
            // handle error
            setLoading(false)
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }

    function openDbinthem () {
        const url = props.config.inthem_url+"/login"
        window.open(url,"db-inthem")
    }

    if(loading) 
        return  (
            <div className="margintop30 tcenter">
                <p><br /><b>{t("Attendere, caricamento in corso")}</b></p><PropagateLoader color={window.__most.palette.primary.darkColor} css={spinnerCss} loading={true} />
            </div>
        )


    return (
        <div className="wt3 Dbinthem displayFlex flexflowColumn height100">
          <Toolbar className="rowreverse">
            <Button variant="outlined" onClick={syncDbinthem} className="grayButton" > {t("Sincronizza DB-INTHEM")} </Button>
            <Button variant="outlined" onClick={openDbinthem} className="grayButton marginr10" > {t("Accedi a DB-INTHEM")} </Button>
          </Toolbar>
        { mode === "consensi" ?
            <React.Fragment>
            <Container component="main" maxWidth="sm">
              <h2>{t("Recupero stato consensi pazienti")}</h2>
              <div className="boxForm">
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <MostAutocomplete options={options_formatofile_download_t} label={t("Formato file")+" *"} value={formato} onChange={onChangeFormato} />
                    </Grid>
                    <Grid item xs={12}>
                        <MostSubmitButton label={t("Recupera")} color="secondary" onClick={download} />
                    </Grid>
                </Grid>
              </div>
            </Container>
            { showdatagrid ?
                <div className="whiteback margintop30 flex1">
                    <DataTable columns={columnsConsensi} rows={rows} density={"compact"} />
                </div>
            : null }
            </React.Fragment>
        : null }
        </div>
    )
}
