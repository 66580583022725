import React, { useState, }  from 'react';
import log from 'loglevel';
import { useTranslation } from "react-i18next";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { app_server_url } from "./MyAxios";

export const MostdocDialog = (props) => {
  const [size, setSize] = useState(null)
  const { t } = useTranslation();

  function setIframeHeight() {
        try {
          const iframeParent = document.getElementById("iframeParent") 
          if(!iframeParent)
            return
          // dipende da dimensione dialog: con sm 552 x 415
          let ih = iframeParent.offsetHeight
          let iw = iframeParent.offsetWidth
          // per firefox
          ih -= 2
          iw -= 2
          // padding
          ih -= 8*2
          iw -= 24*2
          // boh
          ih -= 4
          setSize([iw,ih])
          let iframe = document.getElementById("mdocIframe");
          if (iframe) {
              iframe.style.height = '' + ih + 'px';
              iframe.style.width = '' + iw + 'px';
          }
        } catch (e) {
          log.error('setIframeHeight', e);
        }
  }

  function msgInIframe() {
    let ifrm = document.getElementById('mdocIframe');
    ifrm = ifrm.contentWindow || ifrm.contentDocument.document || ifrm.contentDocument;
    ifrm.document.open();
    ifrm.document.write(t('Attendere')+'...');
    ifrm.document.close()
  }

  const handleClose = () => {
    props.setMostdocOpen(false);
  };

  let iw = 800
  let ih = 600
  if(size) {
    iw = size[0]
    ih = size[1]
  }

  const action = app_server_url + "/mostdoc/WWW/MOSTDOC/html"

  if (props.mostdocOpen && props.mostdocSubmit) {
    setTimeout(()=>{
        if(!size)
            setIframeHeight()
        msgInIframe()
        setTimeout(()=>{
            window.document.getElementById('mdocForm').submit();
        }, 200)
    }, 200)
  }

  return (
  <React.Fragment>
   <Dialog 
        id="mostdoc"
        open={props.mostdocOpen}
        scroll="paper"
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="mostdoc-draggable-dialog-title"
      >
        <DialogTitle id="mostdoc-draggable-dialog-title">
            {t("Stampa")}
        </DialogTitle>
        <DialogContent id="iframeParent">
            <form action={action} id="mdocForm" target="mdocIframe" method="POST">
                <input type="hidden" name="form_action" value="stampaEmo7.html" />
                <input type="hidden" name="G_app_name" value={props.wtapp.toUpperCase()} />
                <input type="hidden" name="G_exportInfo4Decrypt" value={JSON.stringify(props.info4decrypt)} />
            </form>
            <iframe style={{ border: 0 }} name="mdocIframe" id="mdocIframe" title="mostdoc iframe" width={iw} height={ih} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            {t("translation:Chiudi")}
          </Button>
        </DialogActions>
   </Dialog>
  </React.Fragment>
  );
}
