import React, { useState, useCallback, useEffect, } from "react";
import log from 'loglevel';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import MyAxios, {check_response} from "./MyAxios";
import { ymd } from "./Utils";
import { getOptionBooleanListElement, options_boolean_si_no, getOptionList, getOptionListElement, Loading, MostDatePicker, MostAutocomplete, MostSubmitButton, MostTextField, } from "./components/MostComponents";

export const Gene = (props) => {
    const { t, i18n } = useTranslation()
    const { control, handleSubmit, register } = useForm();
    // altrimenti useEffect va in loop
    const [options_bool] = useState(options_boolean_si_no(t))
    const [optionGt_outcome, setOptionGt_outcome] = useState([])
    const [optionGt_conditioning, setOptionGt_conditioning] = useState([])
    const [optionGt_type, setOptionGt_type] = useState([])
    const [gt, setGt] = useState(null)
    const [gt_start, setGt_start] = useState(null)
    const gt_startChange = (date) => {
            setGt_start(date)
    }
    const [data, setData] = useState({})
    const [got2, setGot2] = useState(false)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const [forceReload, setForceReload] = useState(0);
    const appAlert = useCallback((text) => {
        setContent(text);
        setAlert1(true);
    }, [setContent,setAlert1])
    const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
    function appAlert2(text,closeHandler) {
        setContent2(text)
        setOnClose2(() => x => {
        closeHandler()
        });
        setAlert2(true)
    }

    useEffect(() => {
        const jdata = {
            action: "LIST",
            patient_id: props.paziente.value,
        }
        MyAxios.post("/gene_therapy", jdata)
        .then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            appAlert(res.error)
            return
          }
          const trans = "trans_" + i18n.language
          setOptionGt_conditioning(getOptionList(res.gt_conditioning,'id',trans))
          setOptionGt_outcome(getOptionList(res.gt_outcome,'id',trans))
          setOptionGt_type(getOptionList(res.gt_type,'id',trans))
          setData(res.gt_row)
          setGt_start(res.gt_row.gt_start ? new Date(res.gt_row.gt_start) : null)
          setGt(getOptionBooleanListElement(options_bool,res.gt_row.gt))
          setGot2(true)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [forceReload,appAlert,i18n.language,props.paziente.value,options_bool,])

    const onSubmitUpload = (vals) => {
        log.debug(vals)
        if(isNaN(gt_start)) {
            appAlert(t("Data non valida"))
            return
        }
        if (gt === null) {
            appAlert(t("Sottoposto a terapia genica")+": "+t("campo obbligatorio"))
            return
        }
        const jdata = {}
        jdata['action'] = data.id ? "MODIFY" : "ADD"
        jdata['patient_id'] = props.paziente.value
        if (data.id)
            jdata['id'] = data.id
        jdata['gt'] = gt.value === "true" ? true : false
        if (jdata['gt']) {
            jdata['gt_start'] = gt_start ? ymd(gt_start) : null
            jdata['gt_protocol'] = vals.gt_protocol
            jdata['gt_type'] = vals.gt_type ? vals.gt_type.value : null
            jdata['gt_conditioning'] = vals.gt_conditioning ? vals.gt_conditioning.value : null
            jdata['gt_outcome'] = vals.gt_outcome ? vals.gt_outcome.value : null
            jdata['gt_note'] = vals.gt_note
        } else {
            jdata['gt_start'] = null
            jdata['gt_protocol'] = null
            jdata['gt_type'] = null
            jdata['gt_conditioning'] = null
            jdata['gt_outcome'] = null
            jdata['gt_note'] = null
        }
        MyAxios.post("/gene_therapy", jdata)
        .then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            appAlert(res.error)
            return
          }
          appAlert2(t("Modifiche registrate correttamente"),() => {
            setForceReload(prevCount => prevCount + 1)
          })
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }

    const handleGtChange = (e,el) => {
        setGt(el)
    }

    if(!got2)
        return <Loading />

    let nonsottoposto = true
    if (gt && gt.value === "true") 
        nonsottoposto = false

    log.debug("Gene")
    return (
        <div className="wt3">
        <Container component="main">
            <div className="margintop30">
                <div className="boxForm margintop30 insertBox">
                <form onSubmit={handleSubmit(onSubmitUpload)} noValidate>
                    <MostAutocomplete name="gt" options={options_bool} label={t("Sottoposto a terapia genica")+" *"} value={gt} onChange={handleGtChange} />
                    <MostDatePicker disabled={nonsottoposto} openTo="date" disableFuture={true} value={gt_start} onChange={gt_startChange} label={t("Data della terapia genica")} />
                    <MostTextField name="gt_protocol" disabled={nonsottoposto} register={register} multiline={true} rows={5} label={t("Tipo di protocollo")} defaultValue={data.gt_protocol} inputProps={{ maxLength: 255 }} />
                    <MostAutocomplete name="gt_type" disabled={nonsottoposto} control={control} options={optionGt_type} label={t("Tipo")} defaultValue={getOptionListElement(optionGt_type,data.gt_type)} />
                    <MostAutocomplete name="gt_conditioning" disabled={nonsottoposto} control={control} options={optionGt_conditioning} label={t("Tipo di condizionamento")} defaultValue={getOptionListElement(optionGt_conditioning,data.gt_conditioning)} />
                    <MostAutocomplete name="gt_outcome" disabled={nonsottoposto} control={control} options={optionGt_outcome} label={t("Esito")} defaultValue={getOptionListElement(optionGt_outcome,data.gt_outcome)} />
                    <MostTextField name="gt_note" disabled={nonsottoposto} label={t("Note")} register={register} multiline={true} rows={5} defaultValue={data.gt_note} inputProps={{ maxLength: 1998 }}
 />
                    <MostSubmitButton label={t("Modifica")} color="secondary" className="font12" />
                </form>
                </div>
            </div>
        </Container>
        </div>
    )
}

