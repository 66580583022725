import React, { useState, useEffect, } from "react";
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import MyAxios, {check_response} from "./MyAxios";
import { toFloat, ymd } from "./Utils";
import { getOptionListElement, readOnlyProps, MostTextField, S3Field, MostDatePicker, MostAutocomplete, } from "./components/MostComponents";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";

export const InstrumentalExamsInsertDialog = (props) => {
    const { t, } = useTranslation("documents", "translation");
    const [tipo_esame, setTipo_esame] = useState(null)
    const [data_ingresso, setData_ingresso] = useState(new Date())
    const [data_esame, setData_esame] = useState(null)
    const [errorText, setErrorText] = useState(null)
    const [uploadInfo, setUploadInfo] = useState(null);
    const [uploadTarget, setUploadTarget] = useState(null);
    const [docRemoved, setDocRemoved] = useState(false)
    const [disabledButs, setDisabledButs] = useState(false)
    const { register, control, handleSubmit, } = useForm();

    const [options_STD_RMC_DEE] = useState([
        {value:'ABSENT', label:t('ABSENT')},
        {value:'SUBENDOCARDIAL', label:t('SUBENDOCARDIAL')},
        {value:'TRANSMURAL', label:t('TRANSMURAL')},
    ])

    const onChangeTipo_esame = (e,el) => {
        setTipo_esame(el)
    }

    function getExam(v,k) {
        for (let i in v) {
            if(v[i].exam === k)
                return v[i].text_value
        }
        return null
    }

    // nota: con uso di forceReloadDialog viene richiamata ad ogni apertura
    useEffect(() => {
        if (props.row) {
            // modifica
            setTipo_esame( getOptionListElement(props.options_tipo_esame,props.row.tipo_esame) )
            if(props.row.data_ingresso)
                setData_ingresso(new Date(props.row.data_ingresso))
            else
                setData_ingresso(null)
            if(props.row.data_esame)
                setData_esame(new Date(props.row.data_esame))
            else
                setData_esame(null)
            setDocRemoved(false)

            if(props.row.tipo_esame==="88.92") {
                props.row.esami_rmc = {
                    'STD-T2STAR_CUORE': getExam(props.row.exam_results,'STD-T2STAR_CUORE'),
                    'STD-RMC_DEE': getExam(props.row.exam_results,'STD-RMC_DEE'),
                    'STD-RMC_LVEDV': getExam(props.row.exam_results,'STD-RMC_LVEDV'),
                    'STD-RMC_RVEDV': getExam(props.row.exam_results,'STD-RMC_RVEDV'),
                    'STD-RMC_LVESV': getExam(props.row.exam_results,'STD-RMC_LVESV'),
                    'STD-RMC_RVESV': getExam(props.row.exam_results,'STD-RMC_RVESV'),
                    'STD-RMC_LVEF': getExam(props.row.exam_results,'STD-RMC_LVEF'),
                    'STD-RMC_RVEF': getExam(props.row.exam_results,'STD-RMC_RVEF'),
                    'STD-RMC_LVM': getExam(props.row.exam_results,'STD-RMC_LVM'),
                    'STD-RMC_RVM': getExam(props.row.exam_results,'STD-RMC_RVM'),
                }
            }
            if(props.row.tipo_esame==="88.95.RM-ADDOME") {
                props.row.esami_rma = {
                    'STD-T2STAR_FEGATO': getExam(props.row.exam_results,'STD-T2STAR_FEGATO'),
                    'STD-T2STAR_PANCREAS': getExam(props.row.exam_results,'STD-T2STAR_PANCREAS'),
                    'STD-T2STAR_MILZA': getExam(props.row.exam_results,'STD-T2STAR_MILZA'),
                    'STD-T2STAR_RENE': getExam(props.row.exam_results,'STD-T2STAR_RENE'),
                    'STD-T2STAR_VERTEBRALE': getExam(props.row.exam_results,'STD-T2STAR_VERTEBRALE'),
                    'STD-LIC': getExam(props.row.exam_results,'STD-LIC'),
                }
            }
            if(props.row.tipo_esame==="88.72") {
                props.row.esami_ec = {
                    'STD-EC_FS%': getExam(props.row.exam_results,'STD-EC_FS%'),
                    'STD-EC_EF%': getExam(props.row.exam_results,'STD-EC_EF%'),
                    'STD-EC_LVEDD': getExam(props.row.exam_results,'STD-EC_LVEDD'),
                    'STD-EC_PAP': getExam(props.row.exam_results,'STD-EC_PAP'),
                    'STD-EC_AD': getExam(props.row.exam_results,'STD-EC_AD'),
                    'STD-EC_RVD': getExam(props.row.exam_results,'STD-EC_RVD'),
                    'STD-EC_TRV': getExam(props.row.exam_results,'STD-EC_TRV'),
                }
            }
        } else {
            setTipo_esame(null)
            setData_ingresso(new Date())
            setData_esame(null)
        }
        setErrorText(null)
        setUploadTarget(null)
        setUploadInfo(null)
    }, [props.row,props.options_esenzioni,props.forceReloadDialog,props.options_tipo_esame])

    const data_ingressoChange = (date) => {
            setData_ingresso(date)
    }
    const data_esameChange = (date) => {
            setData_esame(date)
    }
    const closeDialogFunc = () => {
        props.setOpendialog(false)
    }
    const onSubmit = (vals) => {
        log.debug("onSubmit",vals)

        if(!tipo_esame) {
            setErrorText(t("tipo_esame")+": "+t("translation:campo obbligatorio"))
            return
        }
        if(isNaN(data_ingresso) || isNaN(data_esame)) {
            setErrorText(t("translation:Data non valida"))
            return
        }
        if(!data_ingresso) {
            setErrorText(t("data_ingresso")+": "+t("translation:campo obbligatorio"))
            return
        }
        if(!data_esame) {
            setErrorText(t("data_esame")+": "+t("translation:campo obbligatorio"))
            return
        }
        setDisabledButs(true)
        const jdata = {
            patient_id: props.paziente.value,
            data_ingresso: data_ingresso ? ymd(data_ingresso) : null,
            data_esame: data_esame ? ymd(data_esame) : null,
            data_contr: vals.data_contr,
            tipo_esame: tipo_esame.value,
            esito: vals.esito,
            note: vals.note,
        }
        if (props.row) {
            jdata.action = 'MODIFY'
            jdata.mostdoc__key = props.row.mostdoc__key
        } else {
            jdata.action = 'ADD'
        }

        if(tipo_esame.value === "88.92") { // RMN CUORE (RMN torace/miocardio)
            jdata.esami_rmc = {
                'STD-T2STAR_CUORE': toFloat(vals['STD-T2STAR_CUORE']),
                'STD-RMC_DEE': (vals['STD-RMC_DEE'] ? vals['STD-RMC_DEE'].value : null),
                'STD-RMC_LVEDV': toFloat(vals['STD-RMC_LVEDV']),
                'STD-RMC_RVEDV': toFloat(vals['STD-RMC_RVEDV']),
                'STD-RMC_LVESV': toFloat(vals['STD-RMC_LVESV']),
                'STD-RMC_RVESV': toFloat(vals['STD-RMC_RVESV']),
                'STD-RMC_LVM': toFloat(vals['STD-RMC_LVM']),
                'STD-RMC_RVM': toFloat(vals['STD-RMC_RVM']),
            }
            if(!vals['STD-RMC_LVEDV'] || !vals['STD-RMC_LVESV'] || jdata.esami_rmc['STD-RMC_LVEDV'] < 0.01 || jdata.esami_rmc['STD-RMC_LVESV'] < 0.01)
                jdata.esami_rmc['STD-RMC_LVEF'] = null
            else
                jdata.esami_rmc['STD-RMC_LVEF'] = Math.round((jdata.esami_rmc['STD-RMC_LVEDV'] - jdata.esami_rmc['STD-RMC_LVESV']) / jdata.esami_rmc['STD-RMC_LVEDV'] * 100)
            if(!vals['STD-RMC_RVEDV'] || !vals['STD-RMC_RVESV'] || jdata.esami_rmc['STD-RMC_RVEDV'] < 0.01 || jdata.esami_rmc['STD-RMC_RVESV'] < 0.01)
                jdata.esami_rmc['STD-RMC_RVEF'] = null
            else
                jdata.esami_rmc['STD-RMC_RVEF'] = Math.round((jdata.esami_rmc['STD-RMC_RVEDV'] - jdata.esami_rmc['STD-RMC_RVESV']) / jdata.esami_rmc['STD-RMC_RVEDV'] * 100)
        }

        if(tipo_esame.value === "88.95.RM-ADDOME") { // RM ADDOME
            jdata.esami_rma = {
                'STD-T2STAR_FEGATO': toFloat(vals['STD-T2STAR_FEGATO']),
                'STD-T2STAR_PANCREAS': toFloat(vals['STD-T2STAR_PANCREAS']),
                'STD-T2STAR_MILZA': toFloat(vals['STD-T2STAR_MILZA']),
                'STD-T2STAR_RENE': toFloat(vals['STD-T2STAR_RENE']),
                'STD-T2STAR_VERTEBRALE': toFloat(vals['STD-T2STAR_VERTEBRALE']),
            }
            if(jdata.esami_rma['STD-T2STAR_FEGATO'])
                jdata.esami_rma['STD-LIC'] = Math.round((25.4 / jdata.esami_rma['STD-T2STAR_FEGATO'] + 0.202)*10)/10
            else
                jdata.esami_rma['STD-LIC'] = null
        }

        if(tipo_esame.value === "88.72") { // ECO CARDIO
            jdata.esami_ec = {
                'STD-EC_FS%': toFloat(vals['STD-EC_FS%']),
                'STD-EC_EF%': toFloat(vals['STD-EC_EF%']),
                'STD-EC_LVEDD': toFloat(vals['STD-EC_LVEDD']),
                'STD-EC_PAP': toFloat(vals['STD-EC_PAP']),
                'STD-EC_AD': toFloat(vals['STD-EC_AD']),
                'STD-EC_RVD': toFloat(vals['STD-EC_RVD']),
                'STD-EC_TRV': toFloat(vals['STD-EC_TRV']),
            }
        }

        const formData = new FormData()
        if(props.row && props.row.s3_id && docRemoved && !uploadInfo)
            jdata.delete_file = true
        if(uploadInfo)
            formData.append('document_file', uploadInfo[0])
        formData.append('jpayload', JSON.stringify(jdata))
        const url = '/instrumental_exams'
        log.debug(url, [...formData.entries()])
        log.debug(url, "jpayload", jdata)
        MyAxios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            setErrorText(res.error)
            return
          }
          closeDialogFunc()
          // diamo il tempo di eseguire il then finale
          setTimeout(()=>{
            props.reload()
          },200)
        })
        .catch(function (error) {
            log.error(error);
            setErrorText(error.message?error.message:JSON.stringify(error));
        })
        .then(function () {
            if(uploadTarget)
                uploadTarget.value = null
            setUploadInfo(null)
            setDisabledButs(false)
        })
    }

    return (
        <Dialog open={props.opendialog} fullWidth maxWidth="md" className="wt3">
            <DialogTitle>{props.row ? t("translation:Modifica esame strumentale") : t("translation:Nuovo Esame_Consulenza")}</DialogTitle>
            <form id="form1" onSubmit={handleSubmit(onSubmit)} noValidate>
            <DialogActions className="wt3">
                <Button onClick={closeDialogFunc} disabled={disabledButs} variant="outlined" color="secondary" className="font12" >
                    {t("translation:Annulla")}
                </Button>
                <Button type="submit" disabled={disabledButs} variant="contained" color="secondary" className="font12" >
                    {props.row ? t("translation:Modifica") : t("translation:Aggiungi")}
                </Button>
            </DialogActions>
            <DialogContent>
                <DialogContentText className="red">
                    {errorText}
                </DialogContentText>
                <Grid container spacing={1} alignItems="flex-start">
                  <Grid item xs={12} md={6}>
                    <MostAutocomplete name="tipo_esame" options={props.options_tipo_esame} label={t("tipo_esame")+" *"} value={tipo_esame} onChange={onChangeTipo_esame} />
                  </Grid>
                  <Grid item xs={12} md={6} className="top-margin-8">
                    <S3Field label={t("translation:Documento allegato")} s3_id={props.row ? props.row.s3_id : null} showError={setErrorText} docRemoved={docRemoved} setDocRemoved={setDocRemoved} setUploadInfo={setUploadInfo} setUploadTarget={setUploadTarget} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MostDatePicker disabled label={t("data_ingresso")+" *"} onChange={data_ingressoChange} value={data_ingresso} disableFuture={true} openTo="date" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MostDatePicker name="data_esame" label={t("data_esame")+" *"} onChange={data_esameChange} value={data_esame} disableFuture={true} openTo="date" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MostTextField register={register} name="esito" label={t("esito")} multiline={true} rows={3} inputProps={{ maxLength: 1998 }} defaultValue={props.row ? props.row.esito : null} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MostTextField register={register} name="note" label={t("note")} multiline={true} rows={3} inputProps={{ maxLength: 1998 }} defaultValue={props.row ? props.row.note : null} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MostTextField register={register} name="data_contr" label={t("data_contr")} multiline={true} rows={2} inputProps={{ maxLength: 255 }} defaultValue={props.row ? props.row.data_contr : null} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  </Grid>
                  { tipo_esame && ['88.92', '88.95.RM-ADDOME', '88.72'].includes(tipo_esame.value) ?
                    <Grid item xs={12}>
                      <div style={{margin:'10px 0 0 0',border: '6px #C0D0F0 solid'}}>
                        <div style={{backgroundColor: '#C0D0F0',padding:'0 0 6px 0'}}>
                            <div style={{display:'inline-block', color:'#40428B',padding:'0 4px',fontSize:'12px',fontWeight:'bold', backgroundColor:'white'}}>
                                {t("translation:Esami")}
                            </div>
                        </div>
                        <div style={{padding:'10px',backgroundColor:'white'}}>
                            { tipo_esame.value === "88.92" ? // RMN CUORE (RMN torace/miocardio)
                                <Grid container spacing={1} alignItems="flex-start">
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-T2STAR_CUORE" inputProps={{type:'number'}} label={t("STD-T2STAR_CUORE")+" (ms)"} defaultValue={props.row && props.row.esami_rmc ? props.row.esami_rmc['STD-T2STAR_CUORE'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostAutocomplete control={control} name="STD-RMC_DEE" label={t("STD-RMC_DEE")} options={options_STD_RMC_DEE} defaultValue={props.row && props.row.esami_rmc ? getOptionListElement(options_STD_RMC_DEE, props.row.esami_rmc['STD-RMC_DEE']) : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-RMC_LVEDV" inputProps={{type:'number'}} label={t("STD-RMC_LVEDV")+" (ml)"} defaultValue={props.row && props.row.esami_rmc ? props.row.esami_rmc['STD-RMC_LVEDV'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-RMC_RVEDV" inputProps={{type:'number'}} label={t("STD-RMC_RVEDV")+" (ml)"} defaultValue={props.row && props.row.esami_rmc ? props.row.esami_rmc['STD-RMC_RVEDV'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-RMC_LVESV" inputProps={{type:'number'}} label={t("STD-RMC_LVESV")+" (ml)"} defaultValue={props.row && props.row.esami_rmc ? props.row.esami_rmc['STD-RMC_LVESV'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-RMC_RVESV" inputProps={{type:'number'}} label={t("STD-RMC_RVESV")+" (ml)"} defaultValue={props.row && props.row.esami_rmc ? props.row.esami_rmc['STD-RMC_RVESV'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField name="STD-RMC_LVEF" inputProps={readOnlyProps} disabled={props.row && props.row.esami_rmc && props.row.esami_rmc['STD-RMC_LVEF']?false:true} label={t("STD-RMC_LVEF")+" (%)"} defaultValue={props.row && props.row.esami_rmc ? props.row.esami_rmc['STD-RMC_LVEF'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField name="STD-RMC_RVEF" inputProps={readOnlyProps} disabled={props.row && props.row.esami_rmc && props.row.esami_rmc['STD-RMC_RVEF']?false:true} label={t("STD-RMC_RVEF")+" (%)"} defaultValue={props.row && props.row.esami_rmc ? props.row.esami_rmc['STD-RMC_RVEF'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-RMC_LVM" inputProps={{type:'number'}} label={t("STD-RMC_LVM")+" (g)"} defaultValue={props.row && props.row.esami_rmc ? props.row.esami_rmc['STD-RMC_LVM'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-RMC_RVM" inputProps={{type:'number'}} label={t("STD-RMC_RVM")+" (g)"} defaultValue={props.row && props.row.esami_rmc ? props.row.esami_rmc['STD-RMC_RVM'] : null} />
                                  </Grid>
                                </Grid>

                            : tipo_esame && tipo_esame.value === "88.95.RM-ADDOME" ?  // RM ADDOME , ex 88.95.RM-ADDOME-SUPERIORE , RM dell'addome superiore
                                <Grid container spacing={1} alignItems="flex-start">
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-T2STAR_FEGATO" inputProps={{type:'number'}} label={t("STD-T2STAR_FEGATO")+" (ms)"} defaultValue={props.row && props.row.esami_rma ? props.row.esami_rma['STD-T2STAR_FEGATO'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-T2STAR_PANCREAS" inputProps={{type:'number'}} label={t("STD-T2STAR_PANCREAS")+" (ms)"} defaultValue={props.row && props.row.esami_rma ? props.row.esami_rma['STD-T2STAR_PANCREAS'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-T2STAR_MILZA" inputProps={{type:'number'}} label={t("STD-T2STAR_MILZA")+" (ms)"} defaultValue={props.row && props.row.esami_rma ? props.row.esami_rma['STD-T2STAR_MILZA'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-T2STAR_RENE" inputProps={{type:'number'}} label={t("STD-T2STAR_RENE")+" (ms)"} defaultValue={props.row && props.row.esami_rma ? props.row.esami_rma['STD-T2STAR_RENE'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-T2STAR_VERTEBRALE" inputProps={{type:'number'}} label={t("STD-T2STAR_VERTEBRALE")+" (ms)"} defaultValue={props.row && props.row.esami_rma ? props.row.esami_rma['STD-T2STAR_VERTEBRALE'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField name="STD-LIC" inputProps={readOnlyProps} disabled={props.row && props.row.esami_rma && props.row.esami_rma['STD-LIC']?false:true} label={t("STD-LIC")+" (mg/g d.w.)"} defaultValue={props.row && props.row.esami_rma ? props.row.esami_rma['STD-LIC'] : null} />
                                  </Grid>
                                </Grid>

                            : tipo_esame && tipo_esame.value === "88.72" ?  // ECO CARDIO
                                <Grid container spacing={1} alignItems="flex-start">
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-EC_FS%" inputProps={{type:'number'}} label={t("STD-EC_FS%")} defaultValue={props.row && props.row.esami_ec ? props.row.esami_ec['STD-EC_FS%'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-EC_EF%" inputProps={{type:'number'}} label={t("STD-EC_EF%")} defaultValue={props.row && props.row.esami_ec ? props.row.esami_ec['STD-EC_EF%'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-EC_LVEDD" inputProps={{type:'number'}} label={t("STD-EC_LVEDD")+" (mm)"} defaultValue={props.row && props.row.esami_ec ? props.row.esami_ec['STD-EC_LVEDD'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-EC_RVD" inputProps={{type:'number'}} label={t("STD-EC_RVD")+" (mm)"} defaultValue={props.row && props.row.esami_ec ? props.row.esami_ec['STD-EC_RVD'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-EC_AD" inputProps={{type:'number'}} label={t("STD-EC_AD")+" (mm)"} defaultValue={props.row && props.row.esami_ec ? props.row.esami_ec['STD-EC_AD'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-EC_PAP" inputProps={{type:'number'}} label={t("STD-EC_PAP")} defaultValue={props.row && props.row.esami_ec ? props.row.esami_ec['STD-EC_PAP'] : null} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MostTextField register={register} name="STD-EC_TRV" inputProps={{type:'number'}} label={t("STD-EC_TRV")+" (m/s)"} defaultValue={props.row && props.row.esami_ec ? props.row.esami_ec['STD-EC_TRV'] : null} />
                                  </Grid>
                                </Grid>
                            : null }
                        </div>
                      </div>
                    </Grid>
                  : null }
                </Grid>
            </DialogContent>
            </form>
        </Dialog>
    )
}
