import React, { useState, } from "react";
import log from 'loglevel';
import Container from "@material-ui/core/Container";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { MMEEXCalcolo, MMEEXPrevisione, options_sesso, readOnlyProps, resultProps, submitClassname, resetClassname, resultLabelProps, } from "./MEEX.js";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MostAutocomplete, MostButton, MostSubmitButton, MostTextField, } from "./components/MostComponents";
import { round, } from "./Utils"

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

export const Tools = (props) => {
    const { t } = useTranslation();
    const classes = useStyles()

    const P50 = (props) => {
        const { register, handleSubmit, errors, } = useForm();
        const [mode, setMode] = useState("insert")
        const [log10, setLog10] = useState(" ")
        const [uk, setUk] = useState(" ")
        const [p50, setP50] = useState(" ")

        const nuovoCalcolo = () => {
            setLog10(" ")
            setUk(" ")
            setP50(" ")
            setMode("insert")
        }

        const onFormSubmit = (vals) => {
            log.debug(vals)
            const pO2 = parseFloat(vals.pO2)
            const pH = parseFloat(vals.pH)
            const sO2 = parseFloat(vals.sO2)
            const log10 = Math.log10(pO2)-0.5*(7.4-pH)
            setLog10(round(log10,6))
            const uk = Math.pow(10,2.7*log10)*(100-sO2)/sO2
            setUk(round(uk,3))
            const p50 = Math.pow(10,Math.log10(uk)/2.7)
            setP50(round(p50,2))
            setMode("view")
        }

        //log.debug("mode",mode)
        return (
            <Container component="main" maxWidth="sm">
            <div className="boxForm margintop30">
              <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
                <MostTextField name="pO2" disabled={mode!=="insert"} defaultValue="" required={true} label={"pO2 (venous)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                <MostTextField name="pH" disabled={mode!=="insert"} defaultValue="" required={true} label={"pH (venous)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                <MostTextField name="sO2" disabled={mode!=="insert"} defaultValue="" required={true} label={"sO2 (venous)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                <MostSubmitButton label={t("Calcola")} color="secondary" className={submitClassname(mode)} />
                <MostButton label={t("Nuovo calcolo")} color="secondary" className={resetClassname(mode)} onClick={nuovoCalcolo} />
                <MostTextField inputProps={readOnlyProps} label={"log[p02(7.4)]"} value={log10} />
                <MostTextField inputProps={readOnlyProps} label={"1/k"} value={uk} />
                <MostTextField inputProps={resultProps} InputLabelProps={resultLabelProps} label={"p50"} value={p50} />
              </form>
            </div>
            </Container>
        )
    }

    const QuantitaMassimaDaTrasfondere = (props) => {
        const { control, register, handleSubmit, errors, } = useForm();
        const [mode, setMode] = useState("insert")
        const [hb97, setHb97] = useState(" ")
        const [incrementoHb, setIncrementoHb] = useState(" ")
        const [hbPost, setHbPost] = useState(" ")
        const [sangue, setSangue] = useState(" ")
        const nuovoCalcolo = () => {
            setHb97(" ")
            setIncrementoHb(" ")
            setHbPost(" ")
            setSangue(" ")
            setMode("insert")
        }
        const onFormSubmit = (vals) => {
            log.debug(vals)
            const eta = parseFloat(vals.eta)
            const peso = parseFloat(vals.peso)
            const hb_pre = parseFloat(vals.hb_pre)
            const k_hb1 = parseFloat(vals.k_hb1)
            const k_maxhb = parseFloat(vals.k_maxhb)
            let hb97
            if(vals.sesso.value === "M")
                hb97 = 0.0013*Math.pow(eta,3) - 0.0155*Math.pow(eta,2) + 0.1334*eta + 13.907
            else
                hb97 = -0.0011*Math.pow(eta,3)+0.0255*Math.pow(eta,2)-0.0399*eta+14.062
            setHb97(round(hb97,1))
            const diff = hb97 - hb_pre
            let incrementoHb
            if(diff > k_maxhb)
                incrementoHb = k_maxhb
            else
                incrementoHb = diff
            setIncrementoHb(round(incrementoHb,1))
            const hbPost = hb_pre + incrementoHb
            setHbPost(round(hbPost,1))
            const sangue = peso * k_hb1 * incrementoHb
            setSangue(round(sangue,0))
            setMode("view")
        }
        return (
            <Container component="main" maxWidth="sm">
            <div className="boxForm margintop30">
              <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
                <MostAutocomplete control={control} name="sesso" disabled={mode!=="insert"} options={options_sesso} label={t("Sesso")+" *"} required={true} rules={{ required: true }}  errors={errors} />
                <MostTextField name="eta" disabled={mode!=="insert"} defaultValue="" required={true} label={t("Età (anni)")} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                <MostTextField name="peso" disabled={mode!=="insert"} defaultValue="" required={true} label={t("Peso")+" (kg)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                <MostTextField name="hb_pre" disabled={mode!=="insert"} defaultValue="" required={true} label={"Hb pre (g/dl)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                <MostTextField name="k_hb1" disabled={mode!=="insert"} defaultValue="4" required={true} label={t("Quantità da trasfondere per far aumentare Hb di")+" 1g/dl (ml/kg)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                <MostTextField name="k_maxhb" disabled={mode!=="insert"} defaultValue="5" required={true} label={t("Massimo incremento di Hb accettabile")+" (g/dl)"} register={register({ required: true })} errors={errors} inputProps={{type:'number'}} />
                <MostSubmitButton label={t("Calcola")} color="secondary" className={submitClassname(mode)} />
                <MostButton label={t("Nuovo calcolo")} color="secondary" className={resetClassname(mode)} onClick={nuovoCalcolo} />
                <MostTextField inputProps={readOnlyProps} label={"Hb_97°Perc (g/dl)"} value={hb97} />
                <MostTextField inputProps={resultProps} InputLabelProps={resultLabelProps} label={t("Incremento di Hb")+" (g/dl)"} value={incrementoHb} />
                <MostTextField inputProps={resultProps} InputLabelProps={resultLabelProps} label={"Hb post (g/dl)"} value={hbPost} />
                <MostTextField inputProps={resultProps} InputLabelProps={resultLabelProps} label={t("Sangue da trasfondere")+" (ml)"} value={sangue} />
              </form>
            </div>
            </Container>
        )
    }

    const MP50 = React.memo(P50)
    const MQuantitaMassimaDaTrasfondere = React.memo(QuantitaMassimaDaTrasfondere)

    return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{t("Emogas Analisi p50")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MP50 />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>MEEX {t("Calcolo")} HbS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MMEEXCalcolo />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>MEEX {t("previsione volumi")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MMEEXPrevisione />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography className={classes.heading}>{t("Quantità massima da trasfondere")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MQuantitaMassimaDaTrasfondere />
        </AccordionDetails>
      </Accordion>
    </div>
    )
}
