import { Agende } from "./Agende";

export const AgendeWin = (props) => {
    return (
      <div className="UHome app-container flexflowColumn">
        <div className="content-container" >
            <Agende mode={props.mode} />
        </div>
      </div>
    )
}
