import React, { useState, useEffect, } from "react";
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import MyAxios, {check_response} from "./MyAxios";
import { ymd } from "./Utils";
import { MostTextField, S3Field, getOptionListElement, MostDatePicker, MostAutocomplete, } from "./components/MostComponents";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useForm } from "react-hook-form";

export const DocumentsInsertDialog = (props) => {
    const { t, } = useTranslation("documents", "translation");
    const [document_date, setDocument_date] = useState(new Date())
    const [errorText, setErrorText] = useState(null)
    const [uploadInfo, setUploadInfo] = useState(null);
    const [uploadTarget, setUploadTarget] = useState(null);
    const [docRemoved, setDocRemoved] = useState(false)
    const [disabledButs, setDisabledButs] = useState(false)
    const { register, control, handleSubmit, errors, } = useForm();

    // nota: con uso di forceReloadDialog viene richiamata ad ogni apertura
    useEffect(() => {
        if (props.row) {
            // modifica
            if(props.row.document_date)
                setDocument_date(new Date(props.row.document_date))
            else
                setDocument_date(null)
            setDocRemoved(false)
        } else {
            setDocument_date(null)
        }
        setErrorText(null)
        setUploadTarget(null)
        setUploadInfo(null)
    }, [props.row,props.options_esenzioni,props.forceReloadDialog])

    const document_dateChange = (date) => {
            setDocument_date(date)
    }
    const closeDialogFunc = () => {
        props.setOpendialog(false)
    }
    const onSubmit = (vals) => {
        log.debug("onSubmit",vals)
        if (!uploadInfo && !props.row) {
            setErrorText(t("translation:File non scelto"))
            return
        }
        // documento obbligatorio
        if(props.row && docRemoved && !uploadInfo) {
            setErrorText(t("translation:File non scelto"))
            return
        }
        if(isNaN(document_date)) {
            setErrorText(t("Data non valida"))
            return
        }
        setDisabledButs(true)
        const jdata = {
            patient_id: props.paziente.value,
            document_date: document_date ? ymd(document_date) : null,
            document_type: vals.document_type.value,
            note: vals.note,
        }
        if (props.row) {
            jdata.action = 'MODIFY'
            jdata.id = props.row.id
        } else {
            jdata.action = 'ADD'
        }
        const formData = new FormData()
        /*
        // documento obbligatorio
        if(props.row && props.row.s3_id && docRemoved && !uploadInfo)
            jdata.delete_file = true
        */
        if(uploadInfo)
            formData.append('document_file', uploadInfo[0])
        formData.append('jpayload', JSON.stringify(jdata))
        const url = '/documents'
        log.debug(url, [...formData.entries()])
        log.debug(url, "jpayload", jdata)
        MyAxios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            setErrorText(res.error)
            return
          }
          closeDialogFunc()
          // diamo il tempo di eseguire il then finale
          setTimeout(()=>{
            props.reload()
          },200)
        })
        .catch(function (error) {
            log.error(error);
            setErrorText(error.message?error.message:JSON.stringify(error));
        })
        .then(function () {
            if(uploadTarget)
                uploadTarget.value = null
            setUploadInfo(null)
            setDisabledButs(false)
        })
    }

    return (
        <Dialog open={props.opendialog} fullWidth maxWidth="sm" className="wt3">
            <DialogTitle>{props.row ? t("translation:Modifica documento") : t("translation:Nuovo documento")}</DialogTitle>
            <form id="form1" onSubmit={handleSubmit(onSubmit)} noValidate>
            <DialogContent>
                <div>
                    <S3Field label={t("translation:Documento allegato")} s3_id={props.row ? props.row.s3_id : null} showError={setErrorText} docRemoved={docRemoved} setDocRemoved={setDocRemoved} setUploadInfo={setUploadInfo} setUploadTarget={setUploadTarget} />
                    <div>
                        <MostAutocomplete control={control} name="document_type" options={props.options_document_type} label={t("translation:Tipologia file")+" *"} required={true} rules={{ required: true }}  defaultValue={props.row ? getOptionListElement(props.options_document_type, props.row.document_type) : null} errors={errors} />
                    </div>
                    <MostDatePicker openTo="date" disableFuture={true} value={document_date} onChange={document_dateChange} label={t("translation:Data del documento")} />
                    <MostTextField register={register} name="note" label={t("translation:Note")} multiline={true} rows={5} inputProps={{ maxLength: 1998 }} defaultValue={props.row ? props.row.note : null} />
                </div>
                <DialogContentText className="red">
                    {errorText}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="wt3">
                <Button onClick={closeDialogFunc} disabled={disabledButs} variant="outlined" color="secondary" className="font12" >
                    {t("translation:Annulla")}
                </Button>
                <Button type="submit" disabled={disabledButs} variant="contained" color="secondary" className="font12" >
                    {props.row ? t("translation:Modifica") : t("translation:Aggiungi")}
                </Button>
            </DialogActions>
            </form>
        </Dialog>
    )
}
