import React, { useState, useEffect, } from "react";
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import MyAxios, {check_response} from "./MyAxios";
import { getOptionListElement, S3Field, MostTextField, MostDatePicker, MostAutocomplete, } from "./components/MostComponents";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const HospitalizationInsertDialog = (props) => {
    log.debug(props)
    const { t, } = useTranslation();
    const [check_in, setCheck_in] = useState(null)
    const [hospedal_days, setHospedal_days] = useState(null)
    const [hospedal_cause, setHospedal_cause] = useState(null)
    const [hospital, setHospital] = useState(null)
    const [notes, setNotes] = useState(null)
    const [errorText, setErrorText] = useState(null)
    const [uploadInfo, setUploadInfo] = useState(null)
    const [uploadTarget, setUploadTarget] = useState(null)
    const [docRemoved, setDocRemoved] = useState(false)
    const [disabledButs, setDisabledButs] = useState(false)

    function toInt(s) {
        if(!s || s.trim() === '')
            return null
        return parseInt(s)
    }

    useEffect(() => {
        if (props.row) {
            // modifica
            if(props.row.check_in)
                setCheck_in(new Date(props.row.check_in))
            else
                setCheck_in(null)
            setHospedal_cause(getOptionListElement(props.options_disease,props.row.hospedal_cause))
            setHospedal_days(props.row.hospedal_days)
            setHospital(props.row.hospital)
            setNotes(props.row.notes)
            setDocRemoved(false)
        } else {
            setCheck_in(null)
            setHospedal_days(null)
            setHospedal_cause(null)
            setHospital(null)
            setNotes(null)
        }
        setErrorText(null)
        setUploadTarget(null)
        setUploadInfo(null)
    }, [props.row,props.forceReloadDialog,props.options_disease])

    const closeDialogFunc = () => {
        props.setOpendialog(false)
    }

    const onChangeCheck_in = (check_in) => {
        setCheck_in(check_in)
    }
    const onChangeNotes = (e) => {
        setNotes(e.target.value)
    }
    const onChangeHospital = (e) => {
        setHospital(e.target.value)
    }
    const onChangeHospedal_cause = (e,el) => {
        setHospedal_cause(el)
    }
    const onChangeHospedal_days = (e,el) => {
        log.debug("onChangeHospedal_days: " + el + " e: " + e)
        log.debug(e)
        setHospedal_days(toInt(e.target.value))
    }
    const handleSubmit = () => {
        if(isNaN(check_in)) {
            setErrorText(t("Data non valida"))
            return
        }
        if ( hospedal_days == null ) {
          setErrorText(t("Giorni di ospedalizzazione")+": "+t("campo obbligatorio"))
          return
        }
        if ( hospedal_cause == null ) {
          setErrorText(t("Causa ospedalizzazione")+": "+t("campo obbligatorio"))
          return
        }
        if ( check_in == null ) {
          setErrorText(t("CheckIn")+": "+t("campo obbligatorio"))
          return
        }
        setDisabledButs(true)
        const jdata = {
          check_in: check_in,
          patient_id: props.paziente.value,
          hospedal_days: hospedal_days,
          hospedal_cause: hospedal_cause.value,
          hospital: hospital,
          notes: notes,
        }
        if (props.row) {
            jdata.action = 'MODIFY'
            jdata.id = props.row.id
        } else {
            jdata.action = 'ADD'
        }
        const formData = new FormData()
        if(props.row && props.row.s3_id && docRemoved && !uploadInfo)
            jdata.delete_file = true
        if(uploadInfo)
            formData.append('document_file', uploadInfo[0])
        formData.append('jpayload', JSON.stringify(jdata))
        const url = '/hospitalization'
        log.debug(url, [...formData.entries()])
        log.debug(url, "jpayload", jdata)
        MyAxios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            setErrorText(res.error)
            return
          }
          closeDialogFunc()
          // diamo il tempo di eseguire il then finale
          setTimeout(()=>{
            props.reload()
          },200)
        })
        .catch(function (error) {
            log.error(error);
            setErrorText(error.message?error.message:JSON.stringify(error));
        })
        .then(function () {
            if(uploadTarget)
                uploadTarget.value = null
            setUploadInfo(null)
            setDisabledButs(false)
        })
    }

    return (
        <Dialog open={props.opendialog} fullWidth maxWidth="sm" className="wt3">
            <DialogTitle>{props.row ? t("Modifica ospedalizzazione") : t("Nuova ospedalizzazione")}</DialogTitle>
            <DialogContent>
                <div>
                    <MostAutocomplete value={hospedal_cause} options={props.options_disease} label={t("Causa ospedalizzazione")+" *"} onChange={onChangeHospedal_cause} />
                </div>
                <div>
                    <MostDatePicker openTo="date" disableFuture={true} value={check_in} onChange={onChangeCheck_in} label={t("CheckIn")+" *"} />
                </div>
                <div>
                    <MostTextField name="hospedal_days" inputProps={{type:'number'}} defaultValue={hospedal_days} label={t("Giorni di ospedalizzazione")+" *"} onChange={onChangeHospedal_days} />
                </div>
                <div>
                    <MostTextField name="hospital" defaultValue={hospital} label={t("Hospital")} onChange={onChangeHospital} inputProps={{ maxLength: 255 }} />
                </div>
                <div>
                    <MostTextField name="notes" defaultValue={notes} label={t("Notes")} multiline={true} rows={5} onChange={onChangeNotes} inputProps={{ maxLength: 4096 }} />
                </div>
                <S3Field label={t("Documento allegato")} s3_id={props.row ? props.row.s3_id : null} showError={setErrorText} docRemoved={docRemoved} setDocRemoved={setDocRemoved} setUploadInfo={setUploadInfo} setUploadTarget={setUploadTarget} />
                <DialogContentText className="red">
                    {errorText}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="wt3">
                <Button onClick={closeDialogFunc} disabled={disabledButs} variant="outlined" color="secondary" className="font12" >
                    {t("Annulla")}
                </Button>
                <Button onClick={handleSubmit} disabled={disabledButs} variant="contained" color="secondary" className="font12" >
                    {props.row ? t("Modifica") : t("Aggiungi")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
