import React, { useEffect, useState, } from "react";
import log from 'loglevel';
import { useParams } from 'react-router-dom';
import { getLang, } from "./i18n";
import { useTranslation } from 'react-i18next';
import { app_server_url } from "./MyAxios";

// PROVE localhost : xampp start + /opt/lampp/htdocs/simulawt2.html

const proveLocalhost = false
let wt3Site
let G_okCallback
let waitAnswerLogout = false
let waitLogout = false

export const sendMessageToWt2 = (msg,okCallback) => {
      let ret = -1
      try {
        const iframe = document.getElementById("wtIframe");
        if(iframe.contentWindow.frames[0]) {
            if(msg === "logout" || msg === "timeout") {
                log.debug('toWt2: posting '+msg)
                waitAnswerLogout = true
                G_okCallback = okCallback
                iframe.contentWindow.frames[0].postMessage(msg, wt3Site);
                // accorgersi che non puo' arrivare conferma (frame non caricato)
                setTimeout(()=>{
                    if(waitAnswerLogout || waitLogout) {
                        log.debug("timeout waitAnswerLogout waitLogout")
                        okCallback()
                    }
                },3000)
                ret = 0
            } else {
                alert("sendMessageToWt2: unknown message "+msg)
            }
        }
        else {
            log.debug('sendMessageToWt2: no frame in the iframe')
        }
      } catch (e) {
        log.debug('sendMessageToWt2 error',e)
      }
      if (ret === -1) {
        log.debug("sendMessageToWt2: ret -1 calling callback")
        okCallback()
      }
}

export const Wt2 = (props) => {
    const resetIdleTimeout = props.resetIdleTimeout
    const { i18n, t } = useTranslation();
    const props_changeMode = props.changeMode
    const props_mutazioni = props.mutazioni
    const props_esenzioni = props.esenzioni
    const props_nuovaVisita = props.nuovaVisita
    const props_appuntamentiVisita = props.appuntamentiVisita
    const props_MEEXcalcoloHbs = props.MEEXcalcoloHbs
    const props_MEEXprevisioneVolumi = props.MEEXprevisioneVolumi
    const props_trattamentiSpeciali = props.trattamentiSpeciali
    const props_forceReloadWt2 = props.forceReloadWt2
    const [size, setSize] = useState(null)
    const [noIframe, setNoIframe] = useState(true)
    const { wtapp } = useParams()
    if (proveLocalhost)
        wt3Site = "http://localhost"
    else
        wt3Site = app_server_url

    useEffect(() => {
        // disabilitato gestione dinamica resize
        // forza un reload del contenuto del frame con alert:
        //    Leave site? Changes you made may not be saved.
        // Necessario scegliere Cancel altrimenti si perdono tutte le modifiche
        // Effettuiamo resize solo all'ingresso in wt2
        // (vedi setIframeHeight1)
        // https://stackoverflow.com/questions/55723467/using-iframe-resizer-with-a-react-js-page-as-content
        //function setIframeHeight () { }

        function messageHandler(e) {
            e.preventDefault()
            // error added or removed in iframe
            if (e.data.msg === 'validationChanged') {
              //setIframeHeight();
            } else if(e.origin === wt3Site) {
              //alert('react: got message from gwt: '+e.data);
              if(typeof e.data === "string") {
                log.debug('react: got message from gwt: ',e.data)
                //alert(e.data)
                if(e.data === "gwt_activity") {
                    if(resetIdleTimeout)
                        resetIdleTimeout()
                    return
                }
                if(waitAnswerLogout) {
                    waitAnswerLogout = false
                    if(e.data === "unsaved changes") {
                        alert(t("Modifiche non commitate"))
                        // visualizzare wt2
                        props_changeMode('WT2')
                    } else {
                        waitLogout = true
                    }
                } else if(waitLogout) {
                    waitLogout = false
                    log.debug("waitLogout, got",e.data)
                    setNoIframe(true)
                    if(G_okCallback) {
                        log.debug("calling G_okCallback")
                        G_okCallback()
                    }
                } else {
                    if(e.data === "gwt.logout") {
                        log.debug("gwt.logout")
                        // NON facciamo logout. Si chiude interfaccia wt2, ma se si sta lavorando in ambiente react si puo' continuare a lavorare
                        //props.logout()
                    } else if(e.data === "menu.mutazioni") {
                        log.debug("menu.mutazioni")
                        props_mutazioni()
                    } else if(e.data === "menu.esenzioni") {
                        log.debug("menu.esenzioni")
                        props_esenzioni()
                    } else if(e.data === "menu.nuovoappuntamentovisita") {
                        log.debug("menu.nuovoappuntamentovisita")
                        props_nuovaVisita()
                    } else if(e.data === "menu.appuntamentivisita") {
                        log.debug("menu.appuntamentivisita")
                        props_appuntamentiVisita()
                    } else if(e.data === "menu.MEEXcalcoloHbs") {
                        log.debug("menu.MEEXcalcoloHbs")
                        props_MEEXcalcoloHbs()
                    } else if(e.data === "menu.MEEXprevisioneVolumi") {
                        log.debug("menu.MEEXprevisioneVolumi")
                        props_MEEXprevisioneVolumi()
                    } else if(e.data.indexOf("menu.trattamentiSpeciali:") === 0) {
                        const ha_entry_date = e.data.split(":")[1]
                        props_trattamentiSpeciali(ha_entry_date)
                    }
                }
              }
            }
      }

      //let supportsOrientationChange = 'onorientationchange' in window
      //let orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize'
      if(window.addEventListener) {
        window.addEventListener('message', messageHandler)
        //window.addEventListener(orientationEvent, setIframeHeight)
      }
      //setIframeHeight();

      // clean up
      return () => {
        if(window.addEventListener) {
            window.removeEventListener('message', messageHandler)
            //let supportsOrientationChange = 'onorientationchange' in window
            //let orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize'
            //window.removeEventListener(orientationEvent, setIframeHeight)
        }
      }
    }, [props_appuntamentiVisita,props_nuovaVisita,props_esenzioni,props_mutazioni,props_changeMode,props_trattamentiSpeciali,props_MEEXcalcoloHbs,props_MEEXprevisioneVolumi,resetIdleTimeout,t])

    useEffect(() => {

        const setIframeHeight1 = () => {
            try {
              // in chrome basterebbe window, if ff serve window.top
              let ih = window.top.innerHeight;
              let iw = window.top.innerWidth;
              log.debug('top ih '+ih + ' iw '+iw);
              // per non fare comparire scrollbar (per chrome bastano 6, per firefox servono 8)
              ih -= 8;
              iw -= 8;
              // menu react
              ih -= 64;
              setSize([iw,ih])
              let iframe = document.getElementById("wtIframe");
              if (iframe) {
                  iframe.style.height = '' + ih + 'px';
                  iframe.style.width = '' + iw + 'px';
              }
            } catch (e) {
              log.error('setIframeHeight1', e);
            }
        }

        setTimeout(() => {
            //setIframeHeight1();
        }, 500);
            setIframeHeight1();
            setNoIframe(false)
    }, [props.paziente,props_forceReloadWt2])

        let src
        if (proveLocalhost)
            src = wt3Site + "/simulawt2.html"
        else
            src = wt3Site + "/tomcat/" + wtapp + "/"
        const lang = getLang(i18n.language)
        let locale = "en"
        if (lang === "it" || lang === "fr" || lang === "pt")
            locale = lang
        src += "?locale="+locale
        if(props.paziente)
            src += "&patientId="+props.paziente.value
        let iw = 800
        let ih = 600
        if(size) {
            iw = size[0]
            ih = size[1]
        }

    /*
    if(!noIframe)
        log.debug("wt2 iframe",src,iw,ih)
    else
        log.debug("wt2 noiframe")
    */
    if(noIframe)
        return null
    return (
        <iframe id="wtIframe" title="WT2" width={iw} height={ih} src={src}/>
    )
}
