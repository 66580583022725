import React, { useEffect, useState, } from "react";
import log from 'loglevel';
import { getLang, } from "./i18n";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { app_server_url } from "./MyAxios";

export const Mostdoc = (props) => {
    const [size, setSize] = useState(null)
    const { wtapp } = useParams()
    const { i18n } = useTranslation();

    useEffect(() => {
        const setIframeHeight = () => {
            try {
              // in chrome basterebbe window, if ff serve window.top
              let ih = window.top.innerHeight;
              let iw = window.top.innerWidth;
              log.debug('top ih '+ih + ' iw '+iw);
              // per non fare comparire scrollbar (per chrome bastano 6, per firefox servono 8)
              ih -= 8;
              //iw -= 8;
              // menu react
              ih -= 64;
              // titlebar
              ih -= 40;
              setSize([iw,ih])
              let iframe = document.getElementById("mdocIframe");
              if (iframe) {
                  iframe.style.height = '' + ih + 'px';
                  iframe.style.width = '' + iw + 'px';
              }
            } catch (e) {
              log.error('setIframeHeight1', e);
            }
        }

        function messageHandler(e) {
            e.preventDefault()
            // error added or removed in iframe
            if (e.data.msg === 'validationChanged') {
              setIframeHeight();
            }
      }

      let supportsOrientationChange = 'onorientationchange' in window
      let orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize'
      if(window.addEventListener) {
        window.addEventListener('message', messageHandler)
        window.addEventListener(orientationEvent, setIframeHeight)
      }
      setIframeHeight();

      // clean up
      return () => {
        if(window.addEventListener) {
            window.removeEventListener('message', messageHandler)
            let supportsOrientationChange = 'onorientationchange' in window
            let orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize'
            window.removeEventListener(orientationEvent, setIframeHeight)
        }
      }
    }, [])

    const lang = getLang(i18n.language)
    let mlang = "ENG"
    if (lang === "it")
        mlang = "ITA"
    else if (lang === "fr")
        mlang = "FRA"
    let src = app_server_url + "/mostdoc/WWW/MOSTDOC/html?"+wtapp.toUpperCase()+"+Mframe.extjs+"+mlang
    if(props.modalita === "amministrazione") {
        src += "+users_administration+"+src
    }
    let iw = 800
    let ih = 600
    if(size) {
        iw = size[0]
        ih = size[1]
    }

    return (
        <iframe id="mdocIframe" title="WT2" width={iw} height={ih} src={src}/>
    )
}
