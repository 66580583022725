import React, { useEffect, useState, useCallback } from "react";
import log from 'loglevel';
import { useGlobalHook } from '@devhammed/use-global-hook'
import Container from "@material-ui/core/Container";
import MyAxios, {check_response} from "./MyAxios";
import { MostTable, } from "./components/MostTable";
import { Loading, } from "./components/MostComponents";
import { NewMutation } from "./NewMutation";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

export const Mutazioni = (props) => {

    const { t, } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [forceReload, setForceReload] = useState(0);
    const [patientMutation, setPatientMutation] = useState([]); //elenco mutazioni associate al paziente
    const [mutationNames, setMutationNames] = useState([]); //elenco mutazioni per ogni gene
    const [geneList, setGeneList] = useState(null); // lista geni non ancora associati al paziente
    const [gene, setGene] = useState("");
    const [allele1, setAllele1] = useState("");
    const [allele2, setAllele2] = useState("");

    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])
    const { setConfirm1, setCContent, setOnConfirm } = useGlobalHook('confirmStore');
    function appConfirm(text,okHandler) {
      setCContent(text);
      setOnConfirm(() => x => {
        okHandler();
      });
      setConfirm1(true);
    }

    useEffect(() => {

        function BuildPatientMutation(source,mutationNames,gene) {
          var g = "";
          var c1 = "";
          var c2 = "";
          //var d1 = "";
          //var d2 = "";
          var h1 = "";
          var h2 = "";
          var mutationNameObject = "";
          var m = "";
          const buildPM = source.map((ele) => {
            // log.debug(ele);
            g = ele.gene;
            // log.debug("Bild GENE ",gene);
            mutationNameObject = mutationNames[g];
            // log.debug(mutationNameObject);
            //d1 = "";
            //d2 = "";
            for (let i = 0; i < mutationNameObject.length; i++) {
              m = mutationNameObject[i];
              // log.debug(m);
              if (m.nome_comune === ele.allele1) {
                c1 = m.nome_comune;
                h1 = m.nome_hgvs;
                if(c1==="Normale" || c1==="Non determinato") {
                    c1 = t(c1)
                    h1 = c1
                }
              }
              if (m.nome_comune === ele.allele2) {
                c2 = m.nome_comune;
                h2 = m.nome_hgvs;
                if(c2==="Normale" || c2==="Non determinato") {
                    c2 = t(c2)
                    h2 = c2
                }
              }
            }
            return { ...ele, displayAllele1: c1, displayAllele1h: h1, displayAllele2: c2, displayAllele2h: h2 };
          });
          return buildPM;
        }

        const url = "/user/get_mutations/"+props.paziente.value
        MyAxios.get(url)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          setGeneList(data.return_dict.gene);
          setMutationNames(data.return_dict.mutation_names);
          const computed_p_m = BuildPatientMutation(
              data.return_dict.patient_mutation,
              data.return_dict.mutation_names,
              data.return_dict.gene
            );
          setPatientMutation(computed_p_m);
          setLoading(false)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,props.paziente.value,forceReload,t])

  function deleteMutation(key,gene) {
    const text = t("Cancellare la mutazione del gene")+" "+gene+" ?"
    appConfirm(text,() => {
        const formData = new FormData()
        formData.append('key', key)
        const url = "/user/delete_mutation"
        log.debug(url, [...formData.entries()])
        MyAxios.post(url, formData)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          setGene("")
          setForceReload(prevCount => prevCount + 1)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    })
  }

  function submitNewMutation() {
    //alert(`Submitting gene ${gene}, Allele1 ${allele1}, Allele2 ${allele2}`);
    log.debug("submitNewMutation",gene,allele1,allele2)
    if(gene==="" || allele1==="" || allele2==="") {
        appAlert(t("Scegli gene, allele1 e allele2"))
        return;
    }
    const formData = new FormData()
    formData.append('patient_id', props.paziente.value)
    formData.append('gene', gene.value)
    formData.append('allele1', allele1.value)
    formData.append('allele2', allele2.value)
    const url = "/user/save_mutation"
    log.debug(url, [...formData.entries()])
    MyAxios.post(url, formData)
    .then((response) => {
      const data = check_response(response);
      if(!data.success) {
        log.error(data.error)
        appAlert(data.error)
        return
      }
      setGene("")
      setForceReload(prevCount => prevCount + 1)
    })
    .catch(function (error) {
        log.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    })
  }

  const columns = [
          {
            Header: t("Gene"),
            className: "geneColumn",
            columns: [
            {
               Header: "",
               accessor: "gene",
            },
            ]
          },
          {
            Header: t("Allele")+"1",
            className: "allele1Column",
            columns: [
            {
               Header: t("nome comune"),
               accessor: "displayAllele1",
            },
            {
               Header: "HGVS",
               accessor: "displayAllele1h",
            },
            ]
          },
          {
            Header: t("Allele")+"2",
            className: "allele2Column",
            columns: [
            {
               Header: t("nome comune"),
               accessor: "displayAllele2",
            },
            {
               Header: "HGVS",
               accessor: "displayAllele2h",
            },
            ]
          },
          {
            Header: "",
            id: "col1",
            columns: [
            {
               Header: "",
               accessor: "mostdoc__key",
               Cell: ({ cell: { value }, row: { original } }) => {
                 return (
                    <Tooltip title={t("Rimuovi")}>
                      <IconButton
                        onClick={() => deleteMutation(value,original.gene)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                )},
            },
            ]
          },
  ]

  if(loading)
    return <Loading />

  return (
        <div className="wt3">
        <Container component="main">
          <h2>{t("Mutazioni")}</h2>
          { patientMutation.length ?
            <MostTable 
                className="tabellaMutazioni"
                columns={columns} 
                data={patientMutation} 
                getCellProps={cellInfo => {
                    // cellInfo.column.Header (intestazione, es HGVS)
                    // cellInfo.column.parent.Header (intestazione, es. Allele2)
                    // cellInfo.row (record)
                    if (cellInfo.column.parent && cellInfo.column.parent.Header === "Gene")
                        return ({
                            style: {
                                color: "red",
                                textAlign: "center",
                                fontWeight: "bold",
                            },
                        })
                    return ({})
                }}
            />
          : 
            <div className="boxForm tcenter">
                {t("Nessuna mutazione")}
            </div>
          }
          <h2>{t("Inserimento nuovo gene")}</h2>
          <div className="boxForm">
            <NewMutation
              mutationNames={mutationNames}
              submitNewMutation={submitNewMutation}
              geneList={geneList}
              setGene={setGene}
              gene={gene}
              allele1={allele1}
              allele2={allele2}
              setAllele1={setAllele1}
              setAllele2={setAllele2}
            />
          </div>
        </Container>
        </div>
    )
}
