import React, { useEffect, useState, useCallback } from "react";
import Container from "@material-ui/core/Container";
import log from 'loglevel';
import { makeStyles, } from '@material-ui/core/styles';
import { useParams, useHistory, Redirect } from "react-router-dom";
import { Header } from './Header';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import PropagateLoader from "react-spinners/PropagateLoader";
import { HelpIframe, } from "./components/HelpDialog";
import { getOptionList, logoutReplacingWindowLocation, Loading, } from "./components/MostComponents";
import Button from "@material-ui/core/Button";
import { css } from "@emotion/core";
//import { getLang, getLocale } from "./i18n";
import { ChangePasswd } from './auth/ChangePasswd';
import { Wt2, sendMessageToWt2, } from "./Wt2";
import { string_dmy_hms, prettyJson } from "./Utils";
import { NuovoPaziente } from "./NuovoPaziente";
import { TrattamentiSpeciali } from "./TrattamentiSpeciali";
import { Diagnosi } from "./Diagnosi";
import { Dbinthem } from "./Dbinthem";
import { Tools } from "./Tools";
import { DbinthemPatient } from "./DbinthemPatient";
import { Mutazioni } from "./Mutazioni";
import { Esenzioni } from "./Esenzioni";
import { Riepilogo } from "./Riepilogo";
import { Mostdoc } from "./Mostdoc";
import { AppuntamentiVisita } from "./AppuntamentiVisita";
import { Covid } from "./Covid";
import { Gene } from "./Gene";
import { Hospitalization } from "./Hospitalization";
import { CalendarEventDialog } from "./CalendarEventDialog";
import { Documents } from "./Documents";
import { InstrumentalExams } from "./InstrumentalExams";
import { Log } from "./Log";
import MyAxios, {check_and_download,check_response} from "./MyAxios";
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { MMEEXCalcolo, MMEEXPrevisione, } from "./MEEX.js";

const drawerWidth = 600;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    justifyContent: 'flex-start',
  },
  drawerTitle: {
    flex: 1,
    textAlign: 'center',
  },
  content: {
    width: '100%',
    padding: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

export const UHome = (props) => {
    //log.debug("UHome props",props)
    const [channel] = React.useState(new BroadcastChannel("checkWt3Active"))
    const [alreadyLogged, setAlreadyLogged] = useState(false)
    const resetIdleTimeout = props.resetIdleTimeout
    const classes = useStyles()
    const [drawerTitle, setDrawerTitle] = React.useState("")
    const [drawerOpen, setDrawerOpen] = React.useState(false)
    const history = useHistory()
    const { wtapp } = useParams()
    const { userInfo, setUserInfo } = useGlobalHook('userStore')
    const [kts, setKts] = useState(0)
    const [kas, setKas] = useState(0)
    const [openInsertVisitaDialog, setOpenInsertVisitaDialog] = useState(false)
    const [ricercheLoaded, setRicercheLoaded] = useState(false)
    const [drawerMode, setDrawerMode] = useState(null)
    const [fullManual, setFullManual] = useState(false)
    const [options_stanze, setOptions_stanze] = useState([])
    const [options_dottori, setOptions_dottori] = useState([])
    const [config, setConfig] = useState(null)
    const [portaleVaccinazioni, setPortaleVaccinazioni] = useState(null)
    const [entryDate, setEntryDate] = useState(null)
    const [forceReload, setForceReload] = useState(0)
    const [forceReloadWt2, setForceReloadWt2] = useState(0)
    const [options_patient, setOptions_patient] = useState([])
    const [paziente, setPaziente] = useState(null)
    const [pazienteAfterReload, setPazienteAfterReload] = useState(null)
    const [mode, setMode] = useState("")
    const { t, i18n } = useTranslation();
    const { setLlang, setLlocale } = useGlobalHook('loginStore')
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])
    const spinnerCss = css`
        display: block;
        margin: 0 auto;
    `;

    const postPatientSelected = useCallback((patient_id) => {
        const jdata = {
            patient_id: patient_id,
        }
        MyAxios.post("/patient_selected", jdata)
        .then((response) => {
            const data = check_response(response);
            if(!data.success) {
                log.error(data.error)
                appAlert(data.error)
                return
            }
            if (data.APC_changes.length !== 0) {
                log.error("modifiche anagrafe: "+JSON.stringify(data.APC_changes))
                appAlert("Attenzione: dati modificati in base a informazioni presenti nell'anagrafe<br>"+prettyJson(data.APC_changes,1))
            }
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert])

    const lsetPaziente = useCallback((v) => {
        log.debug("setPaziente",v)
        const cookieName = wtapp+"_PATIENT_ID"
        let cookieValue = ""
        if (v)
            cookieValue = v.value
        document.cookie = cookieName+"="+cookieValue+";path=/"
        setPaziente(v)
        if(v)
            postPatientSelected(v.value)
    }, [wtapp,postPatientSelected])

    const checkSession = useCallback((callback) => {
        const url = 'get_userinfo';
        MyAxios.get(url)
          .then(response => {
            const res = check_response(response);
            if (res.success) {
                setUserInfo(res.user)
                // su reload della pagina perdiamo global store
                setLlang(res.user.lang)
                setLlocale(res.user.locale)
                if(callback)
                    callback()
            } else {
                log.error("get_userinfo error",response);
                setUserInfo({})
            }
          })
          .catch(function (error) {
            log.error(error);
            setUserInfo({})
          })
    }, [setUserInfo,setLlang,setLlocale])

    const lsetMode = useCallback((v,fromBack) => {
        log.debug("setMode",v)
        setMode(v)
        if(v==="ricerche")
            setRicercheLoaded(true)
        if(v && !fromBack) {
            const url = "/"+wtapp+"/uhome/"+v
            log.debug("history push",url)
            history.push(url)
        }
    }, [history,wtapp,])

    const sendLogoutMessageToWt2IfRunning = useCallback((newmode,callback) => {
        // wt2 e' sempre aperto, non mi interessa in che stato siamo
        // ma in che stato vogliamo andare. Dobbiamo chiudere wt2?
        /* nuovo paziente e cambio paziente */
        if (newmode === "nuovoPaziente" || newmode === "resetWT2") {
            sendMessageToWt2("logout",callback)
        } else
            callback()
    }, [])

    const changeMode = useCallback((m) => {
        log.debug("changeMode",m)
        checkSession(() => {
            if(m === "WT2") 
                lsetMode("WT2")
            else
                sendLogoutMessageToWt2IfRunning(m,()=>{
                    lsetMode(null)
                    if(m === "nuovoPaziente") {
                        // resettiamo
                        lsetPaziente(null)
                        // se paziente era gia' vuoto, wt2 non si ricrea ma rimane noIframe; forziamo comunque generazione iframe
                        setForceReloadWt2(prevCount => prevCount + 1)
                        // nota: wt2 creato senza avere iframe visibile ha pagina iniziale con componenti non dimensionati correttamente (bottone stampa, spazio per nome paziente...) ma comunque sono cose disabilitate e/o vuote
                    }
                    lsetMode(m)
                })
        })
    }, [checkSession,lsetMode,lsetPaziente,sendLogoutMessageToWt2IfRunning])

    const handleDrawerClose = () => {
        setDrawerOpen(false)
    }

    // https://stackoverflow.com/questions/39342195/intercept-handle-browsers-back-button-in-react-router
    const [ locationKeys, setLocationKeys ] = useState([])

    useEffect(() => {
        channel.onmessage = ev => {
            log.debug("got message from another window",ev)
            if(!alreadyLogged) {
                if(ev.data === "openingWindow") {
                    try {
                        log.debug("postMessage openWindow")
                        channel.postMessage("openWindow")
                    } catch(e) {}
                } else
                    setAlreadyLogged(true)
            }
        }
        return function cleanup() {
            try {
                channel.close()
            } catch(e) {}
        }
    }, [ channel, alreadyLogged, ])

    useEffect(() => {
        log.debug("channel.postMessage")
        try {
            channel.postMessage("openingWindow")
        } catch(e) {}
    }, [ channel, ])

    useEffect(() => {
      return history.listen(location => {
        if (history.action === 'PUSH') {
          setLocationKeys([ location.key ])
        }

        if (history.action === 'POP') {
          if (locationKeys[1] === location.key) {
            setLocationKeys(([ _, ...keys ]) => keys)
            // Handle forward event
            log.debug("history forward",location.pathname)
          } else {
            setLocationKeys((keys) => [ location.key, ...keys ])
            // Handle back event
            log.debug("history back",location.pathname)
          }
          const path = location.pathname
          const ls = path.lastIndexOf("/")
          const m = path.substring(ls+1)
          checkSession(() => {
            if(m === "uhome") {
                // siamo ritornati all'inizio (primo stato prima del caricamento)
                // vorremmo tornare indietro al login
                // ma fare back e' un casino, restiamo su WT2 (bisogna fare due back)
                lsetMode("WT2",1)
            } else {
                log.debug("mode from history",m)
                sendLogoutMessageToWt2IfRunning(m,()=>{
                    lsetMode(m,1)
                })
            }
          })
        }
      })
    }, [ locationKeys, history, lsetMode, checkSession, sendLogoutMessageToWt2IfRunning, ])

    useEffect(() => {
        function getConfig() {
            MyAxios.get("/get_config")
            .then((response) => {
              const data = check_response(response);
              if(!data.success) {
                log.error(data.error)
                appAlert(data.error)
                return
              }
              setConfig({
                inthem_url: data.inthem_url
              })
              getDoctor()
            })
            .catch(function (error) {
                log.error(error);
                appAlert(error.message?error.message:JSON.stringify(error));
            })
        }
        function getDoctor() {
            const jdata = {
                action: 'LIST',
                also_room: true,
            }
            MyAxios.post("/doctor", jdata)
            .then((response) => {
                const data = check_response(response);
                if(!data.success) {
                    log.error(data.error)
                    appAlert(data.error)
                    return
                }
                const d = []
                for(let i in data.doctors) {
                    if(data.doctors[i].active)
                        d.push(data.doctors[i])
                }
                const od = getOptionList(d,'username','surname+name',0,1)
                //log.debug(od)
                setOptions_dottori(od)
                const r = []
                for(let i in data.rooms) {
                    if(data.rooms[i].active)
                        r.push(data.rooms[i])
                }
                const or = getOptionList(r,'code','name')
                setOptions_stanze(or)
                // finito startup
                if(userInfo.passwordExpired)
                    changeMode('changepasswd')
            })
            .catch(function (error) {
                log.error(error);
                appAlert(error.message?error.message:JSON.stringify(error));
            })
        }

        function exportJsonPatientArray (v) {
            // esportiamo elenco pazienti per gwt
            window.top.jsonPatientArray = JSON.stringify(v)
        }
        function exportPatientsInfo (v) {
            // esportiamo elenco pazienti per mostdoc ricerche avanzate
            const info = {}
            for (let i in v) {
                const id = v[i].identifier
                info[id] = JSON.stringify({
                    name: v[i].name,
                    surname: v[i].surname,
                })
            }
            window.top.patientsInfo = info
        }
        function exportDecrypted (v) {
            // esportiamo dati in chiaro per mostdoc
            window.top.decrypted = v
        }

        const jdata = {
            action: 'LIST',
        }
        MyAxios.post("/patient", jdata)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          exportJsonPatientArray(data.rows)
          exportPatientsInfo(data.rows)
          const op = getPatientOptionList(data.rows)
          if(pazienteAfterReload) {
            for(let i in op) {
                if(op[i].value === pazienteAfterReload) {
                    lsetPaziente(op[i])
                    break
                }
            }
            setPazienteAfterReload(null)
          }
          setOptions_patient(op)
          exportDecrypted(data.trows)
          //let squid = false
          let portale_vaccinazioni = true
          /*
          for(let i in data.properties) {
            //if(data.properties[i].prop_key === "SquidCenter") {
            //    if(data.properties[i].prop_value === "true") {
            //        squid = true
            //    }
            //} else 
            if(data.properties[i].prop_key === "portale_vaccinazioni") {
                if(data.properties[i].prop_value === "false") {
                    portale_vaccinazioni = false
                }
            }
          }
          */
          setPortaleVaccinazioni(portale_vaccinazioni)
          lsetMode("WT2")
          getConfig()
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,i18n.language,forceReload,pazienteAfterReload,lsetMode,lsetPaziente,userInfo.passwordExpired,changeMode])

    const getPatientOptionList = (list) => {
        const ret = []
        for (let i in list) {
            // nota: label non viene visualizzata (lista customizzata)
            //       ma cio' che e' presente nella label e' cio' che e' 
            //       ricercabile inserendo testo da tastiera
            // mettere l'identificatore permette di fare ricerca anche per identificatore
            // mettere anche il nome complica la ricerca per cognome
            // 31.3.2022 : Longo: ricerca anche per nome (pazienti stranieri)
            ret.push({value: list[i].identifier, label: list[i].surname+" "+list[i].name+" "+list[i].identifier, row: list[i]})
        }
        return ret
    }

    const setPatientIfPossible = (p) => {
        checkSession(() => {
            sendLogoutMessageToWt2IfRunning("resetWT2",()=>{
                // chiudiamo tutto
                lsetMode(null)
                lsetPaziente(p)
                lsetMode("WT2")
            })
        })
    }

    // ricaricare liste pazienti e dati crittografati e andare su paziente
    const onNewpatient = (identifier) => {
        setOptions_patient([])
        setPazienteAfterReload(identifier)
        setForceReload(prevCount => prevCount + 1)
    }

    const chiudiPaziente = () => {
        checkSession(() => {
            setPatientIfPossible(null)
        })
    }

    const apriPaziente = (el) => {
        checkSession(() => {
            log.debug("apriPaziente",el)
            setPatientIfPossible(el)
        })
    }

    // xxx work in progress
    const stampaPaziente = () => {
        const jdata = {
            patient_id: 'xxx',
        }
        MyAxios.post("/legacy_print", jdata, {
            responseType: 'arraybuffer',
        }).then((response) => {
            //const fileName = "paziente_"+ymd(currentdate)+'.pdf'
            const fileName = 'paziente.pdf'
            const res = check_and_download(response,'pdf','pdf',fileName,1)
            if(res.success === false) {
                log.error("Error: "+res.error)
                appAlert(res.error)
                return
            }
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }

    const showManualeCompleto = () => {
        setFullManual((prev) => !prev)
    }
    const openHelpInDrawer = () => {
        setDrawerMode("help")
        setDrawerTitle(t("Manuale utente"))
        setDrawerOpen(true)
    }
    const MEEXcalcoloHbs = () => {
        setDrawerMode("MEEX_HbS")
        setDrawerTitle(t("Calcolo")+" HbS")
        setDrawerOpen(true)
    }
    const MEEXprevisioneVolumi = () => {
        setDrawerMode("MEEX_volumi")
        setDrawerTitle(t("previsione volumi"))
        setDrawerOpen(true)
    }

    const nuovaVisita = () => {
        setOpenInsertVisitaDialog(true)
    }

    /* xxx DEBUG ONLY */
    const debugTS = () => {
        trattamentiSpeciali('2022-06-27')
    }
    /* xxx DEBUG ONLY */

    const trattamentiSpeciali = (entry_date) => {
        setEntryDate(entry_date)
        changeMode("trattamentiSpeciali")
    }

    const appuntamentiVisita = () => {
        changeMode("appuntamentiVisita")
    }
    const mutazioni = () => {
        changeMode("mutazioni")
    }
    const tools = () => {
        changeMode("tools")
    }
    const esenzioni = () => {
        changeMode("esenzioni")
    }
    const paginaPrincipale = () => {
        changeMode('WT2')
    }

    const logout = () => {
        sendLogoutMessageToWt2IfRunning("resetWT2",()=>{
            logoutReplacingWindowLocation()
        })
    }

    const UHomeHeader = (props) => {
        let title = ""
        if (props.mode === "ricerche") 
            title = t("Ricerche avanzate")
        else if (props.mode === "amministrazione") 
            title = t("Amministrazione")+" / "+t("Gestione tabelle")
        else if (props.mode === "nuovoPaziente") 
            title = t("Nuovo paziente")
        else if (props.mode === "query_log") 
            title = t("Amministrazione")+" / "+t("Log")
        else if (props.mode === "diagnosi") 
            title = t("Diagnosi")
        else if (props.mode === "dbinthem") 
            title = "DB-INTHEM"
        else if (props.mode === "dbinthemPatient") 
            title = t("Consenso DB-INTHEM")
        else if (props.mode === "document") 
            title = t("Gestione Documentale")
        else if (props.mode === "covid") 
            title = "Covid"
        else if (props.mode === "gene") 
            title = t("Terapia genica")
        else if (props.mode === "hospitalization") 
            title = t("Ospedalizzazioni")
        else if (props.mode === "instrumental_exam") 
            title = t("Esami Strumentali")
        else if (props.mode === "mutazioni") 
            title = t("Tipizzazione molecolare")
        else if (props.mode === "tools") 
            title = t("Tools")
        else if (props.mode === "riepilogo") 
            title = t("Anamnesi Storica")
        else if (props.mode === "esenzioni") 
            title = t("Esenzioni")
        else if (props.mode === "appuntamentiVisita") 
            title = t("Appuntamenti visita")
        else if (props.mode === "trattamentiSpeciali") 
            title = t("Accesso ospedaliero del")+" "+string_dmy_hms(entryDate,2)+" : "+t("Trattamenti Speciali")
        else if (props.mode === "changepasswd") 
            title = t("Cambio password")
        if (title === "")
            return null
        return (
            <div className="uhomeTitle displayFlex">
                <div className="w20p taleft nowrap">
                  { userInfo.passwordExpired ? null
                  :
                    <Button onClick={paginaPrincipale} variant="contained" color="primary" size="small" startIcon={<ArrowBackIcon />} >
                        {t("Pagina principale")}
                    </Button>
                  }
                </div>
                <div className="flex1">
                    <h1>{title}</h1>
                </div>
                <div className="w20p"/>
            </div>
        )
    }

    function forceReloadTrattamentiSpeciali() {
        setKts(prevCount => prevCount + 1)
    }
    function keyTrattamentiSpeciali() {
        return "kts_"+kts
    }

    function reloadAppuntamentiVisita() {
        if (mode !== "appuntamentiVisita")
            return
        setKas(prevCount => prevCount + 1)
    }
    function keyAppuntamentiVisita() {
        return "kas_"+kas
    }

    const LoadingPatients = () => {
        let text1 = ""
        if(pazienteAfterReload)
            text1 = t("Paziente inserito correttamente")
        //const text2 = t("Attendere, caricamento pazienti in corso...")
        return  (
          <div className="app-container">
            <div className="content-container">
              <div className="margintop30 tcenter">
                <p><b>{text1}<br />{t("Attendere, caricamento pazienti in corso")}</b></p><PropagateLoader color={window.__most.palette.primary.darkColor} css={spinnerCss} loading={true} />
              </div>
            </div>
          </div>
            )
    }

    function alreadLoggedGoOn() {
        setAlreadyLogged(false)
    }

    if(alreadyLogged) {
        return (
        <div className="app-container">
        <div className="content-container">
        <div className="wt3">
            <Container component="main" maxWidth="sm" className="blackColor">
            <div className="boxForm margintop30">
                <h1>{t("Attenzione")}</h1>
                <p>{t("Already logged in 1")}</p>
                <p>{t("Already logged in 2")}</p>
                <p>{t("Already logged in 3")}</p>
                <div className="taright tabBar">
                        <Button variant="outlined" color="secondary" onClick={alreadLoggedGoOn} >
                            {t("Continua sconsigliato")}
                        </Button>
                </div>
            </div>
            </Container>
        </div>
        </div>
        </div>
        )
    }

    if(options_patient.length === 0)
        return <LoadingPatients />

    if(!userInfo.username)
        return (<Redirect to={'/'+wtapp+'/login'} />)

    if ((mode === "dbinthemPatient" || mode === "mutazioni" || mode === "appuntamentiVisita" || mode === "esenzioni" || mode === "riepilogo" || mode === "document" || mode === "trattamentiSpeciali")
    && !paziente) {
        log.debug("no patient, forcing WT2")
        setMode("WT2")
        // rimane url originale ma si va in mode WT2
        return <Loading />
    }

    let div1, div2, div3
    if (mode === "WT2") {
        div1 = "block"
        div2 = "hidden"
        div3 = "hidden"
    } else if (mode === "ricerche") {
        div1 = "hidden"
        div2 = "hidden"
        div3 = "block"
    } else {
        div1 = "hidden"
        div2 = "block"
        div3 = "hidden"
    }
    let contentClass = "content-container"
    if(mode === "trattamentiSpeciali" && paziente)
        contentClass += " accessoContent"

    //log.debug("mode",mode,"div1",div1)
    return (
    <div className={"UHome app-container "+classes.root}>
      <div className={"divStretchedByDrawer "+clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
         <Header wtapp={wtapp} options_patient={options_patient} paziente={paziente} setPaziente={setPatientIfPossible} chiudiPaziente={chiudiPaziente} apriPaziente={apriPaziente} stampaPaziente={stampaPaziente} changeMode={changeMode} portaleVaccinazioni={portaleVaccinazioni} logout={logout} nuovaVisita={nuovaVisita} tools={tools} openHelpInDrawer={openHelpInDrawer} debugTS={debugTS} />
        <UHomeHeader mode={mode} />
        <div className={contentClass}>
          <div className={div1}>
            <Wt2 paziente={paziente} esenzioni={esenzioni} mutazioni={mutazioni} changeMode={changeMode} forceReloadWt2={forceReloadWt2} nuovaVisita={nuovaVisita} appuntamentiVisita={appuntamentiVisita} trattamentiSpeciali={trattamentiSpeciali} MEEXcalcoloHbs={MEEXcalcoloHbs} MEEXprevisioneVolumi={MEEXprevisioneVolumi} resetIdleTimeout={resetIdleTimeout} logout={logout} />
          </div>
          <div className={div2+ " height100"}>
                { mode === "nuovoPaziente" ?
                    <NuovoPaziente onNewpatient={onNewpatient} />
                : mode === "query_log" ?
                    <Log />
                : mode === "diagnosi" ?
                    <Diagnosi />
                : mode === "amministrazione" ?
                    <Mostdoc modalita={mode} />
                : mode === "dbinthem" ?
                    <Dbinthem config={config} />
                : mode === "tools" ?
                    <Tools />
                : mode === "dbinthemPatient" && paziente ?
                    <DbinthemPatient paziente={paziente} config={config} />
                : mode === "esenzioni" && paziente ?
                    <Esenzioni paziente={paziente} />
                : mode === "riepilogo" && paziente ?
                    <Riepilogo paziente={paziente} />
                : mode === "mutazioni" && paziente ?
                    <Mutazioni paziente={paziente} />
                : mode === "document" && paziente ?
                    <Documents paziente={paziente} />
                : mode === "instrumental_exam" && paziente ?
                    <InstrumentalExams paziente={paziente} />
                : mode === "covid" && paziente ?
                    <Covid paziente={paziente} />
                : mode === "gene" && paziente ?
                    <Gene paziente={paziente} />
                : mode === "hospitalization" && paziente ?
                    <Hospitalization paziente={paziente} />
                : mode === "appuntamentiVisita" && paziente ?
                    <AppuntamentiVisita paziente={paziente} options_stanze={options_stanze} options_dottori={options_dottori} key={keyAppuntamentiVisita()} />
                : mode === "trattamentiSpeciali" && paziente ?
                    <TrattamentiSpeciali paziente={paziente} entryDate={entryDate} key={keyTrattamentiSpeciali()} forceReloadTrattamentiSpeciali={forceReloadTrattamentiSpeciali} />
                : mode === "changepasswd" ?
                    <ChangePasswd paginaPrincipale={paginaPrincipale} />
                :
                    null
                }
          </div>
          <div className={div3}>
                { ricercheLoaded ?
                    <Mostdoc modalita="ricerche" />
                :
                    null
                }
          </div>
        </div>
        { paziente ?
            <CalendarEventDialog opendialog={openInsertVisitaDialog} setOpendialog={setOpenInsertVisitaDialog} paziente={paziente} options_stanze={options_stanze} options_dottori={options_dottori} currentdate={new Date()} agenda="appuntamenti" reload={reloadAppuntamentiVisita} />
        : null }
      </div>
      <Drawer
        className={(drawerOpen?"":"hidden ")+classes.drawer}
        variant="persistent"
        anchor="right"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
          <div className={classes.drawerTitle}>
            {drawerTitle}
          </div>
          { drawerMode === "help" && !fullManual ?
            <Button variant="outlined" color="secondary" onClick={showManualeCompleto} >
                {t("Manuale completo")}
            </Button>
          : drawerMode === "help" && fullManual ?
            <Button variant="outlined" color="secondary" onClick={showManualeCompleto} >
                {t("Chiudi manuale completo")}
            </Button>
          : null }
        </div>
        <Divider />
        <div className="drawer-container">
            { drawerMode === "MEEX_HbS" ?
                <MMEEXCalcolo />
            : drawerMode === "MEEX_volumi" ?
                <MMEEXPrevisione />
            : drawerMode === "help" ?
                <HelpIframe fullManual={fullManual} />
            : null }
        </div>
      </Drawer>
    </div>
    );
};
