
import { getLang } from './i18n';

export const options_formatofile = [
        {value: "CSV", label: "CSV"},
        {value: "JSON", label: "JSON"},
        {value: "XLSX", label: "XLSX"},
        {value: "XML", label: "XML"},
    ]

export const options_formatofile_download = (t) => {
    return [
        {value: "CSV", label: "CSV"},
        {value: "JSON", label: "JSON"},
        {value: "XLSX", label: "XLSX"},
        {value: "XML", label: "XML"},
        {value: "video", label: t("visualizzazione a video")},
    ]
}

export const tableLangColumn = (colname,i18nLanguage) => {
  const lang = getLang(i18nLanguage)
  let column = colname+"_it"
  if (lang === "en")
    column = colname+"_en"
  else if (lang === "fr")
    column = colname+"_fr"
  else if (lang === "pt")
    column = colname+"_pt"
  return column
}

export const descriptionLangColumn = (i18nLanguage) => {
  return tableLangColumn("description",i18nLanguage)
}

export const svcTableLangColumn = (i18nLanguage) => {
  return tableLangColumn("trans",i18nLanguage)
}

