import React, { useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { filesizeFmt, string_dmy_hms } from '../Utils'
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';

export const dmy_date_formatter = (params: ValueFormatterParams) => string_dmy_hms(params.value,2)
export const dmyhms_date_formatter = (params: ValueFormatterParams) => string_dmy_hms(params.value,1)
export const hm_time_formatter = (params: ValueFormatterParams) => (params.value ? params.value.substring(0,5) : '')
export const filesize_formatter = (params: ValueFormatterParams) => filesizeFmt(params.value)

// ritornando null invece di "", per i valori false viene stampato false???
export const bool_rendercell = (params: GridCellParams) => (params.value ? <CheckIcon /> : "" )

// tooltip. nota: i newline diventano spazi
// in alternativa
// vedi wt3 Documents.js colonna note (testo cliccabile -> appAlert)
export const TooltipLongText = (props) => (
    <Tooltip title={props.text}><span>{props.text}</span></Tooltip>
)

/*
// https://stackoverflow.com/questions/64331095/how-to-add-a-button-to-every-row-in-material-ui-table
      { field: 'bottone', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
          const onClick = () => {
            const api: GridApi = params.api
            const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c)
            const thisRow = {}
            fields.forEach((f) => {
              thisRow[f] = params.getValue(f)
            })
            alert(JSON.stringify(thisRow))
          }
          return <IconButton onClick={onClick}><SettingsIcon /></IconButton>
        }
      },
*/

// https://material-ui.com/components/data-grid/rendering/
function customCheckbox(theme) {
  return {
    '& .MuiDataGrid-colCellCheckbox': {
        // altrimenti cella di intestazione con checkbox non ha bordo alto giusto
        height: 56,
    },
    '& .MuiDataGrid-colCellTitleContainer .MuiCheckbox-root svg': {
      // sfondo checkbox intestazione
      backgroundColor: 'white',
    },
    '& .MuiCheckbox-root svg': {
      width: 16,
      height: 16,
      backgroundColor: 'transparent',
      border: `1px solid ${
        // colore bordo checkbox NON selezionata
        theme.palette.type === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
      }`,
      borderRadius: 2,
    },
    '& .MuiCheckbox-root svg path': {
      display: 'none',
    },
    '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
      // checkbox selezionata
      //backgroundColor: '#1890ff',
      //borderColor: '#1890ff',
      backgroundColor: window.__most.palette.primary.darkColor,
      borderColor: window.__most.palette.primary.darkColor,
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
      position: 'absolute',
      display: 'table',
      border: '2px solid #fff',
      borderTop: 0,
      borderLeft: 0,
      transform: 'rotate(45deg) translate(-50%,-50%)',
      opacity: 1,
      transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
      content: '""',
      top: '50%',
      left: '39%',
      width: 5.71428571,
      height: 9.14285714,
    },
    '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
      width: 8,
      height: 8,
      // checkbox intestazione selezionata
      //backgroundColor: '#1890ff',
      backgroundColor: window.__most.palette.primary.darkColor,
      transform: 'none',
      top: '39%',
      border: 0,
    },
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: 0,
    // TH color 
    color:
      //theme.palette.type === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
      '#00000',
    /*
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    */
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
      // TH backgroundColor
      //backgroundColor: theme.palette.type === 'light' ? '#fafafa' : '#1d1d1d',
      backgroundColor: '#dddddd'
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
      // linea separatore colonne
      borderRight: `1px solid ${
        theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
      }`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      // linea separatore righe
      borderBottom: `1px solid ${
        theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
      }`,
    },
    '& .MuiDataGrid-cell': {
      // TD color
      color:
        theme.palette.type === 'light'
          ? 'rgba(0,0,0,.85)'
          : 'rgba(255,255,255,0.65)',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
    ...customCheckbox(theme),
  },
}));

export const DataTable = (props) => {
  const [setSelected] = useState(false)
  const classes = useStyles();
  //const { t } = useTranslation()

  const onRowSelected = (info) => {
    setSelected(info.isSelected)
  }

  // columns: 
    // type: number date dateTime boolean (custom (dollars))
    // sortable: false
  // density: standard compact comfortable
  // almeno 5 righe visibili: altezza minima 286px
  return (
   <div style={{ minHeight: (286+(props.toolbar?64:0)), height: '100%', width: '100%', }} className="displayFlex flexflowColumn">
    {props.toolbar? 
          <Toolbar className="rowreverse">
            {props.tbButton? 
                <Button variant="contained" color="primary" onClick={props.tbButtonOnClick} className="marginr10" > {props.tbButton} </Button>
            : null }
            {/*
                gestione di un bottone che compare quando si seleziona una riga
                1. non utilizzata
                2. stesso handler su click del bottone principale?

                selected && props.tbButtonOnSelected?
                <Button variant="contained" color="primary" onClick={props.tbButtonOnClick} className="marginr10"> {props.tbButtonOnSelected} </Button>
            : null }
            : null 
            */}
          </Toolbar>
      : null }
    <div style={{ height: props.height ? props.height : '100%', width: '100%',  }} className="flex1">
     <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>
       <DataGrid 
        className={classes.root}
        rows={props.rows} columns={props.columns} 
        checkboxSelection={props.checkboxSelection}
        getRowId={props.getRowId}
        onRowClick={props.onRowClick}
        onRowSelected={onRowSelected}
        disableSelectionOnClick={props.disableSelectionOnClick}
        density={props.density}
        pageSize={props.pageSize} />
      </div>
     </div>
    </div>
   </div>
  );
}

