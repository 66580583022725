import React, { useState, } from "react";
import log from 'loglevel';
import MyAxios from "./MyAxios";
import { useParams } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';
import { checkWtapp } from './UtilsWt3';
import { SignIn } from './auth/SignIn';
import { Redirect } from "react-router-dom";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { Loading, getWtappFromLocation } from './components/MostComponents';

export const Login = (props) => {
  const { wtapp } = useParams()
  const redirect = checkWtapp(wtapp)
  if (redirect)
    return redirect
  return (
  <div className="app-container flexflowColumn">
    <Header empty={true} wtapp={wtapp} />
    <SignIn resetIdleTimeout={props.resetIdleTimeout} wtapp={wtapp} />
    <Footer />
  </div>
)}

export function Logout() {
  const [loading, setLoading] = useState(true)
  const { setUserInfo } = useGlobalHook('userStore')
  //localStorage.removeItem("token")
  React.useEffect(() => {
    log.debug("Logout")
    setUserInfo({})
    sessionStorage.removeItem('eciesPrivateKey')
    MyAxios.get('userlogout')
        .then((response) => {
        })
        .catch(function (error) {
        })
        .then(() => {
            setLoading(false)
        })
  }, [setUserInfo])

  if(loading)
    return <Loading />
  //log.debug("Logout" + localStorage.getItem("token"))
  const wtapp = getWtappFromLocation()
  if (wtapp)
    return (<Redirect to={'/'+wtapp+'/login'} />)
  return (<Redirect to='/' />)
}
