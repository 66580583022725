import React, { useState, useCallback, useEffect, } from "react";
import log from 'loglevel';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useTranslation } from "react-i18next";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Container from "@material-ui/core/Container";
import MyAxios, {check_response} from "./MyAxios";
import { CalendarEventDialog } from "./CalendarEventDialog";
import { MostTable } from "./components/MostTable";
import { getOptionListLabel, Loading, } from "./components/MostComponents";
import { dmy, } from "./Utils";

export const AppuntamentiVisita = (props) => {
    const { t } = useTranslation()
    const [forceReload, setForceReload] = useState(0);
    const [got2, setGot2] = useState(false)
    const [data, setData] = useState([])
    const [row, setRow] = useState(null)
    const [openInsertDialog, setOpenInsertDialog] = useState(false)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
        setContent(text);
        setAlert1(true);
    }, [setContent,setAlert1])
    const { setConfirm1, setCContent, setOnConfirm } = useGlobalHook('confirmStore');
    function appConfirm(text,okHandler) {
      setCContent(text);
      setOnConfirm(() => x => {
        okHandler();
      });
      setConfirm1(true);
    }

    const getRoomName = useCallback((code) => {
        return getOptionListLabel(props.options_stanze,code)
    }, [props.options_stanze])
    const getDoctorName = useCallback((code) => {
        return getOptionListLabel(props.options_dottori,code)
    }, [props.options_dottori])

    useEffect(() => {
        function compare( a, b ) {
            // desc
            if ( a.dataevento < b.dataevento )
                return 1;
            if ( a.dataevento > b.dataevento )
                return -1;
            return 0;
        }
        const formData = new FormData()
        formData.append('patient_id', props.paziente.value)
        const url = "/eventiAppuntamento"
        log.debug(url, [...formData.entries()])
        MyAxios.post(url, formData)
        .then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            appAlert(res.error)
            return
          }
          const rows = res.rows
          rows.sort( compare )
          for(let i in rows) {
            if (rows[i].time_from) {
                rows[i].time_from = rows[i].time_from.substring(0,5)
                rows[i].time_to = rows[i].time_to.substring(0,5)
            }
          }
          setData(rows)
          setGot2(true)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,props.paziente.value,getDoctorName,getRoomName,forceReload,])

    function editAppuntamento(row) {
        setRow(row)
        setOpenInsertDialog(true)
    }

    function deleteAppuntamento(key,data) {
        const text = t("Cancellare l'appuntamento del")+" "+dmy(data,2)+" ?"
        appConfirm(text,() => {
            const jdata = {
                action: 'DELETE',
                patient_id: props.paziente.value,
                mostdoc__key: key,
            }
            MyAxios.post("/visit_appointment", jdata)
            .then((response) => {
              const data = check_response(response);
              if(!data.success) {
                log.error(data.error)
                appAlert(data.error)
                return
              }
              setForceReload(prevCount => prevCount + 1)
            })
            .catch(function (error) {
                log.error(error);
                appAlert(error.message?error.message:JSON.stringify(error));
            })
        })
    }
    function reloadOnInsert() {
        setForceReload(prevCount => prevCount + 1)
    }

    function nuovo() {
        setRow(null)
        setOpenInsertDialog(true)
    }

    const columns = [
          {
            Header: t("Data"),
            accessor: "dataevento",
            Cell: ({ cell: { value }, row: { original } }) => {
                 const s = dmy(value,2)
                 return (
                    <span>{s}</span>
                )},
          },
          {
            Header: t("Dalle ore"),
            accessor: "time_from",
          },
          {
            Header: t("Alle ore"),
            accessor: "time_to",
          },
          {
            Header: t("Medico"),
            accessor: "doctor",
            Cell: ({ cell: { value }, row: { original } }) => {
                 const s = getDoctorName(value)
                 return (
                    <span>{s}</span>
                )},
          },
          {
            Header: t("Ambulatorio"),
            accessor: "room",
            Cell: ({ cell: { value }, row: { original } }) => {
                 const s = getRoomName(value)
                 return (
                    <span>{s}</span>
                )},
          },
          {
            Header: t("Note"),
            accessor: "dettagli",
          },
          {
            Header: "",
            accessor: "mostdoc__key",
            style: { width: "130px" },
            Cell: ({ cell: { value }, row: { original } }) => {
              return (
                <React.Fragment>
                <Tooltip title={t("Modifica")}>
                  <IconButton
                    onClick={() => editAppuntamento(original)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("Rimuovi")}>
                  <IconButton
                    onClick={() => deleteAppuntamento(value,original.dataevento)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                </React.Fragment>
             )},
           },
        ]

    if(!got2)
        return <Loading />
    return (
        <div className="wt3">
        <Container component="main">
          <div>
            <Toolbar className="rowreverse">
                <Button variant="contained" onClick={nuovo} className="submitButton" > {t("Nuovo appuntamento")} </Button>
            </Toolbar>
            { data.length ?
                <MostTable className="grayTable" columns={columns} data={data} />
            : 
                <div className="boxForm tcenter">
                    {t("Nessun appuntamento")}
                </div>
            }
          </div>
        </Container>
        <CalendarEventDialog opendialog={openInsertDialog} setOpendialog={setOpenInsertDialog} paziente={props.paziente} options_stanze={props.options_stanze} options_dottori={props.options_dottori} reload={reloadOnInsert} currentdate={new Date()} agenda="appuntamenti" row={row} />
        </div>
    )
}
