import log from 'loglevel';

    // input: date
    // output: stringa yyyy-mm-dd
export const ymd = (d) => {
    if(!d)
        return d;
    const ret = 
     d.getFullYear() + "-" 
     + ("0" + (d.getMonth()+1)).slice(-2) + "-"
     + ("0" + d.getDate()).slice(-2)
     return ret
}

    // input: date
    // output: stringa
        // se mode == 2: dd/mm/yyyy
        // se mode == 1: dd/mm/yyyy HH:MM:SS
        // se mode == 0: HH:MM:SS
export const dmy_hms = (d,mode) => {
    var ret = ""
    if (mode)
        ret = ("0" + d.getDate()).slice(-2) + "/"
         + ("0" + (d.getMonth()+1)).slice(-2) + "/"
         + d.getFullYear() + " "
    if (mode === 2)
        return ret
    return ret + ("0" + d.getHours()).slice(-2) + ":"
         + ("0" + d.getMinutes()).slice(-2) + ":"
         + ("0" + d.getSeconds()).slice(-2)
}

    // input: stringa data in formato ISO
    // output: date (se stringa nulla, data nulla)
    // (inizializzazione datePicker)
export const string2date = (s) => {
    if(!s)
        return null
    return new Date(s)
}

    // input: stringa data in formato ISO
    // output: stringa
        // se mode == 3: dd/mm/yyyy HH:MM (UTC)
        // se mode == 2: dd/mm/yyyy
        // se mode == 1: dd/mm/yyyy HH:MM:SS
        // se mode == 0: HH:MM:SS
export const string_dmy_hms = (s,mode) => {
    if(!s)
        return ""
    if(mode === 3)
        return s.substring(8,10)+"/"+s.substring(5,7)+"/"+s.substring(0,4)+" "+s.substring(11,19)
    return dmy_hms(new Date(s),mode)
}

    // input: stringa  yyyy-mm-dd
    // output: stringa  dd/mm/yyyy 
export const dmy = (s) => {
    if(!s)
        return ""
    return s.substring(8)+"/"+s.substring(5,7)+"/"+s.substring(0,4)
}

    // output: stringa ora attuale
        // se mode == 4: yyyymmddHHMMSSmilli
        // se mode == 3: yyyymmddHHMM
        // se mode == 2: dd/mm/yyyy
        // se mode == 1: dd/mm/yyyy HH:MM:SS
        // se mode == 0: HH:MM:SS
export const now = (mode) => {
    if (mode === 3) {
        const d = new Date()
        return d.getFullYear() +
            ("0" + (d.getMonth()+1)).slice(-2) +
            ("0" + d.getDate()).slice(-2) +
            ("0" + d.getHours()).slice(-2) + 
            ("0" + d.getMinutes()).slice(-2)
    }
    if (mode === 4) {
        const d = new Date()
        return d.getFullYear() +
            ("0" + (d.getMonth()+1)).slice(-2) +
            ("0" + d.getDate()).slice(-2) +
            ("0" + d.getHours()).slice(-2) + 
            ("0" + d.getMinutes()).slice(-2) +
            ("0" + d.getSeconds()).slice(-2) +
            ("00" + d.getMilliseconds()).slice(-3)
    }
    return dmy_hms(new Date(),mode)
}

// NOTA: la post|get deve avere "responseType: 'arraybuffer'" o "responseType: 'blob'"
export const downloadArrayBuffer = (ab,fileName,fileType,showInNewWin) => {
    var w = window
    var blob = new Blob([ab], {type: fileType})
    var url = w.URL.createObjectURL(blob)
    if (showInNewWin) {
        // filename: uuid generato dal browser
        window.open(url)
        return
    }
    var link = w.document.createElement('a')
    link.href = url
    link.download = (fileName && fileName !== "") ? fileName : "download"
    link.click()
}

// Material-UI: The data grid component requires all rows to have a unique id property.
export const addIdToRows = (rows) => {
    return dataGridMakeIdUnique(rows,1)
}
// aggiunge o rimpiazza campo ID (deve essere univoco)
export const dataGridMakeIdUnique = (rows,missingId) => {
    const ret=[]
    for (let i in rows) {
        const r = rows[i]
        if(missingId)
            r.id = i
        else {
            r['REAL_ID'] = r.id
            r.id = i
        }
        ret.push(r)
    }
    return ret
}

export const prettyJson = (obj,preformatted,nl2br) => {
    let pretty = JSON.stringify(obj,null,2)
    if(nl2br)
        pretty = pretty.replace(/[\n]/g, '<br>')
    if(preformatted)
        pretty = "<pre>"+pretty+"</pre>"
    return pretty
}

// nota: esistono cookie che non vengono visti da js e che quindi non possono essere cancellati da js (es. cookie di sessione)
export const delete_cookie = ( name, path, domain ) => {
    //domain = location.host
    document.cookie = name + "=" +
      ((path) ? ";path="+path:"")+
      ((domain)?";domain="+domain:"") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
}
export const set_cookie = ( name, value ) => {
    document.cookie = name+"="+value+"; path=/; SameSite=Lax"
}
export const get_cookie = ( cname ) => {
    const name = cname + "="
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ""
}

export const filesizeFmt = (b) => {
    function rou(n) {
        n = Math.round(n * 10)
        return n / 10.0
    }
    if(!b)
        return ""
    let k = b / 1024.0
    if (k < 1)
        return b+" B"
    let m = k / 1024.0
    if (m < 1)
        return rou(k) +" KB"
    return rou(m)+" MB"
}

export const round = (x,n) => {
    const y = Math.pow(10,n)
    return Math.round(y * x) / y
}

export const scrollToElement = (id) => {
    const el = document.getElementById(id)
    if (el)
        el.scrollIntoView()
}

/*
export function emptyCache(){
    if('caches' in window){
        caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
    }
}
*/

export const notNullString = (s) => {
    if(s)
        return s
    return ""
}

// per abilitare log su console: window.log.setLevel("debug")
export const logInit = () => {
    window.log = log
    const running_fe_version = window._env_.FE_VERSION
    if (running_fe_version === "devel")
        log.setLevel("debug")
    else
        log.setLevel("warn")
}

export const getCurrentTimezone = () => {
    if (Intl)
        return Intl.DateTimeFormat().resolvedOptions().timeZone
    return "Europe/Rome"
}

export const toInt = (s) => {
    if(!s || s.trim() === '')
        return null
    return parseInt(s)
}

export const toFloat = (s) => {
    if(!s || s.trim() === '')
        return null
    return parseFloat(s)
}

// indice della n.ma occorrenza di "pat" in "str"
export const nthIndex = (str, pat, n) => {
    var L= str.length, i= -1;
    while(n-- && i++<L){
        i= str.indexOf(pat, i);
        if (i < 0) break;
    }
    return i;
}

export const isSafari = () => {
    const ret = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    return ret
}

export const isMobile = () => {
  let check = false;
  (function(a){if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0,4))) check = true;})(navigator.userAgent||navigator.vendor||window.opera);
  return check;
}

export function showUrl(url) {
    window.open(url, '_blank')
}

