import React, { useState } from "react";
import log from 'loglevel';
import { Route, Redirect } from "react-router-dom";
import { now } from "./Utils"
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios, {check_response} from "./MyAxios";
import { Loading } from './components/MostComponents';

const GuardedRoute = ({ component: Component, ...rest }) => {
    //log.debug("rest",rest);
    //log.debug("Component",Component);
    const resetIdleTimeout = rest.resetIdleTimeout

    // prop forcereload forza azzeramento stato ad ogni accesso
    // della componente (aggiunta key univoca)
    // NOTA: non si puo' usare appAlert() altrimenti
    //       a fronte dell'alert viene ricaricata completamente la pagina
    let forcereload = false;
    if (rest.forcereload === "true")
        forcereload = true;
    //log.debug("forcereload",forcereload);

    const wtapp = rest.computedMatch.params.wtapp

    // il path lo inoltriamo come prop mode alla component
    // non consideriamo '/' iniziale, e non consideriamo wtapp (prima parte)
    const mode = rest.path.substring(1).split("/")[1]

    const { userInfo, setUserInfo } = useGlobalHook('userStore');
    const { setLlang, setLlocale } = useGlobalHook('loginStore')
    const [loading, setLoading] = useState(true);

    const isonow = () => new Date().toISOString();

    React.useEffect(() => {
        log.debug("--- "+now()+" GuardedRoute",mode);
        //log.debug("call get_userinfo");
        let url = 'get_userinfo';
        MyAxios.get(url)
          .then(response => {
            const res = check_response(response);
            if (res.success) {
                //log.debug("userInfo",res.user);
                if(res.user.wtapp !== wtapp) {
                    log.error("different wtapp",wtapp,res.user.wtapp)
                    setUserInfo({})
                } else {
                    setUserInfo(res.user)
                    // su reload della pagina perdiamo global store
                    setLlang(res.user.lang)
                    setLlocale(res.user.locale)
                }
            } else {
                log.error("get_userinfo error",response);
                setUserInfo({})
            }
          })
          .catch(function (error) {
            log.error(error);
            setUserInfo({})
          })
          .then(function () {
            // always executed
            setLoading(false);
          });
    }, [setUserInfo,mode,wtapp,setLlang,setLlocale]);

    //log.debug("GuardedRoute: loading:",loading,"userInfo",userInfo,"mode",mode);
    if(loading)
        return(
            <Loading />
        )
    return(
        <Route {...rest} render={(rest) => (
            userInfo.username ?
                (forcereload ? (
                        <Component {...rest} mode={mode} key={isonow()} resetIdleTimeout={resetIdleTimeout} />
                    ) : (
                        <Component {...rest} mode={mode} resetIdleTimeout={resetIdleTimeout} />
                    ))
                : <Redirect to={"/"+wtapp+"/login"} />
        )} />
    )
}

export default GuardedRoute;
