import React, { useEffect, useState, useCallback } from "react";
import log from 'loglevel';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { Header } from './Header';
import MyAxios, {check_response} from "./MyAxios";
import { useTranslation } from 'react-i18next';
import { Link, } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import { Footer } from './Footer';
import logo from './WebTHAL-logo.png';

export const Home = () => {
  const { t, } = useTranslation()
  const [wtapps, setWtapps] = useState([])
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
  }, [setContent,setAlert1])

  useEffect(() => {
        MyAxios.get("/get_config")
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          setWtapps(data.wt_centers)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert])

  return (
  <div className="app-container flexflowColumn">
    <Header empty={true} />
    <div id="HomeDiv">
      <div className="Home-header">
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={5} md={6} lg={8}>
            <div id="title">
              <h1>WebTHAL 3</h1>
              <h2>{t("Benvenuto")}</h2>
              <div>
                  {t("Applicazioni disponibili")}:
                  <ul className="noliststyle">
                    {wtapps.map((wa,index)=>{
                        return (
                          <li key={"li_"+wa}>
                            <Link to={"/"+wa+"/login"}>{wa}</Link> <br />
                          </li>
                        )
                    })}
                  </ul>
              </div>
            </div>
            </Grid>
            <Grid item xs={12} sm={7} md={6} lg={4} className="tcenter">
                <img src={logo} className="xxxxApp-logo" alt="logo foranemia" />
            </Grid>
        </Grid>
      </div>
        <Footer />
    </div>
  </div>
  );
}
