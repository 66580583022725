import React, { useEffect, useState, useCallback } from "react";
import log from 'loglevel';
import MyAxios, {check_response} from "./MyAxios";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { getOptionListElement, MostDatePicker, MostSubmitButton, MostAutocomplete, MostTextField, getOptionList, } from "./components/MostComponents";
import { string_dmy_hms, ymd, } from './Utils'
import { descriptionLangColumn, } from './UtilsInthem'
import { useParams, } from "react-router-dom";

export const NuovoPaziente = (props) => {

    const { wtapp } = useParams()
    let useAnagrafe = false
    if(wtapp.startsWith("wtfe"))
        useAnagrafe = true

    const { control, register, handleSubmit, errors, } = useForm();
    const optionsTipoRicerca = [
        {value:"equal", label:"cognome uguale a"},
        {value:"startsWith", label:"cognome inizia per"},
    ]
    const [tipoRicercaAnag, setTipoRicercaAnag] = useState(optionsTipoRicerca[0])
    const onChangeTipoRicercaAnag = (e,el) => {
        setTipoRicercaAnag(el)
    }
    const [gender, setGender] = useState(null)
    const onChangeGender = (e,el) => {
        setGender(el)
    }
    const [ricercanome, setRicercanome] = useState('')
    const onChangeRicercanome = (e) => {
        setRicercanome(e.target.value)
    }
    const [inputValue, setInputValue] = useState('')
    const [acLoading, setAcLoading] = useState(false)
    const [anagrafeOptions, setAnagrafeOptions] = useState([]);
    const [anagRow, setAnagRow] = useState(null);
    const [anagSurname, setAnagSurname] = useState('');
    const [anagName, setAnagName] = useState('');
    const [anagBirth, setAnagBirth] = useState('');
    const [disabledButs, setDisabledButs] = useState(false)
    const [nascita, setNascita] = useState(null)
    const [options_diagnosis, setOptions_diagnosis] = useState([])
    const handleNascitaChange = (date) => {
            setNascita(date)
    }
    const { t, i18n } = useTranslation();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    const options_gender = [
        {value: "F", label: t("Femmina")},
        {value: "M", label: t("Maschio")},
        {value: "UN", label: t("Indefinito")},
    ]

    useEffect(() => {
        const formData = new FormData()
        formData.append('format', 'JSON')
        formData.append('table_name', 'supported_diagnosis')
        const url = "/admin/GetConfTable"
        log.debug(url, [...formData.entries()])
        MyAxios.post(url, formData)
        .then((response) => {
          const res = check_response(response,1)
          if(res.success === false) {
            log.error(res.error)
            appAlert(res.error)
            return
          }
          const ol = getOptionList(res,'code',descriptionLangColumn(i18n.language)+'+disease_key')
          setOptions_diagnosis(ol)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,i18n.language])

    function loadFromAnagrafe() {
        if(!tipoRicercaAnag) {
            appAlert("Scegli il tipo di ricerca")
            return
        }
        let s = inputValue
        s=s.trim().toUpperCase()
        if(s.length < 2) {
            appAlert("Inserisci più caratteri del cognome")
            return
        }
        setAcLoading(true)
        const jdata = {
            surname: s,
            name: ricercanome.trim().toUpperCase()+'*',
            type: tipoRicercaAnag.value,
        }
        MyAxios.post("/anagrafe", jdata)
        .then((response) => {
            const res = check_response(response)
            if(res.success === false) {
                log.error(res.error)
                appAlert(res.error)
                return
            }
            const ol = []
            for (const el of res.rows) {
                ol.push({
                    value: el.id, 
                    label: el.surname+' '+el.name+' '+string_dmy_hms(el.birth,2)+' '+(el.fiscal_code ? el.fiscal_code:'')+' '+(el.webthal ? '*WebTHAL*':''),
                    row: el,
                })
            }
            setAnagrafeOptions(ol)
            if(ol.length >= 50) {
                appAlert("<b>ATTENZIONE</b> Sono stati individuati più di 50 pazienti.<br> Poiché vengono visualizzati solo i primi 50 pazienti, il paziente ricercato potrebbe non comparire nell'elenco. <br> In questo caso effettuare una ricerca più precisa: inserire il cognome completo o inserire più caratteri del cognome e/o inserire anche il nome o almeno la sua parte iniziale")
            }
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
        .then(function () {
            setAcLoading(false)
        })
    }

    const onFormSubmit = (vals) => {
        if(!useAnagrafe) {
            if(isNaN(nascita)) {
                appAlert(t("Data non valida"))
                return
            }
            if(nascita === null) {
                appAlert(t("Data di nascita")+": "+t("campo obbligatorio"))
                return
            }
        }
        if(!gender) {
            appAlert(t("Sesso")+": "+t("campo obbligatorio"))
            return
        }
        const jdata = {
            action: 'ADD',
            name: useAnagrafe ? anagName.toUpperCase() : vals.name.toUpperCase(),
            surname: useAnagrafe ? anagSurname.toUpperCase() : vals.surname.toUpperCase(),
            birth_date: useAnagrafe ? anagRow.birth : ymd(nascita),
            gender: gender.value,
            diagnosis: vals.diagnosis.value,
            anag_row: useAnagrafe ? JSON.stringify(anagRow) : null,
        }
        setDisabledButs(true)
        MyAxios.post("/change_patient", jdata)
        .then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            appAlert(res.error)
            return
          }
          log.debug("ok",res.identifier)
          props.onNewpatient(res.identifier)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
        .then(function () {
            setDisabledButs(false)
        })
    }

    return (
        <div className="wt3">
        <Container component="main" maxWidth="sm">
                { useAnagrafe ?
                    <div className="boxForm margintop30">
                        <Grid item xs={12}>
                            <div style={{fontSize: 12, fontStyle: 'italic', paddingBottom: 8}}>
                            <b style={{fontSize: 14}}>Ricerca paziente nell'anagrafe</b><br/>
                            1. (opzionale) Inserire il nome completo del paziente o inserire la parte iniziale del nome <br/>
                            2. Scegliere il tipo ricerca "cognome uguale a" e inserire il cognome completo <br/>
                            oppure scegliere il tipo ricerca "cognome inizia per" e inserire la parte iniziale del cognome (almeno 4 caratteri) <br/>
                            3. Premere INVIO nel campo Cognome <br/>
                            4. Scegliere il paziente dall'elenco visualizzato <br/>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <MostAutocomplete label="Tipo ricerca *"  options={optionsTipoRicerca} value={tipoRicercaAnag} onChange={onChangeTipoRicercaAnag} />
                        </Grid>
                        <Grid item xs={12}>
                            <MostTextField label={t("Nome")} value={ricercanome} onChange={onChangeRicercanome} />
                        </Grid>
                        <Grid item xs={12}>
                            <MostAutocomplete control={control} name="anagrafe" label="Cognome (e dati anagrafe) *" required={true} rules={{ required: true }}  defaultValue={null} errors={errors} 
                            disabled={acLoading}
                            onChange={(event, newValue) => {
                                //log.debug("change",newValue)
                                if(newValue) {
                                    setAnagRow(newValue.row)
                                    setAnagSurname(newValue.row.surname)
                                    setAnagName(newValue.row.name)
                                    setAnagBirth(string_dmy_hms(newValue.row.birth,2))
                                    setGender( getOptionListElement(options_gender,newValue.row.sex) )

                                } else {
                                    setAnagRow(null)
                                    setAnagSurname('')
                                    setAnagName('')
                                    setAnagBirth('')
                                    setGender(null)
                                }
                            }}
                            onKeyDown={ e => {
                                const { key } = e;
                                if (key === "Enter") {
                                    loadFromAnagrafe()
                                    e.preventDefault()
                                    e.stopPropagation()
                                }
                            }}
                            onInputChange={(event, newInputValue) => {
                                if(newInputValue.length < 2)
                                    setAnagrafeOptions([])
                                setInputValue(newInputValue)
                            }}
                            options={anagrafeOptions}
                            loading={acLoading}
                            />
                        </Grid>
                    </div>
                : null
                }
          <div className="boxForm margintop30">
                { useAnagrafe ?
                            <div style={{fontSize: 12, fontStyle: 'italic', paddingBottom: 8}}>
                            <b style={{fontSize: 14}}>Dati del paziente da inserire nel DB</b>
                            </div>
                : null }
              <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
              <Grid container spacing={1} alignItems="center">
                { useAnagrafe ?
                    <>
                        <Grid item xs={12}>
                            <MostTextField label={t("Cognome")} value={anagSurname} />
                        </Grid>
                        <Grid item xs={12}>
                            <MostTextField label={t("Nome")} value={anagName} />
                        </Grid>
                        <Grid item xs={12}>
                            <MostTextField label={t("Data di nascita")} value={anagBirth} />
                        </Grid>
                        <Grid item xs={12}>
                            <MostTextField label={t("Codice fiscale")} value={anagRow ? anagRow.fiscal_code : ''} />
                        </Grid>
                    </>
                :
                    <>
                        <Grid item xs={12}>
                            <MostTextField name="surname" required={true} label={t("Cognome")} register={register({ required: true })} errors={errors} />
                        </Grid>
                        <Grid item xs={12}>
                            <MostTextField name="name" required={true} label={t("Nome")} register={register({ required: true })} errors={errors} />
                        </Grid>
                        <Grid item xs={12}>
                            <MostDatePicker label={t("Data di nascita")} value={nascita} onChange={handleNascitaChange} />
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <MostAutocomplete name="gender" options={options_gender} label={t("Sesso")+" *"} value={gender} onChange={onChangeGender} />
                </Grid>
                <Grid item xs={12}>
                    <MostAutocomplete control={control} name="diagnosis" options={options_diagnosis} label={t("Diagnosi")+" *"} required={true} rules={{ required: true }}  defaultValue={null} errors={errors} />
                </Grid>
                <Grid item xs={12}>
                    <MostSubmitButton id="submit" disabled={disabledButs} label={t("Inserisci")} color="secondary" className="font12" />
                </Grid>
              </Grid>
              </form>
          </div>
        </Container>
        </div>
    )
}
