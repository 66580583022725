import React, { useState, useEffect, }  from "react";
import log from 'loglevel';
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { getWtappFromLocation, MostTextField, } from "./components/MostComponents";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios, {check_response} from "./MyAxios";

// https://codesandbox.io/s/inspiring-edison-zifyw?file=/src/components/Home.js:3485-3515
// https://github.com/andregardi/use-global-hook

/*
// Per definire appAlert in un modulo
import { useGlobalHook } from '@devhammed/use-global-hook'
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  function appAlert(text) {
      setContent(text);
      setAlert1(true);
  }

// Per definire appAlert2 in un modulo
import { useGlobalHook } from '@devhammed/use-global-hook'
  const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
  function appAlert2(text,closeHandler) {
    setContent2(text)
    setOnClose2(() => x => {
      closeHandler()
    });
    setAlert2(true)
  }

// Per definire appConfirm in un modulo
import { useGlobalHook } from '@devhammed/use-global-hook'
  const { setConfirm1, setCContent, setOnConfirm } = useGlobalHook('confirmStore');
  function appConfirm(text,okHandler) {
      setCContent(text);
      setOnConfirm(() => x => {
        okHandler();
      });
      setConfirm1(true);
  }
*/

/*
// versione completa non funzionante per conflitti negli usi
//const { setAlert1, setTitle, setContent, setCancelText, setConfirmText, setOnConfirm, onCancel, setOnCancel } = useGlobalHook('alertStore');
      // setTitle("title");
      // setCancelText("Annulla");
      // setConfirmText("OK");
      //setOnConfirm(() => x => {
        //alert('ok');
      //});
      //setOnCancel(() => x => {
        //alert('cancel');
      //});
  }
*/

export default function AlertDialog(props) {
  const { t } = useTranslation();
  const [passwd, setPasswd] = useState("")
  const [errorText, setErrorText] = useState(null)
  const { setUserInfo } = useGlobalHook('userStore')

  // alert senza handler
  const { alert1, setAlert1, content } = useGlobalHook('alertStore');
  // alert con handler su evento di close
  const { alert2, setAlert2, content2, onClose2 } = useGlobalHook('alert2Store');
  // confirm con handler su evento di click sul bottone di conferma
  const { confirm1, setConfirm1, cContent, onConfirm } = useGlobalHook('confirmStore');

  const { login1, setLogin1, lUsername, llang, llocale, } = useGlobalHook('loginStore');

  useEffect(() => {
        setErrorText(null)
  }, [])

  if (props.name === "alert") 
      return (
        <Dialog
          open={alert1}
          onClose={() => setAlert1(false)}
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{__html: content}} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
                setAlert1(false);
            }} color="primary" variant="contained" autoFocus>
              {t("OK")}
            </Button>
          </DialogActions>
        </Dialog>
      )
  else if (props.name === "alert2") 
      return (
        <Dialog
          open={alert2}
          onClose={() => {
            setAlert2(false)
            onClose2()
          }}
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{__html: content2}} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
                setAlert2(false)
                onClose2()
            }} color="primary" variant="contained" autoFocus>
              {t("OK")}
            </Button>
          </DialogActions>
        </Dialog>
      );
  else if (props.name === "confirm") 
      return (
        <Dialog
          open={confirm1}
          onClose={() => setConfirm1(false)}
          aria-describedby="confirm-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="confirm-dialog-description" dangerouslySetInnerHTML={{__html: cContent}} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
                setConfirm1(false);
            }} color="secondary" variant="outlined">
                {t("Annulla")}
            </Button>
            <Button onClick={() => {
                setConfirm1(false);
                onConfirm();
            }} color="primary" variant="contained" autoFocus>
                {t("OK")}
            </Button>
          </DialogActions>
        </Dialog>
      );
  else if (props.name === "login")  {
    function onLogin() {
        if(!passwd || passwd==="") {
            setErrorText("Inserisci la password")
            return
        }
        const wtapp = getWtappFromLocation()
        const data = {
            username: lUsername,
            password: passwd,
            wtapp: wtapp,
            lang: llang,
            locale: llocale,
        }
        MyAxios.post("login", data)
        .then((response) => {
            const res = check_response(response)
            if (res.success) {
                props.resetIdleTimeout()
                setUserInfo(res.user)
                setErrorText(null)
                setLogin1(false)
            } else {
                log.debug("Error: login: "+res.error);
                setErrorText(t("Accesso fallito")+": "+res.error)
            }
        })
        .catch(function (error) {
            // handle error
            log.error(error)
            setErrorText(error.message?error.message:JSON.stringify(error))
        })
    }
    const onChangePasswd = (e) => {
        setPasswd(e.target.value)
    }

    return (
        <Dialog
          open={login1}
        >
          <DialogContent>
            <div>
                <MostTextField disabled label={t("Username")} name="username" value={lUsername} />
                <MostTextField label={t("Password")} type="password" value={passwd} onChange={onChangePasswd}
                    inputProps={{ autoComplete: 'new-password', form: { autoComplete: 'off' } }}
                />
            </div>
            <DialogContentText className="red">
                {errorText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
                onLogin();
            }} color="primary" variant="contained" autoFocus>
                {t("OK")}
            </Button>
          </DialogActions>
        </Dialog>
    )
  } else
      return null;
      /*
        <Dialog
          open={alert1}
          onClose={() => setAlert1(false)}
          aria-labelledby="x-alert-dialog-title"
          aria-describedby="x-alert-dialog-description"
        >
          <DialogTitle id="x-alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="x-alert-dialog-description">
              {content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            { cancelText==="" ?
                null :
                <Button onClick={() => {
                    setAlert1(false);
                    if(onCancel)
                        onCancel();
                }} color="primary">{cancelText}</Button>
            }
            <Button onClick={() => {
                setAlert1(false);
                if(onConfirm)
                    onConfirm();
            }} color="primary" autoFocus>
              {confirmText}
            </Button>
          </DialogActions>
        </Dialog>
      );
      */
}

