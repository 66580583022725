import React, { useEffect, useState, useCallback } from "react";
import log from 'loglevel';
/*
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
*/
import { toInt, toFloat, string_dmy_hms, dmy_hms, ymd, } from "./Utils";
import MyAxios, {check_response} from "./MyAxios";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { notSavedProps, readOnlyProps, getOptionListElement, getOptionList, Loading, MostAutocomplete, MostTextField, } from "./components/MostComponents";

const today = ymd(new Date())

const options_somministrazione = [
        {value: "PO", label: "PO"},
        {value: "SC", label: "SC"},
        {value: "IV", label: "IV"},
        {value: "IM", label: "IM"},
        {value: "Bolo", label: "Bolo"},
    ]

export const TrattamentiSpeciali = (props) => {
    const { register, handleSubmit, errors, } = useForm()
    const [showButtonLoadLastValues, setShowButtonLoadLastValues] = useState(false)
    const [disabledButs, setDisabledButs] = useState(false)
    const [loading, setLoading] = React.useState(true)
    const [options_farmaci, setOptions_farmaci] = React.useState([])
    const [notsaved, setNotsaved] = useState(false)

    const [idro_program_ori, setIdro_program_ori] = React.useState(null)
    const [lus_program_ori, setLus_program_ori] = React.useState(null)
    const [special1_program_ori, setSpecial1_program_ori] = React.useState(null)
    const [idro_program, setIdro_program] = React.useState(null)
    const [lus_program, setLus_program] = React.useState(null)
    const [special1_program, setSpecial1_program] = React.useState(null)
    const [last, setLast] = React.useState(null)

    const [peso, setPeso] = React.useState('')
    const [lus_dose_kg, setLus_dose_kg] = React.useState('')
    const [lus_dose, setLus_dose] = React.useState('')
    const [lus_site, setLus_site] = React.useState(null)
    const [lus_site2, setLus_site2] = React.useState(null)
    const [lus_site3, setLus_site3] = React.useState(null)
    const [lus_interval, setLus_interval] = React.useState('')
    const [lus_assunzioni_dal, setLus_assunzioni_dal] = React.useState('')
    const [lus_assunzioni_giorni, setLus_assunzioni_giorni] = React.useState('')

    const [idro_dose_kg, setIdro_dose_kg] = React.useState('')
    const [idro_dose, setIdro_dose] = React.useState('')
    const [idro_required_daily, setIdro_required_daily] = React.useState('')
    const [idro_required_weekly, setIdro_required_weekly] = React.useState('')
    const [idro_declared_delivery_count, setIdro_declared_delivery_count] = React.useState('')
    const [idro_compliance, setIdro_compliance] = React.useState('')
    const [idro_assunzioni_attese, setIdro_assunzioni_attese] = React.useState('')
    const [idro_assunzioni_dal, setIdro_assunzioni_dal] = React.useState('')
    const [idro_assunzioni_giorni, setIdro_assunzioni_giorni] = React.useState('')

    const [drug1, setDrug1] = React.useState(null)
    const [drug1_dose_kg, setDrug1_dose_kg] = React.useState('')
    const [drug1_dose, setDrug1_dose] = React.useState('')
    const [drug1_administration, setDrug1_administration] = React.useState(null)
    const [drug1_site, setDrug1_site] = React.useState(null)
    const [drug1_posology, setDrug1_posology] = React.useState(null)
    const [drug1_required_daily, setDrug1_required_daily] = React.useState('')
    const [drug1_required_weekly, setDrug1_required_weekly] = React.useState('')
    const [drug1_interval, setDrug1_interval] = React.useState('')
    const [drug1_declared_delivery_count, setDrug1_declared_delivery_count] = React.useState('')
    const [drug1_compliance, setDrug1_compliance] = React.useState('')
    const [drug1_assunzioni_attese, setDrug1_assunzioni_attese] = React.useState('')
    const [drug1_assunzioni_dal, setDrug1_assunzioni_dal] = React.useState('')
    const [drug1_assunzioni_giorni, setDrug1_assunzioni_giorni] = React.useState('')

    const { t, } = useTranslation();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    // somministrazione: PO bocca, SC sottocutanea, IV endovena, IM intramuscolo, Bolo
    // sede infusione (SC)
    const [options_sede_infusione] = React.useState([
        {value: "braccio_sx", label: t("braccio_sx")},
        {value: "braccio_dx", label: t("braccio_dx")},
        {value: "gamba_sx", label: t("gamba_sx")},
        {value: "gamba_dx", label: t("gamba_dx")},
        {value: "addome", label: t("addome")},
        {value: "altro", label: t("altro")},
    ])
    const [options_posologia] = React.useState([
        {value: "daily", label: t("daily")},
        {value: "periodic", label: t("periodic")},
    ])

    const handleDrug1Change = (e,el) => {
        setDrug1(el)
        if(el) {
            setDrug1_administration( getOptionListElement(options_somministrazione,el.row.administration) )
            setDrug1_posology( getOptionListElement(options_posologia,el.row.posology) )
        } else {
            setDrug1_administration(null)
            setDrug1_posology(null)
        }
    }
    const handleDrug1_administrationChange = (e,el) => {
        setDrug1_administration(el)
    }
    const handleDrug1_posologyChange = (e,el) => {
        setDrug1_posology(el)
    }

    useEffect(() => {
        let days
        let assunzioni_attese
        const jdata = {
            action: 'LIST',
            patient_id: props.paziente.value,
            ha_date: props.entryDate,
        }
        MyAxios.post("/special_treatment", jdata)
        .then((response) => {
          const res = check_response(response,1)
          if(res.success === false) {
            log.error(res.error)
            appAlert(res.error)
            return
          }

          const options_farmaci = getOptionList(res.special_drugs,'code','active_principle')
          setOptions_farmaci(options_farmaci)

          setIdro_program_ori(res.patient_data.idro_program)
          setLus_program_ori(res.patient_data.lus_program)
          setSpecial1_program_ori(res.patient_data.special1_program)
          setIdro_program(res.patient_data.idro_program)
          setLus_program(res.patient_data.lus_program)
          setSpecial1_program(res.patient_data.special1_program)
          if(res.last_weight)
            setPeso(''+res.last_weight)
          else
            setPeso('')

          const current = res.current.ha_special_therapy
          const current_entry_date = res.current.entry_date
          const last = res.last ? res.last.ha_special_therapy : null
          setLast(last)
          const last_entry_date = last ? res.last.entry_date : null

          let is_new_record = true
          if (current.lus_dose_kg || current.lus_dose || current.lus_site || current.lus_site2 || current.lus_site3 || current.lus_interval || current.idro_dose || current.idro_required_daily || current.idro_required_weekly || current.idro_declared_delivery_count || current.idro_compliance || current.drug1_code || current.drug1_dose_kg || current.drug1_dose || current.drug1_administration || current.drug1_site || current.drug1_posology || current.drug1_required_daily || current.drug1_required_weekly || current.drug1_interval || current.drug1_declared_delivery_count || current.drug1_compliance)
            is_new_record = false

          if (is_new_record && last && current_entry_date === today && (last.lus_dose_kg || last.idro_dose || last.drug1_dose_kg || last.drug1_dose)) {
            // gestione default da record precedente
            // solo se record nuovo ed esiste record precedente e se si lavora sul giorno corrente e se ci sono dei valori nel record precedente
            setShowButtonLoadLastValues(true)
          }

            if (current.lus_dose_kg) setLus_dose_kg(''+current.lus_dose_kg)
            else setLus_dose_kg('')
            if (current.lus_dose) setLus_dose(''+current.lus_dose)
            else setLus_dose('')
            if (current.lus_site)
                setLus_site( getOptionListElement(options_sede_infusione,current.lus_site) )
            else
                setLus_site(null)
            if (current.lus_site2)
                setLus_site2( getOptionListElement(options_sede_infusione,current.lus_site2) )
            else
                setLus_site2(null)
            if (current.lus_site3)
                setLus_site3( getOptionListElement(options_sede_infusione,current.lus_site3) )
            else
                setLus_site3(null)
            if (current.lus_interval) setLus_interval(''+current.lus_interval)
            else setLus_interval('21')

            if (current.idro_dose) {
                setIdro_dose(''+current.idro_dose)
                if(res.last_weight) {
                    const dk = Math.round(current.idro_dose/res.last_weight*100)/100
                    setIdro_dose_kg(''+dk)
                } else {
                    setIdro_dose_kg('') 
                }
            } else {
                setIdro_dose('') 
                setIdro_dose_kg('') 
            }
             
            if (current.idro_required_daily) setIdro_required_daily(''+current.idro_required_daily)
            else setIdro_required_daily('')
            if (current.idro_required_weekly) setIdro_required_weekly(''+current.idro_required_weekly)
            else setIdro_required_weekly('')

            if (current.drug1_code)
                setDrug1( getOptionListElement(options_farmaci,current.drug1_code) )
            else
                setDrug1(null)
            if (current.drug1_dose_kg) setDrug1_dose_kg(''+current.drug1_dose_kg)
            else setDrug1_dose_kg('')
            if (current.drug1_dose) setDrug1_dose(''+current.drug1_dose)
            else setDrug1_dose('')
            if (current.drug1_administration)
                setDrug1_administration( getOptionListElement(options_somministrazione,current.drug1_administration) )
            else
                setDrug1_administration(null)
            if (current.drug1_site)
                setDrug1_site( getOptionListElement(options_sede_infusione,current.drug1_site) )
            else
                setDrug1_site(null)
            if (current.drug1_posology)
                setDrug1_posology( getOptionListElement(options_posologia,current.drug1_posology) )
            else
                setDrug1_posology(null)
            if (current.drug1_required_daily) setDrug1_required_daily(''+current.drug1_required_daily)
            else setDrug1_required_daily('')
            if (current.drug1_required_weekly) setDrug1_required_weekly(''+current.drug1_required_weekly)
            else setDrug1_required_weekly('')
            if (current.drug1_interval) setDrug1_interval(''+current.drug1_interval)
            else setDrug1_interval('')


          if (last && last.lus_dose) {
            days = moment(current_entry_date).diff(moment(last_entry_date), 'days')
            setLus_assunzioni_dal(string_dmy_hms(last_entry_date,2))
            setLus_assunzioni_giorni(''+days)
          } else {
            setLus_assunzioni_dal('')
            setLus_assunzioni_giorni('')
          }

          if (current.idro_declared_delivery_count) setIdro_declared_delivery_count(''+current.idro_declared_delivery_count)
          else setIdro_declared_delivery_count('')
          if (current.idro_compliance !== null) setIdro_compliance(''+current.idro_compliance)
          else setIdro_compliance('')
          if (last && last.idro_dose) {
            days = moment(current_entry_date).diff(moment(last_entry_date), 'days')
            if(last.idro_required_weekly && last.idro_required_daily) {
                assunzioni_attese = Math.round(last.idro_required_weekly * last.idro_required_daily * days / 7)
                setIdro_assunzioni_attese(''+assunzioni_attese)
            } else
                setIdro_assunzioni_attese('')
            setIdro_assunzioni_dal(string_dmy_hms(last_entry_date,2))
            setIdro_assunzioni_giorni(''+days)
          } else {
            setIdro_assunzioni_attese('')
            setIdro_assunzioni_dal('')
            setIdro_assunzioni_giorni('')
          }

          if (current.drug1_declared_delivery_count) setDrug1_declared_delivery_count(''+current.drug1_declared_delivery_count)
          else setDrug1_declared_delivery_count('')
          if (current.drug1_compliance !== null) setDrug1_compliance(''+current.drug1_compliance)
          else setDrug1_compliance('')
          if (last && last.drug1_dose) {
              days = moment(current_entry_date).diff(moment(last_entry_date), 'days')
              assunzioni_attese = null
              if(last.drug1_posology === "daily") {
                  if(last.drug1_required_weekly && last.drug1_required_daily) {
                      assunzioni_attese = Math.round(last.drug1_required_weekly * last.drug1_required_daily * days / 7)
                  }
              } else {
                  if (last.drug1_interval)
                      assunzioni_attese = Math.floor(days/last.drug1_interval)
              }
              if(assunzioni_attese !== null)
                  setDrug1_assunzioni_attese(''+assunzioni_attese)
              else
                  setDrug1_assunzioni_attese('')
              setDrug1_assunzioni_dal(string_dmy_hms(last_entry_date,2))
              setDrug1_assunzioni_giorni(''+days)
          } else {
              setDrug1_assunzioni_attese('')
              setDrug1_assunzioni_dal('')
              setDrug1_assunzioni_giorni('')
          }

          setLoading(false)
        })
        .catch(function (error) {
                log.error(error);
                appAlert(error.message?error.message:JSON.stringify(error));
                })
    }, [appAlert,props.paziente.value,props.entryDate,options_sede_infusione,options_posologia,])

/*
    function mToast(msg) {
        toast.success(msg, {
            position: "bottom-right",
            autoClose: 3000,
        })
    }
*/

function getDrug1_administration() {
    if(!drug1)
        return null
    if(drug1.row.administration)
        return drug1.row.administration
    if(!drug1_administration)
        return null
    return drug1_administration.value
}

function getDrug1_posology() {
    if(!drug1)
        return null
    if(drug1.row.posology)
        return drug1.row.posology
    if(!drug1_posology)
        return null
    return drug1_posology.value
}

const recalcAll = (ipeso,ilusdosekg,iidrodose,idrug1dosekg,idrug1dose,idrug1posology) => {
    setNotsaved(true)
    let peso2post
    let lus_dose2post = null
    let lus_dose_kg2post = null
    let idro_dose2post = null
    let idro_dosekg2post = null
    let drug1_dose2post = null
    let drug1_dosekg2post = null
    let posology = null
    if(ipeso !== undefined)
        peso2post = toFloat(ipeso)
    else
        peso2post = peso
    if(ilusdosekg !== undefined)
        lus_dose_kg2post = toFloat(ilusdosekg)
    else
        lus_dose_kg2post = lus_dose_kg
    if(iidrodose !== undefined)
        idro_dose2post = toFloat(iidrodose)
    else
        idro_dose2post = idro_dose
    if(idrug1posology !== undefined)
        posology = idrug1posology
    else
        posology = getDrug1_posology()
    if(posology==="periodic") {
        if(idrug1dosekg !== undefined)
            drug1_dosekg2post = toFloat(idrug1dosekg)
        else
            drug1_dosekg2post = drug1_dose_kg
    } else {
        if(idrug1dose !== undefined)
            drug1_dose2post = toFloat(idrug1dose)
        else
            drug1_dose2post = drug1_dose
    }
    if (peso2post) {
        lus_dose2post = calc_dose(peso2post,lus_dose_kg2post)
        idro_dosekg2post = calc_dosekg(peso2post,idro_dose2post)
        if(posology==="periodic")
            drug1_dose2post = calc_dose(peso2post,drug1_dosekg2post)
        else if(posology==="daily")
            drug1_dosekg2post = calc_dosekg(peso2post,drug1_dose2post)
    }
    setLus_dose(lus_dose2post ? ''+lus_dose2post : '')
    setIdro_dose_kg(idro_dosekg2post ? ''+idro_dosekg2post : '')
    setDrug1_dose_kg(drug1_dosekg2post ? ''+drug1_dosekg2post : '')
    setDrug1_dose(drug1_dose2post ? ''+drug1_dose2post : '')
}

const onChangePeso = (e) => {
    setPeso(e.target.value)
    recalcAll(e.target.value)
}
const onChangeLusdosekg = (e) => {
    setLus_dose_kg(e.target.value)
    recalcAll(undefined,e.target.value)
}
const onChangeLus_site = (e,el) => {
    setLus_site(el)
}
const onChangeLus_site2 = (e,el) => {
    setLus_site2(el)
}
const onChangeLus_site3 = (e,el) => {
    setLus_site3(el)
}
const onChangeLus_interval = (e) => {
    setLus_interval(e.target.value)
}
const onChangeIdro_dose = (e) => {
    setIdro_dose(e.target.value)
    recalcAll(undefined,undefined,e.target.value)
}
const onChangeIdro_required_daily = (e) => {
    setIdro_required_daily(e.target.value)
}
const onChangeIdro_required_weekly = (e) => {
    setIdro_required_weekly(e.target.value)
}
const onChangeIdro_declared_delivery_count = (e) => {
    setIdro_declared_delivery_count(e.target.value)
}
const onChangeDrug1dosekg = (e) => {
    setDrug1_dose_kg(e.target.value)
    recalcAll(undefined,undefined,undefined,e.target.value)
}
const onChangeDrug1_dose = (e) => {
    setDrug1_dose(e.target.value)
    recalcAll(undefined,undefined,undefined,undefined,e.target.value)
}
const onChangeDrug1_site = (e,el) => {
    setDrug1_site(el)
}
const onChangeDrug1_required_daily = (e) => {
    setDrug1_required_daily(e.target.value)
}
const onChangeDrug1_required_weekly = (e) => {
    setDrug1_required_weekly(e.target.value)
}
const onChangeDrug1_interval = (e) => {
    setDrug1_interval(e.target.value)
}
const onChangeDrug1_declared_delivery_count = (e) => {
    setDrug1_declared_delivery_count(e.target.value)
}

const loadLastValues = () => {

    let ilusdosekg = undefined
    let iidrodose = undefined
    let idrug1dosekg = undefined
    let idrug1dose = undefined
    let idrug1posology = undefined
    if(last.lus_dose_kg) {
        ilusdosekg = ''+last.lus_dose_kg
        setLus_dose_kg(ilusdosekg)
    } else {
        setLus_dose_kg('')
    } 
    if(last.lus_site)
        setLus_site( getOptionListElement(options_sede_infusione,last.lus_site) )
    else
        setLus_site(null)
    if(last.lus_site2)
        setLus_site2( getOptionListElement(options_sede_infusione,last.lus_site2) )
    else
        setLus_site2(null)
    if(last.lus_site3)
        setLus_site3( getOptionListElement(options_sede_infusione,last.lus_site3) )
    else
        setLus_site3(null)
    if(last.lus_interval) setLus_interval(''+last.lus_interval)
    else setLus_interval('21')

    if(last.idro_dose) {
        iidrodose = ''+last.idro_dose
        setIdro_dose(iidrodose)
    } else {
        setIdro_dose('')
    }
    if(last.idro_required_daily) setIdro_required_daily(''+last.idro_required_daily)
    else setIdro_required_daily('')
    if(last.idro_required_weekly) setIdro_required_weekly(''+last.idro_required_weekly)
    else setIdro_required_weekly('')

    if(last.drug1_code)
        setDrug1( getOptionListElement(options_farmaci,last.drug1_code) )
    else
        setDrug1(null)
    if(last.drug1_posology === "periodic") {
        if(last.drug1_dose_kg) {
            idrug1dosekg = ''+last.drug1_dose_kg
            setDrug1_dose_kg(idrug1dosekg)
        } else {
            setDrug1_dose_kg('')
        }
    } else {
        if(last.drug1_dose) {
            idrug1dose = ''+last.drug1_dose
            setDrug1_dose(idrug1dose)
        } else {
            setDrug1_dose_kg('')
        }
    }
    if(last.drug1_administration)
        setDrug1_administration( getOptionListElement(options_somministrazione,last.drug1_administration) )
    else
        setDrug1_administration(null)
    if(last.drug1_site)
        setDrug1_site( getOptionListElement(options_sede_infusione,last.drug1_site) )
    else
        setDrug1_site(null)
    if(last.drug1_posology) {
        idrug1posology = getOptionListElement(options_posologia,last.drug1_posology)
        setDrug1_posology(idrug1posology)
        idrug1posology = idrug1posology.value
    } else
        setDrug1_posology(null)
    if(last.drug1_required_daily) setDrug1_required_daily(''+last.drug1_required_daily)
    else setDrug1_required_daily('')
    if(last.drug1_required_weekly) setDrug1_required_weekly(''+last.drug1_required_weekly)
    else setDrug1_required_weekly('')
    if(last.drug1_interval) setDrug1_interval(''+last.drug1_interval)
    else setDrug1_interval('')
    recalcAll(undefined,ilusdosekg,iidrodose,idrug1dosekg,idrug1dose,idrug1posology)
}

const calc_dosekg = (peso,dose) => {
    let d = dose / peso
    if(d) {
        d = Math.round(d*100)/100
        return d
    }
    return null
}

const calc_dose = (peso,dose_kg) => {
    let d = peso * dose_kg
    if(d) {
        d = Math.round(d*100)/100
        return d
    }
    return null
}

const onFormSubmit = (vals) => {
    log.debug("onFormSubmit",vals)
    setDisabledButs(true)
    // compliance
    let assunzioni_attese

    const peso2post = toFloat(peso)
    if(!peso2post) {
        appAlert("ERRORE: modifica di un accesso in cui non è noto il peso")
        return
    }
    const lus_dose_kg2post = toFloat(lus_dose_kg)
    const lus_dose2post = calc_dose(peso2post,lus_dose_kg2post)

    let idro_compliance = null
    let int_idro_declared_delivery_count = toInt(idro_declared_delivery_count)
    assunzioni_attese = toInt(idro_assunzioni_attese)
    if(assunzioni_attese && int_idro_declared_delivery_count !== null) {
        idro_compliance = Math.round(int_idro_declared_delivery_count / assunzioni_attese * 1000) / 10
        if(idro_compliance > 100)
            idro_compliance = 100
    }

    let drug1_dose_kg2post = null
    let drug1_dose2post = null
    let drug1_compliance = null
    if(drug1) {
        if(getDrug1_posology() === "daily") {
            if(true) {
                appAlert("tbd : per ora non esiste farmaco")
                return
            }
            let int_drug1_declared_delivery_count = toInt(drug1_declared_delivery_count)
            assunzioni_attese = toInt(drug1_assunzioni_attese)
            if(assunzioni_attese && int_drug1_declared_delivery_count !== null) {
                drug1_compliance = Math.round(int_drug1_declared_delivery_count / assunzioni_attese * 1000) / 10
                if(drug1_compliance > 100)
                    drug1_compliance = 100
            }
        } else {
            // come lus
            drug1_dose_kg2post = toFloat(drug1_dose_kg)
            drug1_dose2post = calc_dose(peso2post,drug1_dose_kg2post)
        }
    }
    const jdata = {
            action: 'CHANGE',
            patient_id: props.paziente.value,
            ha_date: props.entryDate,
            weight: peso2post,

            lus_dose_kg: lus_dose_kg2post,
            lus_dose: lus_dose2post,
            lus_site: lus_site ? lus_site.value : null,
            lus_site2: lus_site2 ? lus_site2.value : null,
            lus_site3: lus_site3 ? lus_site3.value : null,
            lus_interval: toInt(lus_interval),

            idro_dose: toFloat(idro_dose),
            idro_required_daily: toInt(idro_required_daily),
            idro_required_weekly: toInt(idro_required_weekly),
            idro_declared_delivery_count: toInt(idro_declared_delivery_count),
            idro_compliance: idro_compliance,

            drug1_code: drug1 ? drug1.value : null,
            drug1_dose_kg: drug1_dose_kg2post,
            drug1_dose: drug1_dose2post,
            drug1_administration: getDrug1_administration(),
            drug1_site: drug1 && drug1_site ? drug1_site.value : null,
            drug1_posology: getDrug1_posology(),
        }
        if(drug1) {
            if(getDrug1_posology()==="periodic") {
                jdata.drug1_interval = toInt(drug1_interval)
            }
            if(getDrug1_posology()==="daily") {
                jdata.drug1_required_daily = toInt(drug1_required_daily)
                jdata.drug1_required_weekly = toInt(drug1_required_weekly)
                jdata.drug1_declared_delivery_count = toInt(drug1_declared_delivery_count)
                jdata.drug1_compliance = drug1_compliance
            }
        }
        if(vals.lus_program !== lus_program_ori) {
            jdata.lus_program = vals.lus_program
            jdata.lus_program_ori = lus_program_ori
        }
        if(vals.idro_program !== idro_program_ori) {
            jdata.idro_program = vals.idro_program
            jdata.idro_program_ori = idro_program_ori
        }
        if(vals.special1_program !== special1_program_ori) {
            jdata.special1_program = vals.special1_program
            jdata.special1_program_ori = special1_program_ori
        }

        MyAxios.post("/special_treatment", jdata)
        .then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            appAlert(res.error)
            return
          }
          // necessario per eseguire then finale senza problemi
          setTimeout(()=>{
            props.forceReloadTrattamentiSpeciali()
          },200)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
        .then(function () {
            setDisabledButs(false)
        })
    }

    /*
    const Riquadro = (props) => {
        return (
            <div style={{margin:'10px 10px 0 0',border: '6px #C0D0F0 solid'}}>
                <div style={{backgroundColor: '#C0D0F0',padding:'0 0 6px 0'}}>
                    <div style={{display:'inline-block', color:'#40428B',padding:'0 4px',fontSize:'12px',fontWeight:'bold', backgroundColor:'white'}}>
                        {props.label}
                    </div>
                </div>
                <div style={{padding:'10px',backgroundColor:'white'}}>
                      <Grid container spacing={1} alignItems="flex-start">
                        {props.children}
                      </Grid>
                </div>
            </div>
        )
    }
    */

    if(loading)
        return <Loading />

    // xs, sm, md, lg, xl : 576px, 768px, 992px, 1280px, 1600?
    // 450 riquadro
    // xs 1, md 2, lg 3

    // solo se siamo su accesso del giorno corrente
    let lus_prossima_somministrazione = ''
    if(lus_dose_kg!=='' && lus_interval!=='')
        lus_prossima_somministrazione = dmy_hms(moment().add(lus_interval,'days').toDate(),2)

    let idro_declared_delivery_count_disabled = true
    if (idro_assunzioni_attese !== '')
        idro_declared_delivery_count_disabled = false

    let drug1_declared_delivery_count_disabled = true
    if (drug1_assunzioni_attese !== '')
        drug1_declared_delivery_count_disabled = false
    // solo se siamo su accesso del giorno corrente
    let drug1_prossima_somministrazione = ''
    if(drug1_dose_kg!=='' && drug1_interval!=='')
        drug1_prossima_somministrazione = dmy_hms(moment().add(drug1_interval,'days').toDate(),2)

    return (
      <div>
        <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
        <div className="accessoToolbar tcenter uhomeTitle">
             { showButtonLoadLastValues ?
                <Button type="button" variant="outlined" color="secondary" className="font12" size="small" onClick={loadLastValues} >
                    {t("Carica i valori dell'accesso precedente")}
                </Button>
            : null }
            <Button disabled={disabledButs} onClick={props.forceReloadTrattamentiSpeciali} type="button" variant="outlined" color="secondary" className="font12" size="small" >
                {t("Annulla le modifiche")}
            </Button>
            <Button disabled={disabledButs} type="submit" variant="contained" color="secondary" className="font12" size="small" >
                {t("Salva le modifiche")}
            </Button>
        </div>
        <div className="wt3">
        <Container component="main" maxWidth="xl">
              <Grid container spacing={1} alignItems="flex-start">
{/* programmi */}
                <Grid item xs={12} md={6} lg={4} >
                    <div style={{margin:'10px 10px 0 0',border: '6px #C0D0F0 solid'}}>
                        <div style={{padding:'10px',backgroundColor:'white'}}>
                            <Grid container spacing={1} alignItems="flex-start">
                                <MostTextField name="peso" required inputProps={{type:'number'}} value={peso} onChange={onChangePeso} label={t("Peso")+" (kg)"+ (props.entryDate !== today ? (" "+t("al")+" "+string_dmy_hms(props.entryDate,2)) : "")} register={register({ required: true })} errors={errors} />
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={8} >
                </Grid>
                <Grid item xs={12} md={6} lg={4} >
                    <div style={{margin:'10px 10px 0 0',border: '6px #C0D0F0 solid'}}>
                        <div style={{backgroundColor: '#C0D0F0',padding:'0 0 6px 0'}}>
                            <div style={{display:'inline-block', color:'#40428B',padding:'0 4px',fontSize:'12px',fontWeight:'bold', backgroundColor:'white'}}>
                                {t("Programma")+" Luspatercept"}
                            </div>
                        </div>
                        <div style={{padding:'10px',backgroundColor:'white'}}>
                              <Grid container spacing={1} alignItems="flex-start">
                                <MostTextField name="lus_program" defaultValue={lus_program} register={register} multiline={true} rows={5} inputProps={{ maxLength: 1998 }} />
                              </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4} >
                    <div style={{margin:'10px 10px 0 0',border: '6px #C0D0F0 solid'}}>
                        <div style={{backgroundColor: '#C0D0F0',padding:'0 0 6px 0'}}>
                            <div style={{display:'inline-block', color:'#40428B',padding:'0 4px',fontSize:'12px',fontWeight:'bold', backgroundColor:'white'}}>
                                {t("Programma")+" "+t("Idrossiurea")}
                            </div>
                        </div>
                        <div style={{padding:'10px',backgroundColor:'white'}}>
                              <Grid container spacing={1} alignItems="flex-start">
                                <MostTextField name="idro_program" defaultValue={idro_program} register={register} multiline={true} rows={5} inputProps={{ maxLength: 1998 }} />
                              </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4} >
                    <div style={{margin:'10px 10px 0 0',border: '6px #C0D0F0 solid'}}>
                        <div style={{backgroundColor: '#C0D0F0',padding:'0 0 6px 0'}}>
                            <div style={{display:'inline-block', color:'#40428B',padding:'0 4px',fontSize:'12px',fontWeight:'bold', backgroundColor:'white'}}>
                                {t("Programma altro Trattamento Speciale")}
                            </div>
                        </div>
                        <div style={{padding:'10px',backgroundColor:'white'}}>
                              <Grid container spacing={1} alignItems="flex-start">
                                <MostTextField name="special1_program" defaultValue={special1_program} register={register} multiline={true} rows={5} inputProps={{ maxLength: 1998 }} />
                              </Grid>
                        </div>
                    </div>
                </Grid>
{/* Luspatercept */}
                <Grid item xs={12} md={6} lg={4} >
                    <div style={{margin:'10px 10px 0 0',border: '6px #C0D0F0 solid'}}>
                        <div style={{backgroundColor: '#C0D0F0',padding:'0 0 6px 0'}}>
                            <div style={{display:'inline-block', color:'#40428B',padding:'0 4px',fontSize:'12px',fontWeight:'bold', backgroundColor:'white'}}>
                                Luspatercept
                            </div>
                        </div>
                        <div style={{padding:'10px',backgroundColor:'white'}}>
                              <Grid container spacing={1} alignItems="center">
                                <Grid item xs={6}>
                                    <MostTextField name="lus_dose" value={lus_dose} inputProps={notsaved ? notSavedProps: readOnlyProps} label={t("Dose totale")+" (mg)"} disabled={lus_dose?false:true} />
                                </Grid>
                                <Grid item xs={6}>
                                    <MostTextField name="lus_dose_kg" label={t("Dose/kg")+" (mg/kg)"} value={lus_dose_kg} onChange={onChangeLusdosekg} inputProps={{type:'number'}} />
                                </Grid>
                                    {/* somministrazione : sc */}
                                <Grid item xs={6}>
                                    <MostAutocomplete name="lus_site" options={options_sede_infusione} label={t("Sede infusione")+" 1"} value={lus_site} onChange={onChangeLus_site} />
                                </Grid>
                                <Grid item xs={6}>
                                    <MostAutocomplete name="lus_site2" options={options_sede_infusione} label={t("Sede infusione")+" 2"} value={lus_site2} onChange={onChangeLus_site2} />
                                </Grid>
                                <Grid item xs={6}>
                                    <MostAutocomplete name="lus_site3" options={options_sede_infusione} label={t("Sede infusione")+" 3"} value={lus_site3} onChange={onChangeLus_site3} />
                                </Grid>
                                <Grid item xs={6}>
                                </Grid>
                                <Grid item xs={12}>
                                    <MostTextField name="lus_interval" inputProps={{type:'number'}} value={lus_interval} onChange={onChangeLus_interval} label={t("Intervallo somministrazioni previsto (giorni)")} />
                                </Grid>
                                <Grid item xs={7}>
                                    <MostTextField name="x1" label={t("Ultima somministrazione")} value={lus_assunzioni_dal} inputProps={readOnlyProps} disabled={lus_assunzioni_dal?false:true} />
                                </Grid>
                                <Grid item xs={5}>
                                    <MostTextField name="x1" label={t("giorni trascorsi")} value={lus_assunzioni_giorni} inputProps={readOnlyProps} disabled={lus_assunzioni_giorni?false:true} />
                                </Grid>
                                { props.entryDate === today ?
                                    <Grid item xs={12}>
                                        <MostTextField name="x1" label={t("Data prevista prossima somministrazione")} value={lus_prossima_somministrazione} inputProps={readOnlyProps} disabled={lus_prossima_somministrazione?false:true} />
                                    </Grid>
                                : null }
                              </Grid>
                        </div>
                    </div>
                </Grid>
{/* Idrossiurea */}
                <Grid item xs={12} md={6} lg={4} >
                    <div style={{margin:'10px 10px 0 0',border: '6px #C0D0F0 solid'}}>
                        <div style={{backgroundColor: '#C0D0F0',padding:'0 0 6px 0'}}>
                            <div style={{display:'inline-block', color:'#40428B',padding:'0 4px',fontSize:'12px',fontWeight:'bold', backgroundColor:'white'}}>
                                {t("Idrossiurea")}
                            </div>
                        </div>
                        <div style={{padding:'10px',backgroundColor:'white'}}>
                              <Grid container spacing={1} alignItems="flex-start">
                                <Grid item xs={6}>
                                    <MostTextField name="idro_dose" inputProps={{type:'number'}} label={t("Dose totale")+" (mg/die)"} value={idro_dose} onChange={onChangeIdro_dose} />
                                </Grid>
                                <Grid item xs={6}>
                                    <MostTextField name="x1" label={t("Dose/kg")+" (mg/kg/die)"} value={idro_dose_kg} inputProps={notsaved ? notSavedProps: readOnlyProps} disabled={idro_dose_kg?false:true} />
                                </Grid>
                                    {/* somministrazione : po */}
                                <Grid item xs={12}>
                                    <MostTextField name="idro_required_daily" inputProps={{type:'number'}} label={t("Prescrizione (assunzioni al giorno)")} value={idro_required_daily} onChange={onChangeIdro_required_daily} />
                                </Grid>
                                <Grid item xs={12}>
                                    <MostTextField name="idro_required_weekly" inputProps={{type:'number'}} label={t("Prescrizione (giorni alla settimana)")} value={idro_required_weekly} onChange={onChangeIdro_required_weekly} />
                                </Grid>
                                <Grid item xs={5}>
                                    <MostTextField name="x1" label={t("Assunzioni attese")} value={idro_assunzioni_attese} inputProps={readOnlyProps} disabled={idro_assunzioni_attese?false:true} />
                                </Grid>
                                <Grid item xs={5}>
                                    <MostTextField name="x1" label={t("dal")} value={idro_assunzioni_dal} inputProps={readOnlyProps} disabled={idro_assunzioni_dal?false:true} />
                                </Grid>
                                <Grid item xs={2}>
                                    <MostTextField name="x1" label={t("giorni")} value={idro_assunzioni_giorni} inputProps={readOnlyProps} disabled={idro_assunzioni_giorni?false:true} />
                                </Grid>
                                <Grid item xs={6}>
                                    <MostTextField name="idro_declared_delivery_count" inputProps={{type:'number'}} disabled={idro_declared_delivery_count_disabled}  label={t("Assunzioni dichiarate")} value={idro_declared_delivery_count} onChange={onChangeIdro_declared_delivery_count} />
                                </Grid>
                                <Grid item xs={6}>
                                    <MostTextField name="x1" label="Compliance(%)" value={idro_compliance} inputProps={readOnlyProps} disabled={idro_compliance?false:true} />
                                </Grid>
                              </Grid>
                        </div>
                    </div>
                </Grid>
{/* Altro trattamento speciale */}
                <Grid item xs={12} md={6} lg={4} >
                    <div style={{margin:'10px 10px 0 0',border: '6px #C0D0F0 solid'}}>
                        <div style={{backgroundColor: '#C0D0F0',padding:'0 0 6px 0'}}>
                            <div style={{display:'inline-block', color:'#40428B',padding:'0 4px',fontSize:'12px',fontWeight:'bold', backgroundColor:'white'}}>
                                {t("Altro trattamento speciale")}
                            </div>
                        </div>
                        <div style={{padding:'10px',backgroundColor:'white'}}>
                              <Grid container spacing={1} alignItems="flex-start">
                                <Grid item xs={12} >
                                    {/* es Crizanlizumab IV periodic" */}
                                    <MostAutocomplete options={options_farmaci} label={t("Farmaco")} value={drug1} onChange={handleDrug1Change} />
                                </Grid>
                                { drug1 ?
                                    <React.Fragment>
                                    <Grid item xs={12} >
                                        { drug1.row.posology ? // Nota: label Frequenza ma nelle strutture dati posology (nome contestato da Longo)
                                            <MostTextField name="x1" label={t("Frequenza")} value={t(drug1.row.posology)} inputProps={readOnlyProps} />
                                        :
                                            <MostAutocomplete name="drug1_posology" options={options_posologia} label={t("Frequenza")} value={drug1_posology} onChange={handleDrug1_posologyChange} />
                                        }
                                    </Grid>
                                    {/* 
                                    posology periodic : dose_kg in input come lus
                                    posology daily : dose in input come idro 
                                    */}
                                    { getDrug1_posology() ?
                                        <React.Fragment>
                                        <Grid item xs={6} >
                                            <MostTextField name="drug1_dose" 
                                            value={drug1_dose} 
                                            onChange={onChangeDrug1_dose}
                                            label={t("Dose totale")+(getDrug1_posology()==="periodic"? " (mg)" : " (mg/die)")}
                                            inputProps={getDrug1_posology()==="periodic"? (notsaved ? notSavedProps: readOnlyProps) : {type:'number'}}
                                            disabled={getDrug1_posology()==="periodic"? (drug1_dose?false:true) : false}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <MostTextField name="drug1_dose_kg" 
                                            value={drug1_dose_kg} 
                                            label={t("Dose/kg")+(getDrug1_posology()==="periodic"? " (mg/kg)" : " (mg/kg/die)")}
                                            onChange={onChangeDrug1dosekg} 
                                            inputProps={getDrug1_posology()==="daily"? (notsaved ? notSavedProps: readOnlyProps) : {type:'number'}}
                                            disabled={getDrug1_posology()==="daily"? (drug1_dose_kg?false:true) : false}
                                            />
                                        </Grid>
                                        </React.Fragment>
                                    : null }
                                    <Grid item xs={12} >
                                        { drug1.row.administration ?
                                            <MostTextField name="x1" label={t("Somministrazione")} value={drug1.row.administration} inputProps={readOnlyProps} />
                                        :
                                            <MostAutocomplete options={options_somministrazione} label={t("Somministrazione")} value={drug1_administration} onChange={handleDrug1_administrationChange} />
                                        }
                                    </Grid>
                                    { getDrug1_administration() === "SC" ?
                                        <Grid item xs={12} >
                                            <MostAutocomplete name="drug1_site" options={options_sede_infusione} label={t("Sede infusione")} value={drug1_site} onChange={onChangeDrug1_site} />
                                        </Grid>
                                    : null }
                                    { getDrug1_posology() === "daily" ?
                                        <React.Fragment>
                                        <Grid item xs={12} >
                                            <MostTextField name="drug1_required_daily" inputProps={{type:'number'}} label={t("Prescrizione (assunzioni al giorno)")}  value={drug1_required_daily} onChange={onChangeDrug1_required_daily} />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <MostTextField name="drug1_required_weekly" inputProps={{type:'number'}} label={t("Prescrizione (giorni alla settimana)")} value={drug1_required_weekly} onChange={onChangeDrug1_required_weekly} />
                                        </Grid>
                                        </React.Fragment>
                                    : null }
                                    { getDrug1_posology() === "periodic" ?
                                        <Grid item xs={12}>
                                            <MostTextField name="drug1_interval" inputProps={{type:'number'}} label={t("Intervallo somministrazioni previsto (giorni)")} value={drug1_interval} onChange={onChangeDrug1_interval} />
                                        </Grid>
                                    : null }
                                    { getDrug1_posology() === "daily" ?
                                        <React.Fragment>
                                        <Grid item xs={5}>
                                            <MostTextField name="x1" label={t("Assunzioni attese")} value={drug1_assunzioni_attese} inputProps={readOnlyProps} disabled={drug1_assunzioni_attese?false:true} />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <MostTextField name="x1" label={t("dal")} value={drug1_assunzioni_dal} inputProps={readOnlyProps} disabled={drug1_assunzioni_dal?false:true} />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <MostTextField name="x1" label={t("giorni")} value={drug1_assunzioni_giorni} inputProps={readOnlyProps} disabled={drug1_assunzioni_giorni?false:true} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MostTextField name="drug1_declared_delivery_count" disabled={drug1_declared_delivery_count_disabled}  inputProps={{type:'number'}} value={drug1_declared_delivery_count} onChange={onChangeDrug1_declared_delivery_count} label={t("Assunzioni dichiarate")} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MostTextField name="x1" value={drug1_compliance} label="Compliance(%)" inputProps={readOnlyProps} disabled={drug1_compliance?false:true} />
                                        </Grid>
                                        </React.Fragment>
                                    : 
                                        <React.Fragment>
                                        <Grid item xs={7}>
                                            <MostTextField name="x1" label={t("Ultima somministrazione")} value={drug1_assunzioni_dal} inputProps={readOnlyProps} disabled={drug1_assunzioni_dal?false:true} />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <MostTextField name="x1" label={t("giorni trascorsi")} value={drug1_assunzioni_giorni} inputProps={readOnlyProps} disabled={drug1_assunzioni_giorni?false:true} />
                                        </Grid>
                                        {props.entryDate === today ?
                                            <Grid item xs={12}>
                                                <MostTextField name="x1" label={t("Data prevista prossima somministrazione")} value={drug1_prossima_somministrazione} inputProps={readOnlyProps} disabled={drug1_prossima_somministrazione?false:true} />
                                            </Grid>
                                        : null}
                                        </React.Fragment>
                                    }
                                    </React.Fragment>
                                : null }
                              </Grid>
                        </div>
                    </div>
                </Grid>
              </Grid>
        </Container>
        </div>
        </form>
        {/* <ToastContainer /> */}
      </div>
    )
}
