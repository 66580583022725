import React, { useState, useEffect, } from "react";
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import MyAxios, {check_response} from "./MyAxios";
import { MostTextField, } from "./components/MostComponents";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const RiepilogoUpdateDialog = (props) => {
    log.debug(props)
    const { t, } = useTranslation();
    const [year, setYear] = useState(null)
    const [notes, setNotes] = useState(null)
    const [errorText, setErrorText] = useState(null)
    const [disabledButs, setDisabledButs] = useState(false)

    useEffect(() => {
        if(props.row) {
            if(props.row.summary_year === 9999)
                setYear(t("Nota finale"))
            else
                setYear(props.row.summary_year)
            setNotes(props.row.clinical_history)
            setErrorText(null)
        }
    }, [props.row,props.forceReloadDialog,t,])

    const closeDialogFunc = () => {
        props.setOpendialog(false)
    }

    const onChangeNotes = (e) => {
        setNotes(e.target.value)
    }
    const handleSubmit = () => {
        setDisabledButs(true)
        const jdata = {
          action: 'MODIFY',
          mostdoc__key: props.row.mostdoc__key,
          patient_id: props.paziente.value,
          summary_year: props.row.summary_year,
          clinical_history: notes,
        }
        const url = '/historical_anamnesis'
        MyAxios.post(url, jdata)
        .then((response) => {
          setDisabledButs(false)
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            setErrorText(res.error)
            return
          }
          closeDialogFunc()
          props.reload()
        })
        .catch(function (error) {
            setDisabledButs(false)
            log.error(error);
            setErrorText(error.message?error.message:JSON.stringify(error));
        })
    }

    return (
        <Dialog open={props.opendialog} fullWidth maxWidth="sm" className="wt3">
            <DialogTitle>{t("Modifica storia clinica")}</DialogTitle>
            <DialogContent>
                <div>
                    <MostTextField disabled defaultValue={year} label={t("Anno")} />
                </div>
                <div>
                    <MostTextField name="notes" defaultValue={notes} label={t("Notes")} multiline={true} rows={10} onChange={onChangeNotes} inputProps={{ maxLength: 4096 }} />
                </div>
                <DialogContentText className="red">
                    {errorText}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="wt3">
                <Button onClick={closeDialogFunc} disabled={disabledButs} variant="outlined" color="secondary" className="font12" >
                    {t("Annulla")}
                </Button>
                <Button onClick={handleSubmit} disabled={disabledButs} variant="contained" color="secondary" className="font12" >
                    {t("Modifica")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
