import React, { useState, useCallback, useEffect, } from "react";
import log from 'loglevel';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useTranslation } from "react-i18next";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import DownloadIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Container from "@material-ui/core/Container";
import MyAxios, {get_document, check_response, } from "./MyAxios";
import { Loading, getOptionList, } from "./components/MostComponents";
import { HospitalizationInsertDialog } from "./HospitalizationInsertDialog";
import { MostTable } from "./components/MostTable";
import { dmy } from "./Utils";

export const Hospitalization = (props) => {
    const { t, i18n } = useTranslation()
    const [options_disease, setOptions_disease] = useState([])
    const [forceReloadDialog, setForceReloadDialog] = useState(0)
    const [forceReload, setForceReload] = useState(0);
    const [loading, setLoading] = useState(true)
    const [openInsertDialog, setOpenInsertDialog] = useState(false)
    const [rows, setRows] = useState([])
    const [row, setRow] = useState(null)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
        setContent(text);
        setAlert1(true);
    }, [setContent,setAlert1])
    const { setConfirm1, setCContent, setOnConfirm } = useGlobalHook('confirmStore');
    function appConfirm(text,okHandler) {
      setCContent(text);
      setOnConfirm(() => x => {
        okHandler();
      });
      setConfirm1(true);
    }

    useEffect(() => {
        setLoading(true)
        function compare( a, b ) {
            if ( a.check_in < b.check_in )
                return 1;
            if ( a.check_in > b.check_in )
                return -1;
            return 0
        }
        const hospitalization_data = {};
        hospitalization_data['action'] = "LIST";
        hospitalization_data['patient_id'] = props.paziente.value;
        const formData = new FormData()
        formData.append('jpayload', JSON.stringify(hospitalization_data))
        const url = '/hospitalization'
        log.debug(url, [...formData.entries()])
        log.debug(url, "jpayload", hospitalization_data)
        MyAxios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            appAlert(res.error)
            return
          }
          let descColumn = "description_" + i18n.language
          const rows = res.hospitalization
          rows.sort( compare )
          setRows(rows)
          const od = getOptionList(res.disease_list,'code','code+'+descColumn)
          setOptions_disease(od)
          setLoading(false)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,props.paziente.value,forceReload,i18n.language])

    function deleteHospitalization(key,d) {
        const text = t("Cancellare l'ospedalizzazione del")+" "+dmy(d,2)+" ?"
        appConfirm(text,() => {
            const jdata = {
                action: 'DELETE',
                patient_id: props.paziente.value,
                id: key,
            }
        const formData = new FormData()
        formData.append('jpayload', JSON.stringify(jdata))
        const url = '/hospitalization'
        log.debug(url, [...formData.entries()])
        log.debug(url, "jpayload", jdata)
        MyAxios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
              const data = check_response(response);
              if(!data.success) {
                log.error(data.error)
                appAlert(data.error)
                return
              }
              setForceReload(prevCount => prevCount + 1)
            })
            .catch(function (error) {
                log.error(error);
                appAlert(error.message?error.message:JSON.stringify(error));
            })
        })
    }
    function reloadOnInsert() {
        setForceReload(prevCount => prevCount + 1)
    }
    function edit(row) {
        setRow(row)
        setForceReloadDialog(prevCount => prevCount + 1)
        setOpenInsertDialog(true)
    }
    function nuovo() {
        setRow(null)
        setForceReloadDialog(prevCount => prevCount + 1)
        setOpenInsertDialog(true)
    }
    const download = (id) => {
        get_document(id,appAlert)
    }


    const columns = [
        { accessor: 's3_id', Header: t("Documento"), 
            Cell: ({ cell: { value }, row: { original } }) => {
            if (value === null)
              return null
            return (
              <Tooltip title={t("Download")}>
                <IconButton
                  onClick={() => download(original.s3_id)}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
           )},
          },
          {
            Header: t("CheckIn"),
            accessor: "check_in",
            Cell: ({ cell: { value }, row: { original } }) => {
                 const s = dmy(value,2)
                 return (
                    <span>{s}</span>
                )},
          },
          {
            Header: t("HospDays"),
            accessor: "hospedal_days",
          },
          {
            Header: t("HospCause"),
            accessor: "hospedal_cause_descr",
          },
          {
            Header: t("Hospital"),
            accessor: "hospital",
          },
          {
            Header: t("Notes"),
            accessor: "notes",
          },
          {
            Header: "",
            accessor: "id",
            style: { width: "130px" },
            Cell: ({ cell: { value }, row: { original } }) => {
              return (
                <React.Fragment>
                <Tooltip title={t("Modifica")}>
                  <IconButton
                    onClick={() => edit(original)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("Rimuovi")}>
                  <IconButton
                    onClick={() => deleteHospitalization(value,original.check_in)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                </React.Fragment>
             )},
           },
        ]



    if(loading)
        return <Loading />

    return (
        <div className="wt3">
        <Container component="main">
          <div>
            <Toolbar className="rowreverse">
                <Button variant="contained" onClick={nuovo} className="submitButton" > {t("Nuova ospedalizzazione")} </Button>
            </Toolbar>
            { rows.length ?
                <MostTable className="grayTable" columns={columns} data={rows} />
            : 
                <div className="boxForm tcenter">
                    {t("Nessuna ospedalizzazione")}
                </div>
            }
          </div>
        </Container>
        <HospitalizationInsertDialog opendialog={openInsertDialog} setOpendialog={setOpenInsertDialog} paziente={props.paziente} reload={reloadOnInsert} currentdate={new Date()} options_disease={options_disease} row={row} forceReloadDialog={forceReloadDialog} />
        </div>
    )
}

