import React, { useState, useCallback, useEffect, } from "react";
import log from 'loglevel';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useTranslation } from "react-i18next";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import MyAxios, {check_response, } from "./MyAxios";
import { Loading, } from "./components/MostComponents";
import { RiepilogoUpdateDialog } from "./RiepilogoUpdateDialog";
import { MostTable } from "./components/MostTable";

export const Riepilogo = (props) => {
    const { t, i18n } = useTranslation()
    const [forceReloadDialog, setForceReloadDialog] = useState(0)
    const [forceReload, setForceReload] = useState(0);
    const [loading, setLoading] = useState(true)
    const [openInsertDialog, setOpenInsertDialog] = useState(false)
    const [rows, setRows] = useState([])
    const [row, setRow] = useState(null)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
        setContent(text);
        setAlert1(true);
    }, [setContent,setAlert1])

    useEffect(() => {
        setLoading(true)
        function compare( a, b ) {
            if ( a.summary_year < b.summary_year )
                return 1;
            if ( a.summary_year > b.summary_year )
                return -1;
            return 0
        }
        const jdata = {
            action: 'LIST',
            patient_id: props.paziente.value,
        }
        const url = '/historical_anamnesis'
        MyAxios.post(url, jdata)
        .then((response) => {
          const res = check_response(response)
          if(!res.success) {
            log.error(res.error)
            appAlert(res.error)
            return
          }
          const rows = res.historical_anamnesis
          rows.sort( compare )
          setRows(rows)
          setLoading(false)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,props.paziente.value,forceReload,i18n.language])

    function reloadOnInsert() {
        setForceReload(prevCount => prevCount + 1)
    }
    function edit(row) {
        setRow(row)
        setForceReloadDialog(prevCount => prevCount + 1)
        setOpenInsertDialog(true)
    }

    const columns = [
          {
            Header: t("Anno"),
            accessor: "summary_year",
            Cell: ({ cell: { value }, row: { original } }) => {
                 if(value===9999)
                     return (
                        <span>{t("Nota finale")}</span>
                    )
                 return (
                    <span>{value}</span>
                )},
          },
          {
            Header: t("Storia clinica"),
            accessor: "clinical_history",
          },
          {
            Header: "",
            accessor: "mostdoc__key",
            Cell: ({ cell: { value }, row: { original } }) => {
              return (
                <Tooltip title={t("Modifica")}>
                  <IconButton
                    onClick={() => edit(original)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
             )},
           },
        ]



    if(loading)
        return <Loading />

        /*
        <Container component="main">
        </Container>
        */
    return (
        <div className="wt3">
            <MostTable className="grayTable" columns={columns} data={rows} />
            <RiepilogoUpdateDialog opendialog={openInsertDialog} setOpendialog={setOpenInsertDialog} paziente={props.paziente} reload={reloadOnInsert} row={row} forceReloadDialog={forceReloadDialog} />
        </div>
    )
}

